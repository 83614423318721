import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { TimelineLite, Power2  ,gsap} from "gsap";

import {ReactComponent as IconFeatherCircle} from '../../styles/images/Icon_feather_check_circle.svg'
gsap.registerPlugin([TimelineLite]);
export default function KeywordChangeResponse(props) {
  useEffect(() => {
    let recovery = new TimelineLite({ paused: true, smoothChildTiming: true, reversed: true, yoyo: true });
    recovery
      .fromTo(
        "#change_response",
        { opacity: 0, ease: Power2.easeInOut, y: -50 },
        { opacity: 1, ease: Power2.easeInOut, y: 0 }
      )
      .play();
    return () => {};
  }, []);
  return (
    <div className="client-login recovery" id="change_response">
      <div className="input-form-control reset-password-controller reset-password-response-controller " style={{width:"80%"}}>
        <IconFeatherCircle />
        <p className="login-input-header">Congratulations</p>
        <p className="forgot-password-description">New Security Keyword set successfully!</p>
        <button
          className="login-type-submit"
          style={{
            height: window.innerHeight / 12,
            width: window.innerHeight / 12,
            transform: " translateY(75px)",
            bottom:0
          }}
          onClick={props.onNext}
        >
          <Icon name={"arrow right"} size="large" />
        </button>
      </div>
    </div>
  );
}
