import React, { Component, memo } from "react";
import axios from "../../utils/axios";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import makeSelectGlobal from "../../redux/redux/global/selectors";
import makeSelectAccess from "../../redux/redux/access/selectors";
import { toast } from "react-toastify";
import { CATCH_TOAST, CLIENT_ADDED_ERROR, DAYS, NOT_APPLICABLE, NOT_APPLICABLE_DATE, OVERDUE } from "../../utils/labelContant";
import { searchRecurrence, searchRecurrenceReset, setPageNo, setRecurrence, setTotalCount } from "../../redux/redux/recurrenceJobs/action";
import makeSelectRecurrence from "../../redux/redux/recurrenceJobs/selectors";
import { percentAsPerStatus } from "../../utils/percAsPerStatus";
import { getCusLocalDateYear } from "../../utils/localTime";
import * as qs from "query-string";

export default function RecurrenceJobHoc(ComposedComponent) {
  class HOC extends Component {
    state = {
        loading: true,
        job_type: [],
        jobStatus: [],
        job_Manager:[],
        data:[]
    };

    getJobType = () => {
        axios
          .get("/dds/jobTypes")
          .then((res) => {
            let data = [...res.data.result];
            data = data.map((item) => {
              item = {
                key: `${item.id}_${item.job_name}`,
                value: item.id,
                text: item.job_name,
              };
              return item;
            });
              this.setState((prevState) => ({
                ...prevState,
                job_type: [...data],
              }))
          })
          .catch((e) => { toast.error(
            CATCH_TOAST,
            {
              toastId: CLIENT_ADDED_ERROR,
            }
          ); })
          .finally(() => { });
    };

    getStatus = () => {
        axios
          .get("/dds/status")
          .then((res) => {
            let data = [...res.data.result.rows];
            data = data.map((item) => {
              item = {
                key: `${item.id}_${item.company_name}`,
                value: item.id,
                text: item.status,
              };
              return item;
            });
            this.setState((prevState) => ({
                ...prevState,
                jobStatus: [...data],
              }))
          })
          .catch((e) => {
            toast.error(
            CATCH_TOAST,
            {
              toastId: CLIENT_ADDED_ERROR,
            }
          );})
      };

    getManagers = () => {
        axios
          .get("/dds/managers")
          .then((res) => {
            let data = [...res.data.result];
            data = data.map((item) => {
              item = {
                key: `${item.id}_${item.employee_name}`,
                value: item.id,
                text: item.employee_name,
              };
              return item;
            });
            this.setState((prevState) => ({
                ...prevState,
                job_Manager: [...data],
              }))
          })
          .catch((e) => { toast.error(
            CATCH_TOAST,
            {
              toastId: CLIENT_ADDED_ERROR,
            }
          ); })
    };
    getData = async(paramObj = {}) => {
    await  axios
        .get(`jobs/fetchRecursionJob`, {
          params: {
            ...paramObj,
            offset:  this.props.recurrence.pageNumber * 10 - 10,
            limit:  10,
          },
        })
        .then(async (res) => {
          let data = res?.data?.result[0]?.rows;
          let row = res?.data?.result[0]?.count;
          data = data.map((item) => {
            let newVal = {
              ...item,
  
              id: item?.id || NOT_APPLICABLE,
              reference_no: item?.reference_no || NOT_APPLICABLE,
              company: item?.jobSowDoc?.company_name || "",
              client: item?.Company?.company_name || "",
              job_status_perc: (item.job_status_perc = (
                <div className="outerProgress">
                  <div className="inner">
                    <span
                      className="color"
                      style={{
                        width: `${percentAsPerStatus(item?.Status?.status)}`,
                      }}
                    ></span>
                    <span className="label">
                      {percentAsPerStatus(item?.Status?.status)}
                    </span>
                  </div>
                </div>
              )),
              job_type: item?.Job_type?.job_name || NOT_APPLICABLE,
              yearend:
                item?.jobSowDoc?.year_end_date === NOT_APPLICABLE_DATE
                  ? NOT_APPLICABLE
                  : getCusLocalDateYear(item?.jobSowDoc?.year_end_date),
              job_status: item?.Status?.status,
              createdBy: item?.createdByName || "",
              budget: item?.Quotation?.total_budget_hours || "",
              actual: item?.Quotation?.total_actual_hours || "",
              CREATE_BY: item?.createdByName || "",
              job_start_date:
                item?.job_start_date === NOT_APPLICABLE_DATE
                  ? NOT_APPLICABLE
                  : getCusLocalDateYear(item?.job_start_date),
              job_end_date:
                item?.job_end_date === NOT_APPLICABLE_DATE
                  ? NOT_APPLICABLE
                  : getCusLocalDateYear(item?.job_end_date),
              dispatch_date:
                item?.dispatch_date === NOT_APPLICABLE_DATE
                  ? NOT_APPLICABLE
                  : getCusLocalDateYear(item?.dispatch_date),
              days_left:
                item?.daysLeft == null
                  ? ""
                  : item?.daysLeft == -1 || -item?.daysLeft
                  ? OVERDUE
                  : `${item?.daysLeft} ${DAYS}`,
              manager: item?.Manager?.employee_name,
              recursion_status: item?.recursion_status,
            };
            return newVal;
          });
          await this.props.dispatch(setTotalCount(row));
          await this.props.dispatch(setRecurrence(data));  
        })
        .catch((error) => {
          toast.error(CATCH_TOAST, {
            toastId: CLIENT_ADDED_ERROR,
          });
        })   
    }
    onSelect = (value, type) => {
      this.props.dispatch(searchRecurrence(type, value));
    };
    resetSearch = () => {
      this.props.dispatch(searchRecurrenceReset());
    };
    setPageNumber = async (pageNumber) => {
      const query = await qs.stringify({ p: pageNumber });
      await this.props.dispatch(setPageNo(pageNumber));
      await this.props.history.push(this.props.location.pathname + `?${query}`);
    };

    componentDidMount() {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
      this.getJobType();
      this.getStatus();
      this.getManagers();
      }
    
    render() {
      return <ComposedComponent
      {...this.props}
        job_type={this.state.job_type}
        job_Manager={this.state.job_Manager}
        jobStatus={this.state.jobStatus}
        onSelect={this.onSelect}
        resetSearch={this.resetSearch}
        getData={this.getData}
        setPageNumber={this.setPageNumber}
  />
    }
  }
  const mapStateToProps = createStructuredSelector({
    recurrence: makeSelectRecurrence(),
    global: makeSelectGlobal(),
    access: makeSelectAccess(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  return compose(withConnect, memo)(HOC);
}
