import React from "react";
// import rcEt from "../../styles/components/_editor.scss";
import "../../styles/components/_editor.scss";
import { Button, Icon } from "semantic-ui-react";

class StyleButton extends React.Component {
  onToggle = (e) => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  };
  render() {
    let className = "RichEditor-styleButton "; // Do not remove the space
    if (this.props.active) {
      className += ` RichEditor-activeButton`; // Do not remove the space
    }
    return (
      // <span className={className} onMouseDown={this.onToggle}>
      <Button icon className={className} onMouseDown={this.onToggle}>
        <Icon name={this.props.label.toLowerCase()} />
      </Button>

      //   {this.props.label}
      // </span>
    );
  }
}

export default StyleButton;
