import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { TimelineLite, Power2, gsap } from "gsap";
import { isEmail } from "../../utils/validator";
import axios from "../../utils/axios";
import { ACC_LOCKED } from "../../utils/labelContant";
gsap.registerPlugin([TimelineLite]);

export default function ForgotPassword(props) {
  const [email, setEmail] = useState('')
  const [emailSuccess, setEmailSuccess] = useState('');
  const [emailError, setEmailError] = useState('');
  const [formValue, setFormValue] = useState({
    // role: "",
    email: "",
  });
  useEffect(() => {
  }, [props])
  useEffect(() => {
    let recovery = new TimelineLite({ paused: true, smoothChildTiming: true, reversed: true, yoyo: true });
    recovery
      .fromTo(
        "#forgot_password",
        { opacity: 0, ease: Power2.easeInOut, y: -50 },
        { opacity: 1, ease: Power2.easeInOut, y: 0 },
        .1
      )
      .play();
    return () => { };
  }, []);
  const validate = () => {
    var clientEmail = document.getElementById("email").value;
    var atposition = clientEmail.indexOf("@");
    var dotposition = clientEmail.lastIndexOf(".");
    return (atposition < 1 || dotposition < atposition + 2 || dotposition + 2 >= clientEmail.length)
  }

  const loginTypeValue = () => {
    if (props.loginType === "DOT_TEAM") {
      return { role: 4 };
    }
    return {};
  };

  const onChange = (e) => {
    setEmail(e.target.value)
    setFormValue({ ...formValue, [e.target.id]: e.target.value });
    setEmailError(isEmail(e.target.value, "Email Address"))
  }

  const formSubmit = () => {
    if (email === '') {
      setEmailError('Email cannot be empty')
    }
    else if (isEmail(email, "Email Address")) {
      setEmailError('Please provide a valid Email Address')
    }
    else {
      let value = { ...formValue, ...loginTypeValue() };
      axios
        .post("/forgetPassword/sendVerificationCode", value, {
          headers: {},
        })
        .then((response) => {
          if (response.status === 200) {
            setEmailSuccess(response.data.message)
            props.onNext()
            props.email(email)
            
          }
          else {
            setEmailError("Invalid Email ID")
          }
        })
        .catch((er) => {
          setEmailError(ACC_LOCKED);
        })
        .finally(() => {
        });
    }
  }
  return (
    <div className="client-login recovery" id="forgot_password">
      <p className="login-input-header">Forgot Password</p>
      <p className="forgot-password-description">Please enter your email address to reset your password</p>
      <div className="input-form-control">
        <label className="input-form-control-label" htmlFor="email">
          Email Address
        </label>
        <input
          placeholder=""
          className="input-form-control-input"
          type="email"
          autoComplete="off"
          aria-autocomplete="none"
          id="email"
          onChange={onChange}
          value={email}
        />
        <div className='errorLabel'>
          {emailError}
        </div>
        <div className='successLabel'>
          {emailSuccess}
        </div>
      </div>

      <button
        className="login-type-submit"
        style={{
          height: window.innerHeight / 12,
          width: window.innerHeight / 12,
        }}
        onClick={
          // props.onNext
          formSubmit
        }
      >
        <Icon name={"arrow right"} size="large" />
      </button>
    </div>
  );
}
