
import React from 'react';
import ModalWrapper from '../Modals/ModalWrapper';


function TestModal(props) {
  return (
    <ModalWrapper size="small" header="Test Modal" esc scrolling>
      <div>This is test modal</div>
    </ModalWrapper>
  );
}


export default TestModal;
