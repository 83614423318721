import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { compose } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Grid,
  Input,
  Label,
  Segment,
  TextArea,
} from "semantic-ui-react";
import ContainerTitle from "../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../components/ContainerTitle/TitleWrapper";
import { store } from "../../../redux/store";
import { isSelectEmpty, isArrayEmpty } from "../../../utils/validator";
import axios from "../../../utils/axios";
import moment from "moment";
import { dateFormat, diffYearMonths, formatDate } from "../../../utils/localTime";
import { setRefresh } from "../../../redux/redux/global/action";
import { DATE_FORMAT_YEAR, DONE, HOD, HR, SOMETHING_WRONG } from "../../../utils/labelContant";

const Exit = (props) => {
  const dispatch = useDispatch();
  const [formStatus, setFormStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [staffFormValue, setStaffFormValue] = useState({
    dept_hod_id: null,
    empsign: null,
    user_id: null,
    hr_id: null,
    employeeName: null,
    designation: null,
    employeeCode: null,
    department: null,
    dateOfJoining: null,
    dateOfRegistration: null,
    lastWorkingDate: null,
    lengthOfService: null,
    staffquestion1: "",
    staffquestion2: "",
    staffquestion3: "",
    staffquestion4: [],
    staffquestion5: "",
    staffquestion6: "",
    staffquestion7: [],
    staffquestion8: "",
    staffquestion9: "",
    empSign: "",
    flag: "staff",
  });
  const [staffFormValueError, setStaffFormValueError] = useState({
    staffquestion1Error: "",
    staffquestion2Error: "",
    staffquestion3Error: "",
    staffquestion4Error: "",
    staffquestion5Error: "",
    staffquestion6Error: "",
    staffquestion7Error: "",
    staffquestion8Error: "",
    staffquestion9Error: "",
    empSignError: "",
  });
  const [HODFormValue, setHODFormValue] = useState({
    user_id: null,
    dept_hod_id: null,
    hr_id: null,
    deptquestion1: "",
    deptquestion2: "",
    deptquestion3: "",
    deptquestion4: "",
    hodName: "",
    hodSign: "",
  });
  const [HODFormValueError, setHODFormValueError] = useState({
    deptquestion1Error: "",
    deptquestion2Error: "",
    deptquestion3Error: "",
    deptquestion4Error: "",
    hodNameError: "",
    hodSignError: "",
  });
  const [HRFormValue, setHRFormValue] = useState({
    user_id: null,
    hr_id: null,
    hrquestion1: "",
    hrquestion2: "",
    hrquestion3: "",
    hrName: "",
    hrSign: "",
  });
  const [HRFormValueError, setHRFormValueError] = useState({
    hrquestion1Error: "",
    hrquestion2Error: "",
    hrquestion3Error: "",
    hrNameError: "",
    hrSignError: "",
  });

  const onValidator = (e) => {
    switch (e?.target?.name) {
      case "staffquestion1":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "staffquestion2":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "staffquestion3":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "staffquestion4":
        return isArrayEmpty(e?.target?.value, "Answer");
      case "staffquestion5":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "staffquestion6":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "staffquestion7":
        return isArrayEmpty(e?.target?.value, "Answer");
      case "staffquestion8":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "staffquestion9":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "deptquestion1":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "deptquestion2":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "deptquestion3":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "deptquestion4":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "hrquestion1":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "hrquestion2":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "hrquestion3":
        return isSelectEmpty(e?.target?.value, "Answer");
      case "empSign":
        return isSelectEmpty(e?.target?.value, "Employee Signature");
      case "hodName":
        return isSelectEmpty(e?.target?.value, "Department Head Name");
      case "hodSign":
        return isSelectEmpty(e?.target?.value, "Department Head Signature");
      case "hrName":
        return isSelectEmpty(e?.target?.value, "HR Name");
      case "hrSign":
        return isSelectEmpty(e?.target?.value, "HR Signature");

      default:
        return null;
    }
  };
  const validateStaffForm = () => {
    let isError = false;
    Object.values({ ...staffFormValue }).forEach((value) => {
      if (value === "" || value?.length === 0) {
        isError = true;
      }
    });
    return isError;
  };
  const validateHodForm = () => {
    let isError = false;
    Object.values({ ...HODFormValue }).forEach((value) => {
      if (value === "" || value?.length === 0) {
        isError = true;
      }
    });
    return isError;
  };
  const validateHRForm = () => {
    let isError = false;
    Object.values({ ...HRFormValue }).forEach((value) => {
      if (value === "" || value?.length === 0) {
        isError = true;
      }
    });
    return isError;
  };
  const onSubmitHodValidate = async () => {
    let data = { ...HODFormValue };
    await Object.keys(data)?.forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };
      await setHODFormValueError((prevState) => {
        return { ...prevState, [`${key}Error`]: onValidator(e) };
      });
    });
  };
  const onSubmitHRValidate = async () => {
    let data = { ...HRFormValue };
    await Object.keys(data)?.forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };
      await setHRFormValueError((prevState) => {
        return { ...prevState, [`${key}Error`]: onValidator(e) };
      });
    });
  };
  const onSubmitStaffValidate = async () => {
    let data = { ...staffFormValue };
    await Object.keys(data)?.forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };
      await setStaffFormValueError((prevState) => {
        return { ...prevState, [`${key}Error`]: onValidator(e) };
      });
    });
  };
  const onChange = (e) => {
    let newValues = { ...staffFormValue, [e.target.name]: e?.target?.value };
    let newVal = { ...HODFormValue, [e.target.name]: e?.target?.value };
    let newVal2 = { ...HRFormValue, [e.target.name]: e?.target?.value };

    let errorData = {
      ...staffFormValueError,
      [`${e.target.name}Error`]: onValidator(e),
    };
    let errorData1 = {
      ...HODFormValueError,
      [`${e.target.name}Error`]: onValidator(e),
    };
    let errorData2 = {
      ...HRFormValueError,
      [`${e.target.name}Error`]: onValidator(e),
    };
    setStaffFormValue({ ...newValues });
    setStaffFormValueError({ ...errorData });
    setHODFormValue({ ...newVal });
    setHODFormValueError({ ...errorData1 });
    setHRFormValue({ ...newVal2 });
    setHRFormValueError({ ...errorData2 });
  };
  // create exit interview form
  const sendStaff = async () => {
    let formValue = {
      user_id: staffFormValue?.user_id,
      dept_hod_id: staffFormValue?.dept_hod_id,
      staffquestion1: staffFormValue?.staffquestion1,
      staffquestion2: staffFormValue?.staffquestion2,
      staffquestion3: staffFormValue?.staffquestion3,
      staffquestion4: JSON.stringify(staffFormValue?.staffquestion4),
      staffquestion5: staffFormValue?.staffquestion5,
      staffquestion6: staffFormValue?.staffquestion6,
      staffquestion7: JSON.stringify(staffFormValue?.staffquestion7),
      staffquestion8: staffFormValue?.staffquestion8,
      staffquestion9: staffFormValue?.staffquestion9,
      dept_hod_id: staffFormValue?.dept_hod_id,
      hr_id: staffFormValue?.hr_id,
      status: "HOD",
      empsign: staffFormValue?.empSign,
    };
    setLoading(true);
    axios
      .post("/staff/createExitInterview", formValue)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setRefresh(!store?.getState()?.global?.refresh));
          toast.success("Exit Submitted Successfully", {
            toastId: "clientAddedSuccessfully",
          });
          props?.history?.replace("/dashboard");
        }
      })
      .catch((e) => {
        let response = e?.response;
        toast.error(
          response?.data?.message ||
            "Something went wrong, Please try again later",
          {
            toastId: "clientAddedError",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // update exit from HOD
  const sendHod = async () => {
    let formValue = {
      user_id: HODFormValue?.user_id,
      dept_hod_id: HODFormValue?.dept_hod_id,
      deptquestion1: HODFormValue?.deptquestion1,
      deptquestion2: HODFormValue?.deptquestion2,
      deptquestion3: HODFormValue?.deptquestion3,
      deptquestion4: HODFormValue?.deptquestion4,
      dept_hod_name: HODFormValue?.hodName,
      hod_sign: HODFormValue?.hodSign,
      // hr_id: HODFormValue.hr_id,
      status: "HR",
    };
    setLoading(true);
    axios
      .put("/staff/updateExitInterview", formValue)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setRefresh(!store?.getState()?.global?.refresh));
          toast.success("HOD Exit Submitted Successfully", {
            toastId: "clientAddedSuccessfully",
          });
          props?.history?.replace("/dashboard");
        }
      })
      .catch((e) => {
        let response = e?.response;
        toast.error(
          response?.data?.message ||
            "Something went wrong, Please try again later",
          {
            toastId: "clientAddedError",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // update exit from HR
  const sendHR = async () => {
    let formValue = {
      user_id: HRFormValue?.user_id,
      hr_id: HRFormValue?.hr_id,
      hrquestion1: HRFormValue?.hrquestion1,
      hrquestion2: HRFormValue?.hrquestion2,
      hrquestion3: HRFormValue?.hrquestion3,
      hr_name: HRFormValue?.hrName,
      hr_sign: HRFormValue?.hrSign,
      status: "DONE",
    };
    setLoading(true);
  await axios
      .put("/staff/updateHRExitInterview", formValue)
      .then((res) => {
        if (res.status === 200) {
          let abc = {
            user_id: HODFormValue?.user_id,
            location: "123",
            key: "",
            original_name: "",
            extension: "",
            file_name: null,
            uploaded_by: "HR Name",
            comment: "exit",
            flags: "exit_module",
          };
          axios
            .post("/staff/exitDocuments", abc)
            .then((res) => {
              if (res.status === 200) {
                dispatch(setRefresh(!store?.getState()?.global?.refresh));
                toast.success("HR Exit Submitted Successfully", {
                  toastId: "clientAddedSuccessfully",
                });
                props.history.replace("/dashboard");
              }
            })
            .catch((e) => {
              let response = e?.response;
              toast.error(
                response?.data?.message ||
                  "Something went wrong, Please try again later",
                {
                  toastId: "clientAddedError",
                }
              );
            })
            .finally(() => {});
        }
      })
      .catch((e) => {
        let response = e?.response;
        toast.error(
          response?.data?.message ||
            "Something went wrong, Please try again later",
          {
            toastId: "clientAddedError",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // according to status submit form
  const onSubmit = async () => {
    if (formStatus === "HOD") {
      let isError = await validateHodForm();
      if (!isError) {
        sendHod();
      } else {
        toast.error("Please enter all mandatory fields", {
          toastId: "JobInvalidData",
        });
        onSubmitHodValidate();
      }
    } else if (formStatus === "HR") {
      let isError = await validateHRForm();
      if (!isError) {
        sendHR();
      } else {
        toast.error("Please enter all mandatory fields", {
          toastId: "JobInvalidData",
        });
        onSubmitHRValidate();
      }
    } else {
      let isError = await validateStaffForm();
      if (!isError) {
        sendStaff();
      } else {
        toast.error("Please enter all mandatory fields", {
          toastId: "JobInvalidData",
        });
        onSubmitStaffValidate();
      }
    }
  };
  const checkboxChange = (arrayName, key) => {
    let value = [...staffFormValue[arrayName]];
    value?.splice();
    if (value?.includes(key)) {
      value = value?.filter((i) => i !== key);
    } else {
      value?.push(key);
    }
    setStaffFormValue((prevState) => ({
      ...prevState,
      [arrayName]: [...value],
    }));
  };
  //get exit form for staff
  const getDataExit = (role, id) => {
    axios
      .get(`/staff/fetchExitInterviewList?role=${role}&user_id=${id}`)
      .then((res) => {
        let data = res?.data?.result;
        let result = data[0]?.response_fetchExitInterview[0];
        if (result) {
          setStaffFormValue((prevState) => ({
            ...prevState,
            dept_hod_id: result?.depthodid || null,
            // manager_id: result.manager_id || null,
            // staff_id: result.staffid || null,
            user_id: result?.userid || null,
            hr_id: result?.hrid || null,
            employeeName: result?.employee_name || null,
            designation: result?.designation || null,
            employeeCode: result?.emp_code || null,
            department: result?.department || null,
            staffquestion1: result?.staffquestion1 || "",
            staffquestion2: result?.staffquestion2 || "",
            staffquestion3: result?.staffquestion3 || "",
            staffquestion4: result?.staffquestion4
              ? JSON.parse(result?.staffquestion4)
              : "",
            staffquestion5: result?.staffquestion5 || "",
            staffquestion6: result?.staffquestion6 || "",
            staffquestion7: result?.staffquestion7
              ? JSON.parse(result?.staffquestion7)
              : "",
            staffquestion8: result?.staffquestion8 || "",
            staffquestion9: result?.staffquestion9 || "",
            empSign: result?.empsign || "",
            dateOfJoining:
              formatDate(result?.date_joining) ||
              null,
            dateOfRegistration:
            formatDate(result?.date_reg) ||
              null,
            lastWorkingDate:
              formatDate(result?.lwd) || null,
            lengthOfService:
              diffYearMonths(
                new Date(moment(result?.date_joining, DATE_FORMAT_YEAR)),
                new Date(moment(result?.lwd, DATE_FORMAT_YEAR))
              ) || null,
          }));
          setFormStatus(result?.status);
        } else {
          getDataHodExit(id);
        }
      })
      .catch((err) => {toast.error(err, { toastId: SOMETHING_WRONG });})
  };
  // get exit data for hod
  const getDataHodExit = (id) => {
    axios
      .get(`/staff/fetchExitInterviewList?user_id=&hodid=${id}&hrid=`)
      .then((res) => {
        let data = res?.data?.result;
        let result = data[0]?.response_fetchExitInterview[0];
        if (result && result?.status === "HOD") {
          setStaffFormValue((prevState) => ({
            ...prevState,
            dept_hod_id: result?.depthodid || null,
            user_id: result?.user_id || null,
            employeeName: result?.employee_name || null,
            designation: result?.designation || null,
            employeeCode: result?.emp_code || null,
            department: result?.department || null,
            staffquestion1: result?.staffquestion1 || "",
            staffquestion2: result?.staffquestion2 || "",
            staffquestion3: result?.staffquestion3 || "",
            staffquestion4: result?.staffquestion4
              ? JSON.parse(result?.staffquestion4)
              : "",
            staffquestion5: result?.staffquestion5 || "",
            staffquestion6: result?.staffquestion6 || "",
            staffquestion7: result?.staffquestion7
              ? JSON.parse(result?.staffquestion7)
              : "",
            staffquestion8: result?.staffquestion8 || "",
            staffquestion9: result?.staffquestion9 || "",
            empSign: result?.empsign || "",
            dateOfJoining:
              formatDate(result?.date_joining) ||
              null,
            dateOfRegistration:
              formatDate(result?.date_reg) ||
              null,
            lastWorkingDate:
              formatDate(result?.lwd) || null,
            lengthOfService:
              diffYearMonths(
                new Date(moment(result?.date_joining, DATE_FORMAT_YEAR)),
                new Date(moment(result?.lwd, DATE_FORMAT_YEAR))
              ) || null,
          }));
          setHODFormValue((prevState) => ({
            ...prevState,
            user_id: result?.user_id || null,
            dept_hod_id: result?.dept_hod_id || null,
            hr_id: result?.hrid || null,
          }));
          setFormStatus(result.status);
        } else {
          getDataHRExit(id);
        }
      })
      .catch((error) => {toast.error(error, { toastId: SOMETHING_WRONG });})
  };
  // get exit list for hr
  const getDataHRExit = (id) => {
    axios
      .get(`/staff/fetchExitInterviewList?user_id=&hodid=&hrid=${id}`)
      .then((res) => {
        let data = res?.data?.result;
        let result = data[0]?.response_fetchExitInterview[0];
        if (result) {
          setStaffFormValue((prevState) => ({
            ...prevState,
            dept_hod_id: result?.depthodid || null,
            user_id: result?.user_id || null,
            employeeName: result?.employee_name || null,
            designation: result?.designation || null,
            employeeCode: result?.emp_code || null,
            department: result?.department || null,
            staffquestion1: result?.staffquestion1 || "",
            staffquestion2: result?.staffquestion2 || "",
            staffquestion3: result?.staffquestion3 || "",
            staffquestion4: result?.staffquestion4
              ? JSON.parse(result?.staffquestion4)
              : "",
            staffquestion5: result?.staffquestion5 || "",
            staffquestion6: result?.staffquestion6 || "",
            staffquestion7: result?.staffquestion7
              ? JSON.parse(result?.staffquestion7)
              : "",
            staffquestion8: result?.staffquestion8 || "",
            staffquestion9: result?.staffquestion9 || "",
            empSign: result?.empsign || "",
            dateOfJoining:
              formatDate(result?.date_joining) ||
              null,
            dateOfRegistration:
              formatDate(result?.date_reg) ||
              null,
            lastWorkingDate:
              formatDate(result?.lwd) || null,
            lengthOfService:
              diffYearMonths(
                new Date(moment(result?.date_joining, DATE_FORMAT_YEAR)),
                new Date(moment(result?.lwd, DATE_FORMAT_YEAR))
              ) || null,
          }));
          setHODFormValue((prevState) => ({
            ...prevState,
            user_id: result?.user_id || null,
            dept_hod_id: result?.dept_hod_id || null,
            hr_id: result?.hrid || null,
            deptquestion1: result?.deptquestion1 || "",
            deptquestion2: result?.deptquestion2 || "",
            deptquestion3: result?.deptquestion3 || "",
            deptquestion4: result?.deptquestion4 || "",
            hodName: result?.dept_hod_name || "",
            hodSign: result?.hod_sign || "",
          }));
          setHRFormValue((prevState) => ({
            ...prevState,
            user_id: result?.user_id || null,
            hr_id: result?.hrid || null,
            hrquestion1: result?.hrquestion1 || "",
            hrquestion2: result?.hrquestion2 || "",
            hrquestion3: result?.hrquestion3 || "",
            hrName: result?.hr_name || "",
            hrSign: result?.hr_sign || "",
          }));
          setFormStatus(result?.status);
        }
      })
      .catch((err) => {toast.error(err, { toastId: SOMETHING_WRONG });})
      .finally(() => {});
  };
  // fetch exit interview list
  const getDisplayData = (id) => {
    axios

      .get(`/staff/fetchExitInterviewList?user_id=${id}`)
      .then((res) => {
        let data = res?.data?.result;
        let result = data[0]?.response_fetchExitInterview[0];
        if (result) {
          setStaffFormValue((prevState) => ({
            ...prevState,
            dept_hod_id: result?.depthodid || null,
            user_id: result?.user_id || null,
            employeeName: result?.employee_name || null,
            designation: result?.designation || null,
            employeeCode: result?.emp_code || null,
            department: result?.department || null,
            staffquestion1: result?.staffquestion1 || "",
            staffquestion2: result?.staffquestion2 || "",
            staffquestion3: result?.staffquestion3 || "",
            staffquestion4: result?.staffquestion4
              ? JSON.parse(result?.staffquestion4)
              : "",
            staffquestion5: result?.staffquestion5 || "",
            staffquestion6: result?.staffquestion6 || "",
            staffquestion7: result?.staffquestion7
              ? JSON.parse(result?.staffquestion7)
              : "",
            staffquestion8: result?.staffquestion8 || "",
            staffquestion9: result?.staffquestion9 || "",
            empSign: result?.empsign || "",
            dateOfJoining:
            formatDate(result?.date_joining) ||
              null,
            dateOfRegistration:
            formatDate(result?.date_reg) ||
              null,
            lastWorkingDate:
            formatDate(result?.lwd) || null,
            lengthOfService:
              diffYearMonths(
                new Date(moment(result?.date_joining, DATE_FORMAT_YEAR)),
                new Date(moment(result.lwd, DATE_FORMAT_YEAR))
              ) || null,
          }));
          setHODFormValue((prevState) => ({
            ...prevState,
            user_id: result?.user_id || null,
            dept_hod_id: result?.dept_hod_id || null,
            hr_id: result?.hrid || null,
            deptquestion1: result?.deptquestion1 || "",
            deptquestion2: result?.deptquestion2 || "",
            deptquestion3: result?.deptquestion3 || "",
            deptquestion4: result?.deptquestion4 || "",
            hodName: result?.dept_hod_name || "",
            hodSign: result?.hod_sign || "",
          }));
          setHRFormValue((prevState) => ({
            ...prevState,
            user_id: result?.user_id || null,
            hr_id: result?.hrid || null,
            hrquestion1: result?.hrquestion1,
            hrquestion2: result?.hrquestion2,
            hrquestion3: result?.hrquestion3,
            hrName: result?.hr_name,
            hrSign: result?.hr_sign,
          }));
          setFormStatus(result?.status);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  useEffect(() => {
    if (props?.location?.viewData) {
      let temp = props?.location?.viewData;
      getDisplayData(temp?.user_id);
    } else {
      let userData = store?.getState()?.global;
      getDataExit(userData?.userDetails?.role, userData?.userDetails?.id);
    }
  }, []);
  return (
    <div className={"container-height-width"}>
      <TitleWrapper className="titleWraperExit">
        <div className="container-height-width">
          <ContainerTitle title={"Exit Interview Form"} />
        </div>
      </TitleWrapper>
      <div className="container-height-width extra-padding25">
        <Grid centered>
          <Segment raised className="container-height-width">
            <div className="attendanceTopInputsContainer">
              <Form>
                <Form.Group className="formGroup " widths={16}>
                  <Form.Field
                    control={Input}
                    label="NAME OF EMPLOYEE"
                    clearable
                    className="topMarginInput inlineLabel"
                    placeholder="NAME OF EMPLOYEE"
                    width={8}
                    name="employeeName"
                    disabled
                    value={staffFormValue?.employeeName}
                    onChange={(e) => onChange(e)}
                    error={
                      staffFormValueError?.employeeNameError
                        ? staffFormValueError?.employeeNameError
                        : null
                    }
                  />
                  <Form.Field
                    control={Input}
                    label="DESIGNATION"
                    clearable
                    className="topMarginInput inlineLabel"
                    placeholder="DESIGNATION"
                    width={8}
                    name="designation"
                    disabled
                    value={staffFormValue?.designation}
                    onChange={(e) => onChange(e)}
                    error={
                      staffFormValueError?.designationError
                        ? staffFormValueError?.designationError
                        : null
                    }
                  />
                </Form.Group>
                <Form.Group className="formGroup " widths={16}>
                  <Form.Field
                    control={Input}
                    label="EMPLOYEE CODE"
                    clearable
                    className="topMarginInput inlineLabel"
                    placeholder="EMPLOYEE CODE"
                    selectOnBlur={false}
                    search
                    disabled
                    width={8}
                    name="employeeCode"
                    value={staffFormValue?.employeeCode}
                    onChange={(e) => onChange(e)}
                    error={
                      staffFormValueError?.employeeCodeError
                        ? staffFormValueError?.employeeCodeError
                        : null
                    }
                  />
                  <Form.Field
                    control={Input}
                    label="DEPARTMENT"
                    clearable
                    upward={false}
                    className="topMarginInput inlineLabel"
                    placeholder="DEPARTMENT"
                    selectOnBlur={false}
                    search
                    disabled
                    width={8}
                    name="department"
                    value={staffFormValue?.department}
                    onChange={(e) => onChange(e)}
                    error={
                      staffFormValueError?.departmentError
                        ? staffFormValueError?.departmentError
                        : null
                    }
                  />
                </Form.Group>
                <Form.Group className="formGroup " widths={16}>
                  <DateInput
                    popupPosition="bottom center"
                    label="DATE OF JOINING"
                    className={"inlineLabel topMarginInput"}
                    // icon={"calendar outline large"}
                    name="dateOfJoining"
                    closeOnMouseLeave
                    placeholder="DATE OF JOINING"
                    clearable={true}
                    search="true"
                    value={staffFormValue?.dateOfJoining}
                    iconPosition="right"
                    width={8}
                    disabled
                    dateFormat={"DD-MM-YYYY"}
                    hideMobileKeyboard
                    animation={"drop"}
                    onChange={(event, { name, value }) => {
                      event.target.value = value;
                      event.target.name = "dateOfJoining";
                      onChange(event);
                    }}
                    error={
                      staffFormValueError?.dateOfJoiningError
                        ? staffFormValueError?.dateOfJoiningError
                        : null
                    }
                    // maxDate={moment()}
                  />
                  <DateInput
                    popupPosition="bottom center"
                    label="DATE OF RESIGNATION"
                    className={"inlineLabel topMarginInput"}
                    // icon={"calendar outline large"}
                    name="dateOfRegistration"
                    closeOnMouseLeave
                    placeholder="DATE OF RESIGNATION"
                    clearable={true}
                    search="true"
                    value={staffFormValue?.dateOfRegistration}
                    iconPosition="right"
                    width={8}
                    disabled
                    dateFormat={"DD-MM-YYYY"}
                    hideMobileKeyboard
                    animation={"drop"}
                    onChange={(event, { name, value }) => {
                      event.target.value = value;
                      event.target.name = "dateOfRegistration";
                      onChange(event);
                    }}
                    error={
                      staffFormValueError?.dateOfRegistrationError
                        ? staffFormValueError?.dateOfRegistrationError
                        : null
                    }
                    // maxDate={moment()}
                  />
                </Form.Group>
                <Form.Group className="formGroup " widths={16}>
                  <DateInput
                    popupPosition="bottom center"
                    label="LAST WORKING DATE"
                    className={"inlineLabel topMarginInput"}
                    // icon={"calendar outline large"}
                    name="lastWorkingDate"
                    closeOnMouseLeave
                    placeholder="LAST WORKING DATE"
                    clearable={true}
                    search="true"
                    value={staffFormValue?.lastWorkingDate}
                    iconPosition="right"
                    width={8}
                    disabled
                    dateFormat={"DD-MM-YYYY"}
                    hideMobileKeyboard
                    animation={"drop"}
                    onChange={(event, { name, value }) => {
                      event.target.value = value;
                      event.target.name = "lastWorkingDate";
                      onChange(event);
                    }}
                    error={
                      staffFormValueError?.lastWorkingDateError
                        ? staffFormValueError?.lastWorkingDateError
                        : null
                    }
                    // maxDate={moment()}
                  />
                  <Form.Field
                    control={Input}
                    label="LENGTH OF SERVICE"
                    clearable
                    upward={false}
                    className="topMarginInput inlineLabel"
                    placeholder="LENGTH OF SERVICE"
                    selectOnBlur={false}
                    width={8}
                    disabled
                    name="lengthOfService"
                    value={staffFormValue?.lengthOfService}
                    onChange={(e) => onChange(e)}
                    error={
                      staffFormValueError?.lengthOfServiceError
                        ? staffFormValueError?.lengthOfServiceError
                        : null
                    }
                  />
                </Form.Group>
                <Grid.Row className="fill-left-card segment mt-2">
                  <Segment className="formGroup editor-container segment">
                    <Label className="align-card">
                      1. Please state the primary reason for your departure from
                      Dot Professionals Private Limited.
                    </Label>

                    <Form.Field
                      control={TextArea}
                      clearable
                      // upward={false}
                      className="topMarginInput inlineLabel"
                      style={{ pointerEvents: 'auto' }}
                      width={16}
                      name="staffquestion1"
                      disabled={
                        formStatus === HOD ||
                        formStatus === HR ||
                        formStatus === DONE
                      }
                      value={staffFormValue?.staffquestion1}
                      onChange={(e) => onChange(e)}
                      error={
                        staffFormValueError?.staffquestion1Error
                          ? staffFormValueError?.staffquestion1Error
                          : null
                      }
                    />
                  </Segment>
                </Grid.Row>
                <Grid.Row className="fill-left-card segment mt-2">
                  <Segment className="formGroup editor-container segment">
                    <Label className="align-card">
                      2. Did your motive to join Dot Professionals Private
                      Limited achieved?
                    </Label>
                    <Form.Field
                      control={TextArea}
                      clearable
                      upward={false}
                      style={{ pointerEvents: 'auto' }}
                      className="exitPointerEvent topMarginInput inlineLabel exit-pointerEvent"
                      width={16}
                      name="staffquestion2"
                      disabled={
                        formStatus === HOD ||
                        formStatus === HR ||
                        formStatus === DONE
                      }
                      value={staffFormValue?.staffquestion2}
                      onChange={(e) => onChange(e)}
                      error={
                        staffFormValueError?.staffquestion2Error
                          ? staffFormValueError?.staffquestion2Error
                          : null
                      }
                    />
                  </Segment>
                </Grid.Row>

                <Grid.Row className="fill-left-card segment mt-2">
                  <Segment className="formGroup editor-container segment">
                    <Label className="align-card">
                      3. Did the Organisation or Manager could have done
                      something to prevent your departure?
                    </Label>
                    <Form.Field
                      control={TextArea}
                      style={{ pointerEvents: 'auto' }}
                      clearable
                      upward={false}
                      className="topMarginInput inlineLabel"
                      width={16}
                      name="staffquestion3"
                      disabled={
                        formStatus === HOD ||
                        formStatus === HR ||
                        formStatus === DONE
                      }
                      value={staffFormValue?.staffquestion3}
                      onChange={(e) => onChange(e)}
                      error={
                        staffFormValueError?.staffquestion3Error
                          ? staffFormValueError?.staffquestion3Error
                          : null
                      }
                    />
                  </Segment>
                </Grid.Row>
                <Grid.Row className="fill-left-card segment mt-2">
                  <Segment className="formGroup editor-container segment">
                    <Label className="align-card">
                      4. Please indicate reason, which contributed to your
                      decision to resign from current organisation?
                    </Label>
                    <Grid>
                      <Grid.Row textAlign="left" columns={3} className="mt-2">
                        <Grid.Column>
                          <Form.Field
                            label="Higher Monetary"
                            name="HigherMonetary"
                            control={Checkbox}
                            disabled={
                              formStatus === HOD ||
                              formStatus === HR ||
                              formStatus === DONE
                            }
                            checked={staffFormValue?.staffquestion4?.includes(
                              "HigherMonetary"
                            )}
                            onChange={() => {
                              checkboxChange(
                                "staffquestion4",
                                "HigherMonetary"
                              );
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field
                            // width={5}
                            label="Closer to Home"
                            name="ClosertoHome"
                            control={Checkbox}
                            disabled={
                              formStatus === "HOD" ||
                              formStatus === "HR" ||
                              formStatus === "DONE"
                            }
                            checked={staffFormValue?.staffquestion4?.includes(
                              "ClosertoHome"
                            )}
                            onChange={() => {
                              checkboxChange("staffquestion4", "ClosertoHome");
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field
                            // width={5}
                            label="Job Advancement"
                            name="JobAdvancement"
                            disabled={
                              formStatus === HOD ||
                              formStatus === HR ||
                              formStatus === DONE
                            }
                            control={Checkbox}
                            checked={staffFormValue?.staffquestion4?.includes(
                              "JobAdvancement"
                            )}
                            onChange={() => {
                              checkboxChange(
                                "staffquestion4",
                                "JobAdvancement"
                              );
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={3} textAlign="left">
                        <Grid.Column>
                          <Form.Field
                            // width={5}
                            label="Further Studies"
                            name="FurtherStudies"
                            control={Checkbox}
                            disabled={
                              formStatus === HOD ||
                              formStatus === HR ||
                              formStatus === DONE
                            }
                            checked={staffFormValue?.staffquestion4?.includes(
                              "FurtherStudies"
                            )}
                            onChange={() => {
                              checkboxChange(
                                "staffquestion4",
                                "FurtherStudies"
                              );
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field
                            // width={5}
                            label="Family Responsibility"
                            name="FamilyResponsibility"
                            control={Checkbox}
                            disabled={
                              formStatus === HOD ||
                              formStatus === HR ||
                              formStatus === DONE
                            }
                            checked={staffFormValue?.staffquestion4?.includes(
                              "FamilyResponsibility"
                            )}
                            onChange={() => {
                              checkboxChange(
                                "staffquestion4",
                                "FamilyResponsibility"
                              );
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field
                            // width={5}
                            label="Dissatisfied At Company"
                            name="DissatisfiedAtCompany"
                            control={Checkbox}
                            disabled={
                              formStatus === HOD ||
                              formStatus === HR ||
                              formStatus === DONE
                            }
                            checked={staffFormValue?.staffquestion4?.includes(
                              "DissatisfiedAtCompany"
                            )}
                            onChange={() => {
                              checkboxChange(
                                "staffquestion4",
                                "DissatisfiedAtCompany"
                              );
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={3} textAlign="left">
                        <Grid.Column>
                          {/* <Form.Group className="mt-2"> */}
                          <Form.Field
                            // width={5}
                            // className="mt-2"
                            label="Personal"
                            name="Personal"
                            control={Checkbox}
                            disabled={
                              formStatus === HOD ||
                              formStatus === HR ||
                              formStatus === DONE
                            }
                            checked={staffFormValue?.staffquestion4?.includes(
                              "Personal"
                            )}
                            onChange={() => {
                              checkboxChange("staffquestion4", "Personal");
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field
                            // width={5}
                            label="Health Retirement"
                            name="HealthRetirement"
                            control={Checkbox}
                            disabled={
                              formStatus === HOD ||
                              formStatus === HR ||
                              formStatus === DONE
                            }
                            checked={staffFormValue?.staffquestion4?.includes(
                              "HealthRetirement"
                            )}
                            onChange={() => {
                              checkboxChange(
                                "staffquestion4",
                                "HealthRetirement"
                              );
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field
                            // width={5}
                            label="Relocation"
                            name="Relocation"
                            control={Checkbox}
                            disabled={
                              formStatus === HOD ||
                              formStatus === HR ||
                              formStatus === DONE
                            }
                            checked={staffFormValue?.staffquestion4?.includes(
                              "Relocation"
                            )}
                            onChange={() => {
                              checkboxChange("staffquestion4", "Relocation");
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Row>
                {staffFormValueError?.staffquestion4Error && (
                  <Label basic color="brown" pointing>
                    {staffFormValueError?.staffquestion4Error
                      ? staffFormValueError?.staffquestion4Error
                      : null}
                  </Label>
                )}
                <Grid.Row className="fill-left-card segment mt-2">
                  <Segment className="formGroup editor-container segment">
                    <Label className="align-card">
                      5. Rate if the Organisation helped you settle in the
                      company during your period? (Rate from 1 to 5, 5 Being the
                      Highest)
                    </Label>
                    <Form.Field
                      control={TextArea}
                      clearable
                      upward={false}
                      className="topMarginInput inlineLabel"
                      style={{ pointerEvents: 'auto' }}
                      width={16}
                      disabled={
                        formStatus === HOD ||
                        formStatus === HR ||
                        formStatus === DONE
                      }
                      name="staffquestion5"
                      value={staffFormValue?.staffquestion5}
                      onChange={(e) => onChange(e)}
                      error={
                        staffFormValueError?.staffquestion5Error
                          ? staffFormValueError?.staffquestion5Error
                          : null
                      }
                    />
                  </Segment>
                </Grid.Row>

                <Grid.Row className="fill-left-card segment mt-2">
                  <Segment className="formGroup editor-container segment">
                    <Label className="align-card">
                      6. Rate if the Organisation has provided with all the
                      required training and resources during your tenure for the
                      job profile?
                    </Label>
                    <Form.Field
                      control={TextArea}
                      clearable
                      upward={false}
                      style={{ pointerEvents: 'auto' }}
                      className="topMarginInput inlineLabel"
                      width={16}
                      disabled={
                        formStatus === HOD ||
                        formStatus === HR ||
                        formStatus === DONE
                      }
                      name="staffquestion6"
                      value={staffFormValue?.staffquestion6}
                      onChange={(e) => onChange(e)}
                      error={
                        staffFormValueError?.staffquestion6Error
                          ? staffFormValueError?.staffquestion6Error
                          : null
                      }
                    />
                  </Segment>
                </Grid.Row>

                <Grid.Row className="fill-left-card segment mt-2">
                  <Segment className="formGroup editor-container segment">
                    <Label className="align-card">
                      7. Did the Organisation hear of the feedback if you had to
                      share any?
                    </Label>
                    <Form.Group widths="16" className="mt-2">
                      <Form.Field
                        width={4}
                        label="Always"
                        // className="mt-2"
                        name="Always"
                        control={Checkbox}
                        disabled={
                          formStatus === HOD ||
                          formStatus === HR ||
                          formStatus === DONE
                        }
                        checked={staffFormValue?.staffquestion7?.includes(
                          "Always"
                        )}
                        onChange={() => {
                          checkboxChange("staffquestion7", "Always");
                        }}
                      />

                      <Form.Field
                        width={4}
                        label="Seldom"
                        className="ml-2"
                        name="Seldom"
                        control={Checkbox}
                        disabled={
                          formStatus === HOD ||
                          formStatus === HR ||
                          formStatus === DONE
                        }
                        checked={staffFormValue?.staffquestion7?.includes(
                          "Seldom"
                        )}
                        onChange={() => {
                          checkboxChange("staffquestion7", "Seldom");
                        }}
                      />

                      <Form.Field
                        width={4}
                        label="Usually"
                        className="ml-2"
                        name="Usually"
                        control={Checkbox}
                        disabled={
                          formStatus === HOD ||
                          formStatus === HR ||
                          formStatus === DONE
                        }
                        checked={staffFormValue?.staffquestion7?.includes(
                          "Usually"
                        )}
                        onChange={() => {
                          checkboxChange("staffquestion7", "Usually");
                        }}
                      />

                      <Form.Field
                        width={4}
                        label="Never"
                        className="ml-2"
                        name="Never"
                        control={Checkbox}
                        disabled={
                          formStatus === HOD ||
                          formStatus === HR ||
                          formStatus === DONE
                        }
                        checked={staffFormValue?.staffquestion7?.includes(
                          "Never"
                        )}
                        onChange={() => {
                          checkboxChange("staffquestion7", "Never");
                        }}
                      />
                    </Form.Group>
                  </Segment>
                </Grid.Row>
                {staffFormValueError?.staffquestion7Error && (
                  <Label basic color="brown" pointing>
                    {staffFormValueError?.staffquestion7Error
                      ? staffFormValueError?.staffquestion7Error
                      : null}
                  </Label>
                )}
                <Grid.Row className="fill-left-card segment mt-2">
                  <Segment className="formGroup editor-container segment">
                    <Label className="align-card">
                      8. What will be the Strength and Weakness of Dot
                      Professionals Private Limited according to you?
                    </Label>
                    <Form.Field
                      control={TextArea}
                      style={{ pointerEvents: 'auto' }}
                      clearable
                      upward={false}
                      className="topMarginInput inlineLabel"
                      width={16}
                      disabled={
                        formStatus === HOD ||
                        formStatus === HR ||
                        formStatus === DONE
                      }
                      name="staffquestion8"
                      value={staffFormValue?.staffquestion8}
                      onChange={(e) => onChange(e)}
                      error={
                        staffFormValueError?.staffquestion8Error
                          ? staffFormValueError?.staffquestion8Error
                          : null
                      }
                    />
                  </Segment>
                </Grid.Row>

                <Grid.Row className="fill-left-card segment mt-2">
                  <Segment className="formGroup editor-container segment">
                    <Label className="align-card">
                      9. Kindly share your experience Working with Dot
                      Professionals Private Limited in Detail?
                    </Label>
                    <Form.Field
                      control={TextArea}
                      style={{ pointerEvents: 'auto' }}
                      clearable
                      upward={false}
                      className="topMarginInput inlineLabel"
                      width={16}
                      disabled={
                        formStatus === HOD ||
                        formStatus === HR ||
                        formStatus === DONE
                      }
                      name="staffquestion9"
                      value={staffFormValue?.staffquestion9}
                      onChange={(e) => onChange(e)}
                      error={
                        staffFormValueError?.staffquestion9Error
                          ? staffFormValueError?.staffquestion9Error
                          : null
                      }
                    />
                  </Segment>
                </Grid.Row>

                <div className="fill-center-card segment mt-2 ">
                  <Segment className="formGroup editor-container segment">
                    <div className="align-card-orange container-height-width">
                      <Label className="fontColorWhite align-card-orange">
                        For Hr and Office use only
                      </Label>
                    </div>
                    <div className="formGroup align-orange mt-2">
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <div className="fontColor">
                              To be filled by Department Head Only
                            </div>
                          </Grid.Column>
                          <Divider vertical className="fontColor"></Divider>
                          <Grid.Column>
                            <div className="fontColor">
                              To be filled by HR Department
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>

                    <Form>
                      <Grid>
                        <Grid.Row columns={3}>
                          <Grid.Column tablet={7}>
                            <div className="fill-left-small mt-2">
                              <Segment className="formGroup editor-container segment">
                                <Label className="align-card">
                                  Did the employee serve the Notice Period and
                                  handover process?
                                </Label>
                                <Form.Field
                                  control={TextArea}
                                  clearable
                                  upward={false}
                                  className="topMarginInput inlineLabel"
                                  style={{ pointerEvents: 'auto' }}
                                  selectOnBlur={false}
                                  search
                                  width={16}
                                  name="deptquestion1"
                                  value={HODFormValue?.deptquestion1}
                                  disabled={formStatus !== "HOD"}
                                  onChange={(e) => onChange(e)}
                                  error={
                                    HODFormValueError?.deptquestion1Error
                                      ? HODFormValueError?.deptquestion1Error
                                      : null
                                  }
                                />
                              </Segment>
                            </div>

                            <div className="fill-left-small mt-2">
                              <Segment className="formGroup editor-container segment">
                                <Label className="align-card">
                                  Any suggestion/feedback for employee on
                                  performance?
                                </Label>
                                <Form.Field
                                  control={TextArea}
                                  clearable
                                  upward={false}
                                  style={{ pointerEvents: 'auto' }}
                                  className="topMarginInput inlineLabel"
                                  selectOnBlur={false}
                                  search
                                  width={16}
                                  name="deptquestion2"
                                  disabled={formStatus !== "HOD"}
                                  value={HODFormValue?.deptquestion2}
                                  onChange={(e) => onChange(e)}
                                  error={
                                    HODFormValueError?.deptquestion2Error
                                      ? HODFormValueError?.deptquestion2Error
                                      : null
                                  }
                                />
                              </Segment>
                            </div>

                            <div className="fill-left-small mt-2">
                              <Segment className="formGroup editor-container segment">
                                <Label className="align-card">
                                  Was there any disciplinary issued related to
                                  employee?
                                </Label>
                                <Form.Field
                                  control={TextArea}
                                  clearable
                                  upward={false}
                                  style={{ pointerEvents: 'auto' }}
                                  className="topMarginInput inlineLabel"
                                  selectOnBlur={false}
                                  search
                                  width={16}
                                  name="deptquestion3"
                                  disabled={formStatus !== "HOD"}
                                  value={HODFormValue?.deptquestion3}
                                  onChange={(e) => onChange(e)}
                                  error={
                                    HODFormValueError?.deptquestion3Error
                                      ? HODFormValueError?.deptquestion3Error
                                      : null
                                  }
                                />
                              </Segment>
                            </div>

                            <div className="fill-left-small mt-2">
                              <Segment className="formGroup editor-container segment">
                                <Label className="align-card">
                                  Would you like to rehire the employee if you
                                  get a chance in future?
                                </Label>
                                <Form.Field
                                  control={TextArea}
                                  clearable
                                  upward={false}
                                  style={{ pointerEvents: 'auto' }}
                                  className="topMarginInput inlineLabel"
                                  selectOnBlur={false}
                                  search
                                  width={16}
                                  name="deptquestion4"
                                  disabled={formStatus !== "HOD"}
                                  value={HODFormValue?.deptquestion4}
                                  onChange={(e) => onChange(e)}
                                  error={
                                    HODFormValueError?.deptquestion4Error
                                      ? HODFormValueError?.deptquestion4Error
                                      : null
                                  }
                                />
                              </Segment>
                            </div>
                          </Grid.Column>
                          <Grid.Column tablet={2}>
                            <Divider className="dividerCol" vertical>
                              {" "}
                            </Divider>
                          </Grid.Column>

                          <Grid.Column tablet={7}>
                            <div className="fill-left-small mt-2">
                              <Segment className="formGroup editor-container segment">
                                <Label className="align-card">
                                  Reason for Department? If can be prevented?
                                </Label>
                                <Form.Field
                                  control={TextArea}
                                  clearable
                                  upward={false}
                                  style={{ pointerEvents: 'auto' }}
                                  className="topMarginInput inlineLabel"
                                  selectOnBlur={false}
                                  search
                                  width={16}
                                  name="hrquestion1"
                                  disabled={formStatus !== "HR"}
                                  value={HRFormValue?.hrquestion1}
                                  onChange={(e) => onChange(e)}
                                  error={
                                    HRFormValueError?.hrquestion1Error
                                      ? HRFormValueError?.hrquestion1Error
                                      : null
                                  }
                                />
                              </Segment>
                            </div>

                            <div className="fill-left-small mt-2">
                              <Segment className="formGroup editor-container segment">
                                <Label className="align-card">
                                  Is there any suggestion/feedback from the
                                  employee?
                                </Label>
                                <Form.Field
                                  control={TextArea}
                                  clearable
                                  upward={false}
                                  style={{ pointerEvents: 'auto' }}
                                  className="topMarginInput inlineLabel"
                                  selectOnBlur={false}
                                  search
                                  width={16}
                                  name="hrquestion2"
                                  disabled={formStatus !== "HR"}
                                  value={HRFormValue?.hrquestion2}
                                  onChange={(e) => onChange(e)}
                                  error={
                                    HRFormValueError?.hrquestion2Error
                                      ? HRFormValueError?.hrquestion2Error
                                      : null
                                  }
                                />
                              </Segment>
                            </div>

                            <div className="fill-left-small mt-2">
                              <Segment className="formGroup editor-container segment">
                                <Label className="align-card">
                                  Would the employee wish to join us back in
                                  future
                                </Label>
                                <Form.Field
                                  control={TextArea}
                                  clearable
                                  upward={false}
                                  style={{ pointerEvents: 'auto' }}
                                  className="topMarginInput inlineLabel"
                                  selectOnBlur={false}
                                  search
                                  width={16}
                                  name="hrquestion3"
                                  disabled={formStatus !== "HR"}
                                  value={HRFormValue?.hrquestion3}
                                  onChange={(e) => onChange(e)}
                                  error={
                                    HRFormValueError?.hrquestion3Error
                                      ? HRFormValueError?.hrquestion3Error
                                      : null
                                  }
                                />
                              </Segment>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                  </Segment>
                </div>

                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.TextArea
                        rows="5"
                        label="DEPARTMENT HEAD NAME"
                        className="topMarginInput inlineLabel ml-left"
                        width={11}
                        name="hodName"
                        value={HODFormValue?.hodName}
                        disabled={formStatus !== "HOD"}
                        onChange={(e) => onChange(e)}
                        error={
                          HODFormValueError?.hodNameError
                            ? HODFormValueError?.hodNameError
                            : null
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.TextArea
                        rows="5"
                        label="DEPARTMENT HEAD SIGN"
                        className="topMarginInput inlineLabel ml-left"
                        width={11}
                        name="hodSign"
                        value={HODFormValue?.hodSign}
                        disabled={formStatus !== "HOD"}
                        onChange={(e) => onChange(e)}
                        error={
                          HODFormValueError?.hodSignError
                            ? HODFormValueError?.hodSignError
                            : null
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.TextArea
                        rows="5"
                        label="HR NAME"
                        className="topMarginInput inlineLabel ml-left"
                        width={11}
                        name="hrName"
                        value={HRFormValue?.hrName}
                        disabled={formStatus !== "HR"}
                        onChange={(e) => onChange(e)}
                        error={
                          HRFormValueError?.hrNameError
                            ? HRFormValueError?.hrNameError
                            : null
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.TextArea
                        rows="5"
                        label="HR SIGN"
                        className="topMarginInput inlineLabel ml-left"
                        width={11}
                        name="hrSign"
                        disabled={formStatus !== "HR"}
                        value={HRFormValue?.hrSign}
                        onChange={(e) => onChange(e)}
                        error={
                          HRFormValueError?.hrSignError
                            ? HRFormValueError?.hrSignError
                            : null
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.TextArea
                        rows="5"
                        label="EMPLOYEE SIGNATURE"
                        className="topMarginInput inlineLabel ml-left"
                        disabled={
                          formStatus === "HOD" ||
                          formStatus === "HR" ||
                          formStatus === "DONE"
                        }
                        width={11}
                        name="empSign"
                        value={staffFormValue?.empSign}
                        onChange={(e) => onChange(e)}
                        error={
                          staffFormValueError?.empSignError
                            ? staffFormValueError?.empSignError
                            : null
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {formStatus == "DONE" ? (
                  ""
                ) : (
                  <div className="buttonDiv mt-2">
                    <Button
                      type="button"
                      className="dotButton"
                      onClick={onSubmit}
                      loading={loading}
                      disabled={loading}
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          </Segment>
        </Grid>
      </div>
    </div>
  );
};
export default compose(withRouter)(Exit);
