import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Menu } from "semantic-ui-react";
import ContainerTitle from "../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../components/ContainerTitle/TitleWrapper";
import CustomTable from "../../../components/CustomTable/CustomTable";
import ExitHoc from "./ExitHoc";
import axios from "../../../utils/axios";
import moment from "moment";
import AccessCheck from "../../../utils/AccessCheck";
import { accessActionType, accessModule } from "../../../utils/accessConstant";
import { store } from "../../../redux/store";
import { formatDate } from "../../../utils/localTime";
import { SOMETHING_WRONG } from "../../../utils/labelContant";
import { toast } from "react-toastify";
const ExitCandidates = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    empcode: "",
    ename: "",
    dept: "",
    design: "",
  });
  const onSelect = (value, type) => {
    setSearch({
      ...search,
      [type]: value,
    });
  };
  const clickFunction = () => {
    props.history.push("exitForm/create");
  };
  // fetch all Exit list
  const listAPi = () => {
    axios
      .get(`/staff/fetchAllExit`, {
        params: {
          offset: pageNumber * 10 - 10,
          limit: 10,
          empcode: search.empcode,
          ename: search.ename,
          dept: search.dept,
          design: search.design,
        },
      })
      .then(async (result) => {
        let dataEmp = result?.data?.result[0]?.response_fetchExits;
        dataEmp = dataEmp.map((value) => {
          value.date_joining =
            value.date_joining === "0000-00-00"
              ? "-"
              : formatDate(value?.date_joining);
          value.date_reg =
            value.date_reg === "0000-00-00"
              ? "-"
              : formatDate(value?.date_reg);
          value.lwd =
            value.lwd === "0000-00-00"
              ? "-"
              : formatDate(value?.lwd);
          return value;
        });
        setData([...dataEmp]);
        setCount(dataEmp[0]?.Total_Count || 0);
      })
      .catch((error) => {toast.error(error, { toastId: SOMETHING_WRONG });})
  };
  useEffect(() => {
    listAPi();
  }, [search, pageNumber]);
  return (
    <>
      <Helmet>
        <title>Templates</title>
        <meta name="description" content="Templates Listing" />
      </Helmet>
      <div className="container-height-width">
        <div className="container-height-width">
          <TitleWrapper>
            <ContainerTitle title={"List of Exit Candidates"} />
            {AccessCheck(
              accessModule.STAFF_EXIT,
              accessActionType.CREATE,
              store.getState().global?.access
            ) && (
              <div>
                <Button
                  className="dotButton"
                  floated="right"
                  content="Add New"
                  color="green"
                  onClick={() => clickFunction()}
                />
              </div>
            )}
          </TitleWrapper>
        </div>

        <div className="customTableWraper">
          <CustomTable
            cols={[
              {
                id: "emp_code",
                title: "EMP Code",
                fixed: true,
                search: true,
                onSelect: onSelect,
                type: "empcode"
              },
              {
                id: "employee_name",
                title: "Name",
                search: true,
                onSelect: onSelect,
                type: "ename"
              },
              {
                id: "department",
                title: "Department",
                search: true,
                onSelect: onSelect,
                type: "dept"
              },
              {
                id: "designation",
                title: "Designation",
                search: true,
                onSelect: onSelect,
                type: "design"
              },
              {
                id: "date_joining",
                title: "Date of joining",
                search: false,
              },
              {
                id: "date_reg",
                title: "Date of Registration",
                search: false,
              },
              {
                id: "lwd",
                title: "Last Working Date",
                search: false,
              },
            ]}
            data={data}
            count={count}
            setPagination={(n) => setPageNumber(n)}
            activePage={pageNumber}
            error={(e) => {}}
            loading={loading}
            renderMenuItem={(row) => {
              const role = row.role;
              const id = row.id;
              return (
                <Menu fluid vertical tabular className="user-menu">
                  {AccessCheck(
                    accessModule.STAFF_EXIT,
                    accessActionType.UPDATE,
                    store.getState().global?.access
                  ) && (
                    <Menu.Item
                      name="edit"
                      // onClick={() => clickEdit(id, row.id, role)}
                      onClick={() =>
                        props.history.push(`exitForm/update/${row.id}`)
                      }
                    >
                      Edit / View
                    </Menu.Item>
                  )}
                </Menu>
              );
            }}
          />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default ExitHoc(ExitCandidates);