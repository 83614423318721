import { ARCHIVE_SEARCH, ARCHIVE_SEARCH_RESET,  SET_ARCHIVE,  SET_ARCHIVE_COUNT,  SET_PAGE_NO} from "./constants";

export const searchArchive = (name, value) => {
  return {
    type: ARCHIVE_SEARCH,
    name,value
  };
};
export const searchArchiveReset = () => {
  return {
    type: ARCHIVE_SEARCH_RESET,
   
  };
};
export const setPageNo = (pageNumber) => ({
  type: SET_PAGE_NO,
  pageNumber,
});
export const setArchive = (archive) => ({
  type: SET_ARCHIVE,
  archive,
});
export const setArchiveCount = (count) => ({
  type: SET_ARCHIVE_COUNT,
  count,
});