// import { SET_DEVICE_DETAILS, SET_ERROR, SET_ERROR_MESSAGE, SET_SET_VISIBLE, SET_USER_NAME } from "./constants";

import { ENQUIRY_COUNT, ENQUIRY_GET, ENQUIRY_GET_ERROR, ENQUIRY_GET_LIST_LOADING, ENQUIRY_GET_PAGE_NO, ENQUIRY_SEARCH, ENQUIRY_SEARCH_RESET, ADD_SOURCE ,ADD_EVENT,ADD_ENGAGEMENT,ADD_CATEGORISATION } from "./constants";

// /**
//  * Dispatched when loading the repositories fails
//  *
//  * @param  {string} error The error
//  *
//  * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
//  */
// export function setError(error) {
//     return {
//       type: SET_ERROR,
//       error,
//     };
//   }
//   /**
//    * Dispatched when loading the repositories fails
//    *
//    * @param  {string} error The error
//    *
//    * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
//    */
//   export function setErrorMessage(error) {
//     return {
//       type: SET_ERROR_MESSAGE,
//       error,
//     };
//   }
//   /**
//    * Dispatched when loading the repositories fails
//    *
//    * @param  {string} username The error
//    *
//    * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
//    */
//   export function setUserName(username) {
//     return {
//       type: SET_USER_NAME,
//       username,
//     };
//   }

//   // Device Width and Height
//   export const getWindowDimensions = () => {
//     const { innerWidth: width, innerHeight: height } = window;
//     const isMobile = width < 786;
//     return {
//       width,
//       height,
//       isMobile,
//     };
//   };
//   export const setDeviceHeightWidth = () => ({
//     type: SET_DEVICE_DETAILS,
//     payload: getWindowDimensions(),
//   });

//   // export const setDeviceDetails = deviceDetails => ({
//   //   type: 'SET_DEVICE_DETAILS',
//   //   payload: deviceDetails,
//   // });
//   // Close Device Width and Height
//   export const setSideBarVisible = () => ({
//     type: SET_SET_VISIBLE,
//   });

export const fetchEnquiryData = (query) => {
  return {
    type: "ENQUIRY_FETCH",
    query,
  };
};
export const addEnquiryData = (data) => {
  return {
    type: ENQUIRY_GET,
    data,
  };
};
export const errorEnquiryFetch = (error) => {
  return {
    type: ENQUIRY_GET_ERROR,
    error,
  };
};

export const setEnquiryPageNumber = (pageNumber) => {
  return {
    type: ENQUIRY_GET_PAGE_NO,
    pageNumber,
  };
};

export const setLoading = (loading) => {
  return {
    type: ENQUIRY_GET_LIST_LOADING,
    loading,
  };
};


export const setEnquiryCount = (count) => {
  return {
    type: ENQUIRY_COUNT,
     count,
  };
};


export const searchEnquiry = (name, value) => {
  return {
    type: ENQUIRY_SEARCH,
    name,value
  };
};
export const searchEnquiryReset = () => {
  return {
    type: ENQUIRY_SEARCH_RESET,
   
  };
};
export const setSource = (source) => {
  return {
      type: ADD_SOURCE,
      source,
  };
};
export const setEvent = (event) => {
  return {
      type: ADD_EVENT,
      event,
  };
};
export const setEngagement = (engagement) => {
  return {
      type: ADD_ENGAGEMENT,
      engagement,
  };
};
export const setCategorisation = (categorisation) => {
  return {
      type: ADD_CATEGORISATION,
      categorisation,
  };
};
