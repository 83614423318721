import { createSelector } from "reselect";
import { INITIAL_STATE } from "./reducers";


const selectRecurrenceDomain = (state) => state.recurrence || INITIAL_STATE;

const makeSelectRecurrence = () => createSelector(selectRecurrenceDomain, (recurrence) => recurrence);

export default makeSelectRecurrence;
export { selectRecurrenceDomain, makeSelectRecurrence };
