import * as CryptoJS from "crypto-js";
import { store } from "../redux/store";
 
let Key = store.getState().global?.userDetails?.token || "";
// function to encrypt and decrypt a string using crypto
const encryptData = (value, type, keyValue=Key) => {
    let data
    if (type === "enc") {
        data = value
        let cryptedData = CryptoJS.AES.encrypt(data, keyValue).toString()
        return cryptedData.split("/").join("::");
    }
    if (type === "dec") {
        data = value.split("::").join("/")
        let bytes = CryptoJS.AES.decrypt(data, Key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}
export default encryptData;