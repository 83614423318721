import { createSelector } from 'reselect';
import { INITIAL_STATE } from './reducers';

/**
 * Direct selector to the home state domain
 */

const selectEnquiryDomain = state => state.enquiry || INITIAL_STATE;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Home
 */

const makeSelectEnquiry = () =>
  createSelector(
    selectEnquiryDomain,
    enquiry => enquiry,
  );

export default makeSelectEnquiry;
export { selectEnquiryDomain , makeSelectEnquiry };
