import { memo, useEffect, useState } from "react";
import ModalWrapper from "../../components/Modals/ModalWrapper";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { compose } from "redux";
import { getObjectPresigned } from "../../utils/presignedUpload";
import { Grid } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import TitleWrapper from "../../components/ContainerTitle/TitleWrapper";
import ContainerTitle from "../../components/ContainerTitle/ContainerTitle";
import { FILE_NAME_LIST, HOW_TO_USE } from "../../utils/labelContant";

const UseDotkris = (props) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [fileData, setFileData] = useState([]);
  const [modalVideo, setModalVideo] = useState(null);

  const fileNameList = FILE_NAME_LIST;

  const getVideoData = async () => {
    try {
      const thumbnailImage = await getObjectPresigned("use_dotkris/dotimg.png");

      const promises = fileNameList.map(async (fileName) => {
        const result = await getObjectPresigned(fileName);
        let fileName1 = fileName?.split("/")[1]?.split(".");
        let fileName2 = fileName1[0]?.replace(/_/g, " ");

        return {
          url: result[0].data.result,
          thumbnail: thumbnailImage[0].data.result,
          name: fileName2,
        };
      });
      const newData = await Promise.all(promises);
      setFileData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getVideoData();
  }, []);
  const handleCloseLeaveCancellationModal = () => {
    setModalVideo(null);
  };

  return (
    <>
      <Helmet>
        <title>{HOW_TO_USE}</title>
        <meta name="description" content="Description of Jobs" />
      </Helmet>
      <>
        <div className="invoice-container master-add">
          <TitleWrapper>
            <div className="invoice-flex">
              <ContainerTitle title={HOW_TO_USE} />
            </div>
          </TitleWrapper>
        </div>
      </>
      <Grid className="sideMargin">
        <div className="tableWrapper">
          <div className="kris-video">
            {fileData.map((item, i) => (
              <div
                className="outer_video"
                key={i}
                onClick={() => {
                  setModalVideo(item.url);
                  setThumbnailUrl(item.name);
                }}
              >
                <>
                  <video
                    poster={item.thumbnail}
                    width="300"
                    height="200"
                    className="align-video"
                    oncontextmenu="return false;"
                    id="myVideo"
                    controls
                    controlsList="nodownload"
                  >
                    <source src={thumbnailUrl} type="video/mp4" />
                  </video>
                </>
                <div className="video-title">{item.name}</div>
              </div>
            ))}

            {modalVideo && (
              <ModalWrapper
                size="large"
                closeModal={handleCloseLeaveCancellationModal}
                closeIcon
              >
                <>
                  <div className="video-title-main">{thumbnailUrl}</div>
                  <div>
                    <video
                      width="1040"
                      height="500"
                      autoPlay
                      controls
                      onContextMenu={(e) => e.preventDefault()}
                      controlsList="nodownload"
                    >
                      <source src={modalVideo} type="video/mp4" />
                    </video>
                  </div>
                </>
              </ModalWrapper>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default compose(memo, withRouter)(UseDotkris);
