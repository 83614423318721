import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import Spinners from "../../../../components/Spinner/Spinners";
import makeSelectAddEnquiry from "../../../../redux/redux/addEnquiry/selectors";
import { makeSelectGlobal } from "../../../../redux/redux/global/selectors";
import axios from "../../../../utils/axios";
import { getCusLocalDateYear, getLocalTime } from "../../../../utils/localTime";
import * as qs from "query-string";
import {
  setSource,
  setCategorisation,
  setEngagement,
  setEvent,
} from "../../../../redux/redux/addEnquiry/action";
import { toast } from "react-toastify";
import {
  closeModalAction,
  openModalAction,
} from "../../../../redux/redux/modals/actions";
// import { DELETE_CONFIRMATION } from "../../../utils/constant.utils";
import { withRouter } from "react-router";
// import { setAssignsTo, setCompanies } from "../../redux/redux/addEnquiry/action";
// import makeSelectAddEnquiry from "../../redux/redux/addEnquiry/selectors";

export default function AddEnquiryHOC(ComposedComponent) {
  class HOC extends Component {
    state = {
      loading: true,
      mounted: false,
    };
    getEvent = () => {
      axios
        .get("/dds/listEnquiryEvent")
        .then((res) => {
          let data = [...res.data.result.rows];
          // data.ma
          data = data.map((item) => {
            return {
              key: `${item.id}_${item.event_name}`,

              value: item.id,
              text: item.event_name,
            };
          });
          this.props.dispatch(setEvent(data));
        })
        .catch((e) => {})
        .finally(() => {});
    };
    getSource = () => {
      axios
        .get("/dds/listEnquirySource")
        .then((res) => {
          let data = [...res.data.result.rows];
          data = data.map((item) => {
            return {
              key: `${item.id}_${item.name}`,

              value: item.id,
              text: item.name,
            };
          });
          this.props.dispatch(setSource(data));
        })
        .catch((e) => {})
        .finally(() => {});
    };
    getEngagement = () => {
      axios
        .get("/dds/listEnquiryEngagement")
        .then((res) => {
          let data = [...res.data.result.rows];
          data = data.map((item) => {
            return {
              key: `${item.id}_${item.name}`,

              value: item.id,
              text: item.name,
            };
          });
          this.props.dispatch(setEngagement(data));
        })
        .catch((e) => {})
        .finally(() => {});
    };
    getCategorisation = () => {
      axios
        .get("/dds/listEnquiryCategory")
        .then((res) => {
          let data = [...res.data.result.rows];
          data = data.map((item) => {
            return {
              key: `${item.id}_${item.name}`,

              value: item.id,
              text: item.name,
            };
          });
          this.props.dispatch(setCategorisation(data));
        })
        .catch((e) => {})
        .finally(() => {});
    };

    renderLoading = () => {
      if (this.state.loading) return <Spinners />;
      return <ComposedComponent {...this.props}/>;
    };
    async componentDidMount() {
      await this.getEvent();
      await this.getSource();
      await this.getEngagement();
      await this.getCategorisation();
      await this.setState({
        loading: false,
      });
    }
    render() {
      return <>{this.renderLoading()}</>;
    }
  }
  const mapStateToProps = createStructuredSelector({
    enquiry: makeSelectAddEnquiry(),
    global: makeSelectGlobal(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  return compose(withConnect, memo, withRouter)(HOC);
}
