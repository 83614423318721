
export const ADD_TEAM = "@staffProfile/ADD_TEAM";
export const RESET_TEAM = "@staffProfile/RESET_TEAM";
export const ADD_DESIGNATION = "@staffProfile/ADD_DESIGNATION";
export const RESET_DESIGNATION = "@staffProfile/RESET_DESIGNATION";
export const ADD_STAFF_ROLE = "@staffProfile/ADD_STAFF_ROLE";
export const RESET_STAFF_ROLE = "@staffProfile/RESET_STAFF_ROLE";
export const ADD_MANAGER = "@staffProfile/ADD_MANAGER";
export const RESET_MANAGER = "@staffProfile/RESET_MANAGER";
export const ADD_MARITAL_STATUS = "@staffProfile/ADD_MARITAL_STATUS";
export const RESET_MARITAL_STATUS = "@staffProfile/RESET_MARITAL_STATUS";
export const ADD_BLOOD_GROUP = "@staffProfile/ADD_BLOOD_GROUP";
export const RESET_BLOOD_GROUP = "@staffProfile/RESET_BLOOD_GROUP";
export const ADD_YEARLIST = "@staffProfile/ADD_YEARLIST";
export const RESET_YEARLIST = "@staffProfile/RESET_YEARLIST";
export const ADD_FULL_PROFILE = "@staffProfile/ADD_FULL_PROFILE";
export const RESET_FULL_PROFILE = "@staffProfile/RESET_FULL_PROFILE";
export const ADD_DEPARTMENT = "@staffProfile/ADD_DEPARTMENT";
export const ADD_GRADE = "@staffProfile/ADD_GRADE";
