export const percentAsPerStatus = (value) => {
    // function returns percent value according to job status
    let APIStatus = value || 'No';
    const status = {
            "job created": 1,
            "sow created": 1,
            "awaiting quote": 1,
            "job assigned": 1,
            "approval pending": 5,
            "quote rejected":5,
            "quote approved": 5,
            "job started":10,
            "missing info": 15,
            "in progress": 40,
            "query sent to client": 50,
            "query changes": 60,
            "on hold": 60,
            "feedback received":100,
            "under review": 80,
            "review change": 90,
            "dispatch": 100,
            "close": 100,
            "no": 1
        }
    let per = status[APIStatus.toLowerCase()] || status["no"];
    return per+'%';
}
