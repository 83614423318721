import produce from "immer";
import {
  SET_PAGE_NO,
  ARCHIVE_SEARCH,
  ARCHIVE_SEARCH_RESET,
  SET_ARCHIVE,
  SET_ARCHIVE_COUNT,
} from "./constants";
export const INITIAL_STATE = {
  pageNumber: 1,
  archiveSearch: [],
  archive: [],
  count: 0,
};
const archiveReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ARCHIVE_SEARCH:
        const data = { ...draft?.archiveSearch, [action.name]: action?.value };
        draft.archiveSearch = data;
        break;
      case ARCHIVE_SEARCH_RESET:
        draft.archiveSearch = {};
        break;
      case SET_PAGE_NO:
        draft.pageNumber = action?.pageNumber;
        break;
      case SET_ARCHIVE:
        draft.archive = action?.archive;
        break;
        case SET_ARCHIVE_COUNT:
          draft.count = action?.count;
          break;
      default:
        return draft;
    }
  });
};

export default archiveReducer;
