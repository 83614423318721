import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { compose } from "redux";
import cx from "classnames";
import { createStructuredSelector } from "reselect";
import makeSelectLogin from "../../redux/redux/login/selectors";
import { TimelineLite, gsap, Power2, TimelineMax } from "gsap";
import LoginAsClient from "./LoginAsClient";
import ForgotPassword from "./ForgotPassword";
import login_dot_logo from "../../styles/images/login_dot_logo.png";
import intro_dot from "../../styles/images/intro_dot.png";
import CodeVerification from "./CodeVerification";
import ResetPassword from "./ResetPassword";
import PasswordChangeResponse from "./PasswordChangeResponse";
import Bubbles from "./Bubbles";
import SecurityVerification from "./securityVerification";
import Security from "./SecurityQuestion";
import KeywordChangeResponse from "./KeywordChangeResponse";
import axios from "../../utils/axios";
import Theme from "./Theme";

gsap.registerPlugin([TimelineLite, TimelineMax]);
function Login() {

  const getTheme= async()=>{
    await axios.get("/access/roles/permissions/current-festival")
    .then((data)=>{
      setTheme(data?.data?.result)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getTheme();
  },[])

  // let openDot = new TimelineLite({ paused: true, smoothChildTiming: true });
  const title = "Login";
  const [loginType, setLoginType] = useState({
    type: "DOT_TEAM",
    title: "Client Login",
  });
  const [theme,setTheme]=useState("");
  const [loginTitle, setLoginTitle] = useState("Client Login");
  const [showParticle, setShowParticle] = useState(false);
  const [forgotSection, setForgotSection] = useState([]);
  const [formvalue, setFormvalue] = useState();
  const [email, setEmail] = useState("");
  const [keyword, setKeyword] = useState("");
  const [randomState, setRandomState] = useState([]);
  const [otp, setOtp] = useState("");

  const setShowParticleFlag = useCallback(() => {
    setShowParticle(true);
  }, []);
  const loginIntro = useCallback(() => {
    let tm = new TimelineMax({ smoothChildTiming: true, yoyo: true });
    tm.to("#login-main-intro-background", 2, {
      ease: "none",
      width: "100%",
      x: 0,
      scale: 1,
      opacity: 1,
    })
      .to(
        "#login-main-intro-logo",
        1.5,
        {
          ease: "none",
          width: "100%",
          translateX: 0,
          scale: 1,
          opacity: 1,
        },
        1.2
      )
      .to(
        "#login-main-intro-background",
        1,
        {
          clipPath: "circle(100% at 50% 0%)",
          delay: 1.6,
        },
        0.5
      )

      .to(
        "#cir4",
        2,
        {
          duration: 3,
          ease: "bounce.out",
          translateY: window.innerHeight / 2,
          delay: 1.5,
          scale: 1.5
        },
        1.5
      )

      .to(".hidable", 0.8, { opacity: 0, delay: 2, ease: "power2" }, 1.8)
      .to(
        "#login-main-intro-background",
        0.6,
        {
          backgroundColor: "transparent",
          delay: 2,
          onComplete: () => {
            setShowParticleFlag(true);
          },
        },
        2.4
      )
      .to(
        "#cir4",
        1.5,
        {
          ease: "power2.out",
          y: -window.innerHeight / 1.2,
          x: window.innerWidth / 5,
          delay: 2.8,
          height: window.innerHeight / 1.2,
          width: window.innerHeight / 1.2,
          background: "#193253",
          position: "relative",
          scale: 1
        },
        2.5
      )
      .to(
        "#logo_dot",
        1.2,
        {
          ease: "power2.out",
          delay: 2.8,
          opacity: 1,
        },
        2.7
      )
      .to(
        "#logo",
        1.2,
        {
          opacity: 1,
          onComplete: () => {},
        },
        2.8
      )
      .to(
        "#input_box",
        1.8,
        {
          // duration: 1.8,
          ease: "power2.out",
          delay: 3,
          opacity: 0.95,
          scale: 1,
          x: -window.innerWidth / 4.2,
          y: window.innerHeight / 3.4,
        },
        3.5
      )
      .to(
        "#input_toggler",
        1.5,
        {
          ease: "power2.out",
          delay: 4,
          opacity: 1,
          scale: 1,
          translateX: window.innerWidth / 8,
          translateY: window.innerHeight / 10
        },
        3.7
      );
    // .play()
    // .then((r) => setAnimationPlayed(true));
  }, [setShowParticleFlag]);

  const setLoginTitleCallback = useCallback(() => {
    setLoginType((prevState) => {
      // await setLoginTitle((loginT) => {
      //   if (prevState === "DOT_CLIENT") return "Team Login";
      //   return "Client Login";
      // });
      if (prevState.type === "DOT_TEAM")
        return { type: "DOT_CLIENT", title: "Team Login" };
      return { type: "DOT_TEAM", title: "Client Login" };
    });
  }, []);
  const toggleLoginType = useCallback(() => {
    let openDot = new TimelineLite({ paused: true, smoothChildTiming: true });
    // setLoginType((prevState) => {
    openDot
      .fromTo(
        "#client_login",
        {
          opacity: 1,
          ease: Power2.easeInOut,
          scale: 1,
          translateX: 0,
          translateY: 0,
        },
        {
          opacity: 0,
          ease: Power2.easeInOut,
          scale: 0,
          translateX: window.innerWidth / 8,
          translateY: window.innerHeight / 4,
          onComplete: () => {
            setLoginTitleCallback();
          },
        }
      )
      .fromTo(
        "#client_login",
        {
          opacity: 0,
          ease: Power2.easeInOut,
          scale: 0,
          translateX: window.innerWidth / 8,
          translateY: window.innerHeight / 4,
        },
        {
          opacity: 1,
          ease: Power2.easeInOut,
          scale: 1,
          translateX: 0,
          translateY: 0,
        }
      )
      .play();

    //   return prevState === "DOT_TEAM"
    //     ? (prevState = "DOT_CLIENT")
    //     : (prevState = "DOT_TEAM");
    // });
  }, [setLoginTitleCallback]);
  const onClickForgotPassword = (value) => {
    setForgotSection((prevState) => {
      return [...prevState, value];
    });
  };

  const onBackClick = useCallback(() => {
    let newArray = [...forgotSection];
    newArray.pop();
    setForgotSection([...newArray]);
  }, [setForgotSection, forgotSection]);

  const onSuccessClick = () => {
    new Promise((resolve, reject) => {
      resolve(setForgotSection([]));
    }).finally(() => {
      let toggle_input = new TimelineLite({ smoothChildTiming: true });
      toggle_input
        .fromTo(
          "#client_login",
          { opacity: 0, ease: Power2.easeInOut, y: -50 },
          { opacity: 1, ease: Power2.easeInOut, y: 0 },
          0.1
        )
        .fromTo(
          "#input_toggler",
          {
            scale: 0,
            opacity: 0,
            translateX: 0,
            translateY: 0,
          },
          {
            duration: 1,
            ease: "power2.out",
            opacity: 1,
            scale: 1,
            translateX: window.innerWidth / 8,
            translateY: window.innerHeight / 10,
          }
        )
        .play();
    });
  };

  const renderToggler = useCallback(() => {
    if (
      forgotSection &&
      forgotSection[forgotSection.length - 1] === "PASSWORD_RESPONSE"
    )
      return null;
    return (
      <div
        className={cx(
          {
            "input-toggler":
              forgotSection.length === 0 && loginType.type === "DOT_CLIENT",
          },
          {
            "input-toggler-blue":
              forgotSection.length === 0 && loginType.type === "DOT_TEAM",
          },
          { "forgot-section": forgotSection.length > 0 }
        )}
        id="input_toggler"
        onClick={() => {
          if (forgotSection.length > 0) return onBackClick();
          return toggleLoginType();
        }}
      >
        {forgotSection.length > 0
          ? "Back"
          : loginType.type === "DOT_CLIENT"
          ? "Login as Client"
          : "Login as Dot Team"}
      </div>
    );
  }, [forgotSection, onBackClick, loginType, toggleLoginType]);

  const renderParticle = useCallback(() => {
    if (!showParticle) return null;
    return(<>
    <Bubbles />
    <Theme theme={theme}/>

    </>
    ) 
    
    
    ;
  }, [showParticle]);

  const renderAnimation = useCallback(() => {
    loginIntro();
  }, [loginIntro]);
  const getRndInteger = () => {
    let intRand = [];
    while (intRand.length < 3) {
      var r = Math.floor(Math.random() * (8 - 1 + 1)) + 1;
      if (intRand.indexOf(r) === -1) intRand.push(r);
    }
    intRand.sort();
    setRandomState([...intRand]);
  };
  useEffect(() => {
    getRndInteger();
    renderAnimation();
  }, [renderAnimation]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Description of Login" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=no"
        />
      </Helmet>
      <div className="login-container">
        {renderParticle()}
        <div
          className="login-main-intro-background"
          id="login-main-intro-background"
        >
          <div className="login-main-intro-logo " id="login-main-intro-logo">
            <div
              className="login-intro-animation-header hidable"
              id="intro-logo"
            >
              <div className="login-intro-animation-header-img">
                <img
                  src={intro_dot}
                  alt={"Login logo"}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "scale-down",
                  }}
                />
              </div>
            </div>
            <div className="login-intro-animation-dots">
              <span id="cir1" className="hidable"></span>
              <span id="cir2" className="hidable"></span>
              <span id="cir3" className="hidable"></span>
              <span id="cir4">
                <div className="logo-dot" id="logo_dot">
                  <div className="logo" id="logo">
                    <img
                      src={login_dot_logo}
                      alt={login_dot_logo}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div
                    className="input-box"
                    id="input_box"
                    style={{
                      height: window.innerHeight / 1.7,
                      width: window.innerHeight / 1.7,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {forgotSection[forgotSection.length - 1] ===
                      "SECURITY_VERIFICATION" && (
                      <SecurityVerification
                        title={loginType.title}
                        formvalue={formvalue}
                        random={randomState}
                        onForgoPassword={onClickForgotPassword}
                        loginType={loginType.type}
                      />
                    )}
                    {forgotSection.length === 0 && (
                      <LoginAsClient
                        title={loginType.title}
                        onForgoPassword={onClickForgotPassword}
                        loginType={loginType.type}
                        email={(email) => setEmail(email)}
                        formvalue={(formvalue) => setFormvalue(formvalue)}
                        onNext={(value) => onClickForgotPassword(value)}
                      />
                    )}
                    {forgotSection[forgotSection.length - 1] ===
                      "FORGOT_PASSWORD" &&
                      forgotSection.length > 0 && (
                        <ForgotPassword
                          title={loginTitle}
                          email={(email) => setEmail(email)}
                          loginType={loginType.type}
                          onNext={(e) =>
                            onClickForgotPassword("CODE_VERIFICATION")
                          }
                        />
                      )}
                    {forgotSection[forgotSection.length - 1] ===
                      "CODE_VERIFICATION" &&
                      forgotSection.length > 0 && (
                        // <ForgotPassword />
                        <CodeVerification
                          title={loginTitle}
                          email={email}
                          otp={(otp) => setOtp(otp)}
                          loginType={loginType.type}
                          onNext={
                            (e) => onClickForgotPassword("RESET_PASSWORD")
                            // onClickForgotPassword("SECURITY")
                          }
                        />
                      )}
                    {forgotSection[forgotSection.length - 1] === "SECURITY" &&
                      forgotSection.length > 0 && (
                        <Security
                          title={loginTitle}
                          email={email}
                          keyword={(keyword) => setKeyword(keyword)}
                          loginType={loginType.type}
                          onNext={(e) =>
                            onClickForgotPassword("KEYWORD_RESPONSE")
                          }
                        />
                      )}
                    {forgotSection[forgotSection.length - 1] ===
                      "RESET_PASSWORD" &&
                      forgotSection.length > 0 && (
                        <ResetPassword
                          title={loginTitle}
                          email={email}
                          keyword={keyword}
                          loginType={loginType.type}
                          onNext={(e) =>
                            onClickForgotPassword("PASSWORD_RESPONSE")
                          }
                        />
                      )}
                    {forgotSection[forgotSection.length - 1] ===
                      "PASSWORD_RESPONSE" &&
                      forgotSection.length > 0 && (
                        <PasswordChangeResponse onNext={onSuccessClick} />
                      )}
                    {renderToggler()}

                    {forgotSection[forgotSection.length - 1] ===
                      "KEYWORD_RESPONSE" &&
                      forgotSection.length > 0 && (
                        <KeywordChangeResponse onNext={onSuccessClick} />
                      )}
                    {renderToggler()}
                  </div>
                </div>
              </span>
              <span id="cir5" className="hidable"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  login: makeSelectLogin(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Login);

// const toggleLoginType = () => {
//   setLoginType((prevState) => {
//     openDot
//     .fromTo(
//         "#client_login",
//         {
//           opacity: 1,
//           ease: Power2.easeInOut,
//           scale: 1,
//           translateX: 0,
//           translateY: 0,
//         },
//         {
//           opacity: 0,
//           ease: Power2.easeInOut,
//           scale: 0,
//           translateX: window.innerWidth / 8,
//           translateY: window.innerHeight / 4,

//           onComplete: () => {
//             setLoginTitle((loginT) => {
//               if (prevState === "DOT_CLIENT") return "Team Login";
//               return "Client Login";
//             });
//           },
//         }
//       )
//       .fromTo(
//         "#client_login",
//         {
//           opacity: 0,
//           ease: Power2.easeInOut,
//           scale: 0,
//           translateX: window.innerWidth / 8,
//           translateY: window.innerHeight / 4,
//         },
//         {
//           opacity: 1,
//           ease: Power2.easeInOut,
//           scale: 1,
//           translateX: 0,
//           translateY: 0,
//         }
//       )
//       .play();

//     return prevState === "DOT_TEAM"
//       ? (prevState = "DOT_CLIENT")
//       : (prevState = "DOT_TEAM");
//   });
// };
