import produce from "immer";
import {
  ADD_SOURCE ,
  ADD_EVENT,
  ADD_ENGAGEMENT,
  ADD_CATEGORISATION, 
} from "./constants";
export const INITIAL_STATE = {
  source: [],
  event: [],
  engagement: [],
  categorisation: [],
};
const addEnquiryReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_SOURCE:
        draft.source = [...action.source];
        break;
      case ADD_EVENT:
        draft.event = [...action.event];
        break;
      case ADD_ENGAGEMENT:
        draft.engagement = [...action.engagement];
        break;
      case ADD_CATEGORISATION:
        draft.categorisation = [...action.categorisation];
        break;
      default:
        return draft;
    }
  });
};

export default addEnquiryReducer;
