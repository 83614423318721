import moment from "moment";
import React from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { Button, Form, Grid, Header, Icon, Item, Progress, Segment } from "semantic-ui-react";

function SlaUpload(props) {
  return (
    <Grid.Column
        mobile={16}
        tablet={16}
        computer={16}
        className="topBottomMarginNone topMarginInput"
      >
        <div>
          <Segment raised className="chart-container-height">
            <div className="formContainer">
              <div className="progressHeading">Upload SLA Document</div>
              <Grid>
                <Grid.Row stretched>
                  <Grid.Column textAlign="center">
                    <Item
                      className="dotted"
                      // label='UPLOAD DOCUMENTS'
                      // onClick={() => this.cv.current.click()}
                    >
                      <Header icon>
                        <Icon className="iconOrange" name="cloud upload" />
                        <p>Upload Documents</p>
                        Browse or Drag and Drop
                      </Header>
                      <input
                        hidden
                        // ref={this.cv}
                        name="cv"
                        type="file"
                        // onChange={this.onFileSelected}
                        // multiple
                      />
                    </Item>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="buttonDiv topMarginInput">
                  <Button
                    type="submit"
                    className="dotButtonEnquiry"
                    // onClick={this.sendComment}
                    // loading={loadingOnSubmit}
                    // disabled={loadingOnSubmit}
                  >
                    Send
                    {/* {props.match && props.match.type && props.match.id
                      ? "Update"
                      : "Submit"} */}
                  </Button>
                </div>
            </div>
          </Segment>
        </div>
      </Grid.Column>
  );
}

export default SlaUpload;
