export const USERID = 248932; // for SLA
export const AWSID = {
  // for AWS upload and download
  // KeyId: "AKIAZ2FAEULHLFPUFFDK",
  // secretKey: "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",
};
export const NEWCLIENT = {
  password: "Dot@1234",
  keyword: "Dot@1234",
  lastWord: "dotkris",
};

export const SLAOPTIONS = [
  {
    key: "DOT PRO - seat model",
    text: "DOT PRO - seat model",
    label: "DOT PRO - seat model",
    value: "DOT PRO - seat model",
  },
  {
    key: "Audit Outsourcing",
    text: "Audit Outsourcing",
    label: "Audit Outsourcing",
    value: "Audit Outsourcing",
  },
  {
    key: "Annual Statutory Accounts",
    text: "Annual Statutory Accounts",
    label: "Annual Statutory Accounts",
    value: "Annual Statutory Accounts",
  },
  {
    key: "Bookkeeping Services",
    text: "Bookkeeping Services",
    label: "Bookkeeping Services",
    value: "Bookkeeping Services",
  },
  {
    key: "Management Accounts",
    text: "Management Accounts",
    label: "Management Accounts",
    value: "Management Accounts",
  },
  {
    key: "Cash flows forecasting",
    text: "Cash flows forecasting",
    label: "Cash flows forecasting",
    value: "Cash flows forecasting",
  },
  {
    key: "Accounts Department Outsourcing",
    text: "Accounts Department Outsourcing",
    label: "Accounts Department Outsourcing",
    value: "Accounts Department Outsourcing",
  },
  {
    key: "Payroll",
    text: "Payroll",
    label: "Payroll",
    value: "Payroll",
  },
  {
    key: "Personal Tax Returns",
    text: "Personal Tax Returns",
    label: "Personal Tax Returns",
    value: "Personal Tax Returns",
  },
  {
    key: "Corporation Tax Returns",
    text: "Corporation Tax Returns",
    label: "Corporation Tax Returns",
    value: "Corporation Tax Returns",
  },
  {
    key: "Other data processing, reconciliation, and data conversion assignments",
    text: "Other data processing, reconciliation, and data conversion assignments",
    label:
      "Other data processing, reconciliation, and data conversion assignments",
    value:
      "Other data processing, reconciliation, and data conversion assignments",
  },
];

export const GenderData = [
  {
    text: "male",
    value: "male",
    id: 1,
  },
  {
    text: "female",
    value: "female",
    id: 2,
  },
  {
    text: "others",
    value: "others",
    id: 3,
  },
];

export const TATOPTIONS = [
  {
    key: "Within deadline",
    text: "Within deadline",
    value: "GREEN",
  },
  {
    key: "After deadline < 10 days",
    text: "After deadline < 10 days",
    value: "RED",
  },
  {
    key: "After deadline + 10 days",
    text: "After deadline + 10 days",
    value: "GREY",
  },
];
export const QUOTATIONOPTIONS = [
  {
    key: "Quotes Pending",
    text: "Quotes Pending",
    value: "ORANGE",
  },
  {
    key: "2 Working Days",
    text: "2 Working Days",
    value: "GREEN",
  },
  {
    key: "After 2 days",
    text: "After 2 days",
    value: "GREY",
  },
  {
    key: "After 5 days",
    text: "After 5 days",
    value: "RED",
  },
];
export const VATANDYEAREND = [
  {
    key: "2 Working Days",
    text: "2 Working Days",
    value: "GREEN",
  },
  {
    key: "Quotes E Pending",
    text: "Quotes E Pending",
    value: "ORANGE",
  },
  {
    key: "After 2 days",
    text: "After 2 days",
    value: "GREY",
  },
  {
    key: "After 5 days",
    text: "After 5 days",
    value: "RED",
  },
];

export const VATMONTHOPTIONS = [
  {
    key: "Jan, Apr, Jul, Oct",
    text: "Jan, Apr, Jul, Oct",
    value: "01,04,07,10",
  },
  {
    key: "Feb, May, Aug, Nov",
    text: "Feb, May, Aug, Nov",
    value: "02,05,08,11",
  },
  {
    key: "Mar, Jun, Sept, Dec",
    text: "Mar, Jun, Sept, Dec",
    value: "03,06,09,12",
  },
];
export const YEARENDMONTHOPTIONS = [
  {
    key: "Q1(Jan to Mar)",
    text: "Q1(Jan to Mar)",
    value: "01,02,03",
  },
  {
    key: "Q2(Apr to Jun)",
    text: "Q2(Apr to Jun)",
    value: "04,05,06",
  },
  {
    key: "Q3(Jul to Sept)",
    text: "Q3(Jul to Sept)",
    value: "07,08,09",
  },
  {
    key: "Q4(Oct to Dec)",
    text: "Q4(Oct to Dec)",
    value: "10,11,12",
  },
];
export const FEEDBACKOPTIONS = [
  {
    key: "0-4",
    text: "0-4",
    value: "1,2,3,4",
  },
  {
    key: "5-7",
    text: "5-7",
    value: "5,6,7",
  },
  {
    key: "8-10",
    text: "8-10",
    value: "8,9,10",
  },
];
export const EXITREASONOPTIONS = [
  { key: "Personal_Reason", text: "Personal Reason", value: "Personal_Reason" },
  { key: "Higher_Monetary", text: "Higher Monetary", value: "Higher_Monetary" },
  { key: "Closer_to_Home", text: "Personal Reason", value: "Closer_to_Home" },
  { key: "Further_Studies", text: "Further Studies", value: "Further_Studies" },
  {
    key: "Dissatisfied_at_Company",
    text: "Dissatisfied at Company",
    value: "Dissatisfied_at_Company",
  },
  { key: "Relocation", text: "Relocation", value: "Relocation" },
  { key: "Termination", text: "Termination", value: "Termination" },
  {
    key: "Heath _Retirement",
    text: "Health Retirement",
    value: "Heath _Retirement",
  },
  { key: "Retirement", text: "Retirement", value: "Retirement" },
];
