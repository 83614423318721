import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Form, Grid, Input, Segment, Select, TextArea } from "semantic-ui-react";
import ContainerTitle from "../../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../../components/ContainerTitle/TitleWrapper";
import Stepper from "../../../../components/Stepper/stepper";
import AddEnquiryHOC from "./addEnquiryListHOC";
import axios from "../../../../utils/axios";
import {
  addressValidator,
  isEmail,
  isMobile,
  isSelectEmpty,
  isZipCode,
  stringType,
} from "../../../../utils/validator";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Trial from "./trial";
import Feedback from "./feedback";
import SlaStatus from "./slaStatus";
import SlaUpload from "./slaUpload";
function AddEnquiry({ ...props }) {
  const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [bottomLeft, setBottomLeft] = useState(13);
  const [formValue, setFormValue] = useState({
    name: "",
    company: "",
    email: "",
    mobile: "",
    address: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    source: "",
    event: "",
    engagement: "",
    category: "",
    notes: "",
  });
  const [formValueError, setFormValueError] = useState({
    nameError: "",
    companyError: "",
    emailError: "",
    mobileError: "",
    addressError: "",
    cityError: "",
    countryError: "",
    stateError: "",
    zipcodeError: "",
    sourceError: "",
    eventError: "",
    engagementError: "",
    categoryError: "",
    notesError: "",
  });

  const getEnquiryById = () => {
    axios
      .get(`/marketing/enquiry/fetchEnquiryById?id=${props.match.params.id}`)
      .then(async (res) => {
        let data = await { ...res.data.result };
        let updateData = await {
          name: data.name || "",
          company: data.company_name || "",
          email: data.email || "",
          mobile: data.mobile || "",
          address: data.address || "",
          city: data.city || "",
          country: data.country || "",
          state: data.state || "",
          zipcode: data.zipcode || "",
          source: data.Enquiry_Source.id || "",
          event: data.Enquiry_event.id || "",
          engagement: data.Enquiry_engagement.id || "",
          category: data.Enquiry_Category.id || "",
          notes: data.engagement_notes || "",
        };

        setFormValue((prevState) => updateData);
      })
      .catch((res) => {
        setFormValue({
          name: "",
          company: "",
          email: "",
          mobile: "",
          address: "",
          city: "",
          country: "",
          state: "",
          zipcode: "",
          source: "",
          event: "",
          engagement: "",
          category: "",
          notes: "",
        });
      })
      .finally(() => {});
  };

  const stepsArray = [
    // "Create your account",
    "Info & Onboard ",
    "Trial & feedback",
    "SLA",
  ];
  useEffect(() => {
    if (props.match.params && props.match.params.type && props.match.params.id) {
      getEnquiryById();
    }
    return () => {};
  }, [props.match.params.id, props.match.params.type, props.match.params]);
  const onValidator = (e) => {
    switch (e.target.name) {
      case "name":
        return stringType(e.target.value, "Name");
      case "company":
        return stringType(e.target.value, "Company Name");
      case "email":
        return isEmail(e.target.value, "Email Address");
      case "mobile":
        return isMobile(e.target.value, "Mobile Number");
      case "address":
        return addressValidator(e.target.value, "Address");
      case "city":
        return stringType(e.target.value, "City");
      case "country":
        return stringType(e.target.value, "Country");
      case "state":
        return stringType(e.target.value, "State");
      case "zipcode":
        return isZipCode(e.target.value, "Zip Code");
      case "source":
        return isSelectEmpty(e.target.value, "Source of Enquiry");
      case "event":
        return isSelectEmpty(e.target.value, "Event");
      case "engagement":
        return isSelectEmpty(e.target.value, "Engagement");
      case "category":
        return isSelectEmpty(e.target.value, "Enquiry Categorisation");
      case "notes":
        return addressValidator(e.target.value, "Engagement Notes");
      default:
        return null;
    }
  };
  const onChange = (e) => {
    // let name = e.target.name;
    // let value = e.target.value;

    let newValues = { ...formValue, [e.target.name]: e.target.value };
    let errorData = {
      ...formValueError,
      [`${e.target.name}Error`]: onValidator(e),
    };
    setFormValue({ ...newValues });
    setFormValueError({ ...errorData });
  };

  const handleClick = (clickType) => {
    // const { currentStep } = this.state;
    let newStep = currentStep;
    clickType === "next" ? newStep++ : newStep--;

    if (newStep > 0 && newStep <= 4) {
      setCurrentStep(newStep);
    }

    if (newStep === 0) {
      setBottomLeft(13);
    } else if (newStep === 1) {
      setBottomLeft(13);
    } else if (newStep === 2) {
      setBottomLeft(42);
    } else if (newStep > 2) {
      setBottomLeft(70);
    } else {
      setBottomLeft(200);
    }
  };

  const validate = () => {
    let isError = false;
    let data = { ...formValue };
    Object.values({ ...formValue }).forEach((value) => {
      if (!value) {
        isError = true; //for future validation do not remove
      }
    });

    return isError;
  };
  const onSubmitValidate = async () => {
    let data = { ...formValue };
    // this.state.dataError.map(async (data) => {
    await Object.keys(data).forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };

      await setFormValueError((prevState) => {
        return { ...prevState, [`${key}Error`]: onValidator(e) };
      });
    });
  };

  // const addCompany = (name) => {
  //   if (!name) return;
  //   axios
  //     .post("/master/createCompany", {
  //       name: name,
  //       // createdBy: props.global.username,
  //     })
  //     .then(async (res) => {
  //       await props.dispatch(resetAddCompany());
  //       await props.dispatch(closeModalAction());
  //       toast.success("Company Added Successfully", {
  //         toastId: "companyAddSuccessfully",
  //       });
  //       props.getCompanies();
  //     })
  //     .catch((e) => {})
  //     .finally(() => {});
  // };

  const newEnquiry = () => {
    setLoadingOnSubmit(true);
    axios
      .post("/marketing/enquiry/addEnquiry", formValue)
      .then((res) => {
        if (res.status === 200)
          toast.success("Enquiry Created Successfully", {
            toastId: "clientAddedSuccessfully",
          });
        props.history.replace("/enquiryList");
      })
      .catch((e) => {
        let response = e.response;
        toast.error(
          response.data.message ||
            "Something went wrong, Please try again later",
          {
            toastId: "clientAddedError",
          }
        );
      })
      .finally(() => {
        setLoadingOnSubmit(false);
      });
  };
  const updateEnquiry = () => {
    setLoadingOnSubmit(true);
    axios
      .put(`/marketing/enquiry/editEnquiry?id=${props.match.params.id}`, formValue)
      .then((res) => {
        if (res.status === 200)
          toast.success(
            `Enquiry Updated Successfully with ID: ${props.match.params.id}`,
            {
              toastId: "clientUpdatedSuccessfully",
            }
          );
      })
      .catch((e) => {
        let response = e.response;

        toast.error(
          response.data.message ||
            "Something went wrong, Please try again later",
          {
            toastId: "clientAddedError",
          }
        );
      })
      .finally(() => {
        if (formValue.category === 2) {
          handleClick("next");
        } else {
          props.history.replace("/enquiryList");
        }
        setLoadingOnSubmit(false);
      });
  };
  const onSubmit = async () => {
    let isError = await validate();
    if (!isError) {
        if (props.match.params.type && props.match.params.id) {
          updateEnquiry();
        } else {
          newEnquiry();
        }
    } else {
      toast.error("Please enter all mandatory fields", {
        toastId: "JobInvalidData",
      });
      onSubmitValidate();
    }
  };

  const renderPageFunction = () => {
    if (currentStep === 1)
      return (
        <>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="topBottomMarginNone"
          >
            <div>
              <Segment raised className="chart-container-height">
                <div className="formContainer">
                  <Form className="fontQuicksand">
                    <Form.Group
                      widths={16}
                      className="formGroup marginBottomNone"
                    >
                      <Form.Field
                        className="inlineLabel topMarginInput margin-side mt-2"
                        label="NAME"
                        placeholder="Name"
                        width={8}
                        name="name"
                        value={formValue.name}
                        onChange={onChange}
                        control={Input}
                        error={
                          formValueError.nameError
                            ? formValueError.nameError
                            : null
                        }
                      />

                      <Form.Field
                        className="inlineLabel topMarginInput margin-side"
                        label="COMPANY NAME"
                        placeholder="Company Name"
                        width={8}
                        name="company"
                        value={formValue.company}
                        onChange={onChange}
                        control={Input}
                        error={
                          formValueError.companyError
                            ? formValueError.companyError
                            : null
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      className="formGroup marginBottomNone"
                      widths={16}
                    >
                      <Form.Field
                        className="inlineLabel topMarginInput margin-side"
                        label="EMAIL ADDRESS"
                        placeholder="Email Address"
                        width={8}
                        name="email"
                        value={formValue.email}
                        onChange={onChange}
                        control={Input}
                        error={
                          formValueError.emailError
                            ? formValueError.emailError
                            : null
                        }
                      />
                      <Form.Field
                        className="inlineLabel topMarginInput margin-side"
                        label="MOBILE NUMBER"
                        placeholder="Mobile Number"
                        width={8}
                        name="mobile"
                        value={formValue.mobile}
                        onChange={onChange}
                        control={Input}
                        error={
                          formValueError.mobileError
                            ? formValueError.mobileError
                            : null
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      widths={16}
                      className="formGroup marginBottomNone"
                    >
                      <Form.Field
                        className="inlineLabel topMarginInput margin-side"
                        label="ADDRESS"
                        placeholder="Address"
                        width={16}
                        rows={2}
                        name="address"
                        value={formValue.address}
                        onChange={onChange}
                        control={TextArea}
                        error={
                          formValueError.addressError
                            ? formValueError.addressError
                            : null
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      widths={16}
                      className="formGroup marginBottomNone"
                    >
                      <Form.Field
                        className="inlineLabel topMarginInput margin-side"
                        label="CITY"
                        placeholder="City"
                        width={8}
                        name="city"
                        value={formValue.city}
                        onChange={onChange}
                        control={Input}
                        error={
                          formValueError.cityError
                            ? formValueError.cityError
                            : null
                        }
                      />
                      <Form.Field
                        className="inlineLabel topMarginInput margin-side"
                        label="COUNTRY"
                        placeholder="Country"
                        width={8}
                        name="country"
                        value={formValue.country}
                        onChange={onChange}
                        control={Input}
                        error={
                          formValueError.countryError
                            ? formValueError.countryError
                            : null
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      widths={16}
                      className="formGroup marginBottomNone"
                    >
                      <Form.Field
                        className="inlineLabel topMarginInput margin-side"
                        label="STATE"
                        placeholder="State"
                        width={8}
                        name="state"
                        value={formValue.state}
                        onChange={onChange}
                        control={Input}
                        error={
                          formValueError.stateError
                            ? formValueError.stateError
                            : null
                        }
                      />
                      <Form.Field
                        className="inlineLabel topMarginInput margin-side"
                        label="ZIP CODE"
                        placeholder="Zip Code"
                        width={8}
                        name="zipcode"
                        value={formValue.zipcode}
                        onChange={onChange}
                        control={Input}
                        error={
                          formValueError.zipcodeError
                            ? formValueError.zipcodeError
                            : null
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      widths={16}
                      className="formGroup marginBottomNone"
                    >
                      <Form.Field
                        control={Select}
                        label="SOURCE OF ENQUIRY"
                        placeholder="Source of Enquiry"
                        searchInput={{ id: "form-select-control-role" }}
                        className="inlineLabel topMarginInput margin-side"
                        options={props.enquiry.source}
                        search
                        clearable
                        width={8}
                        value={formValue.source}
                        name="source"
                        selectOnBlur={false}
                        onChange={async (e, { value }) => {
                          e.target.value = await value;
                          e.target.name = await "source";
                          await onChange(e);
                        }}
                        error={
                          formValueError.sourceError
                            ? formValueError.sourceError
                            : null
                        }
                      />
                      <Form.Field
                        control={Select}
                        label="EVENT"
                        placeholder="Event"
                        searchInput={{ id: "form-select-control-role" }}
                        className="inlineLabel topMarginInput margin-side"
                        search
                        options={props.enquiry.event}
                        clearable
                        width={8}
                        value={formValue.event}
                        name="event"
                        selectOnBlur={false}
                        onChange={async (e, { value }) => {
                          e.target.value = await value;
                          e.target.name = await "event";
                          await onChange(e);
                        }}
                        error={
                          formValueError.eventError
                            ? formValueError.eventError
                            : null
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      widths={16}
                      className="formGroup marginBottomNone"
                    >
                      <Form.Field
                        control={Select}
                        label="ENGAGEMENT"
                        placeholder="Engagement"
                        searchInput={{ id: "form-select-control-role" }}
                        className="inlineLabel topMarginInput margin-side"
                        search
                        options={props.enquiry.engagement}
                        clearable
                        width={8}
                        value={formValue.engagement}
                        name="engagement"
                        selectOnBlur={false}
                        onChange={async (e, { value }) => {
                          e.target.value = await value;
                          e.target.name = await "engagement";
                          await onChange(e);
                        }}
                        error={
                          formValueError.engagementError
                            ? formValueError.engagementError
                            : null
                        }
                      />
                      <Form.Field
                        control={Select}
                        label="ENQUIRY CATEGORISATION"
                        placeholder="Enquiry Categorisation"
                        searchInput={{ id: "form-select-control-role" }}
                        className="inlineLabel topMarginInput margin-side"
                        options={props.enquiry.categorisation}
                        search
                        clearable
                        width={8}
                        value={formValue.category}
                        name="category"
                        selectOnBlur={false}
                        onChange={async (e, { value }) => {
                          e.target.value = await value;
                          e.target.name = await "category";
                          await onChange(e);
                        }}
                        error={
                          formValueError.categoryError
                            ? formValueError.categoryError
                            : null
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        className="inlineLabel topMarginInput margin-side mb-2 "
                        label="ENGAGEMENT NOTES"
                        placeholder="Engagement Notes"
                        width={16}
                        rows={2}
                        name="notes"
                        value={formValue.notes}
                        onChange={onChange}
                        control={TextArea}
                        error={
                          formValueError.notesError
                            ? formValueError.notesError
                            : null
                        }
                      />
                    </Form.Group>

                    <div className="buttonDiv">
                      <Button
                        type="submit"
                        className="dotButton mb-2 margin-side"
                        onClick={onSubmit}
                        loading={loadingOnSubmit}
                        disabled={loadingOnSubmit}
                      >
                        Submit &amp; Next
                        {/* {props.match && props.match.type && props.match.id
                      ? "Update"
                      : "Submit"} */}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Segment>
            </div>
          </Grid.Column>
        </>
      );
    if (currentStep === 2)
      return (
        <>
          <Trial trialId={props.match.params.id}/>
          <Feedback />
        </>
      );
    if (currentStep > 2)
      return (
        <>
          <SlaStatus />
          <SlaUpload />
        </>
      );
  };
  return (
    <Grid className="sideMargin">
      <Helmet>
        <title>
          Add New Enquiry
          {/* {props.match && props.match.type && props.match.id
            ? "Update New Client"
            : "Add New Client"} */}
        </title>
        <meta name="description" content="Description of Clients" />
      </Helmet>

      <div className={"container-height-width"}>
        <TitleWrapper>
          <ContainerTitle
            title={
              "Add New Enquiry"
              // props.match && props.match.type && props.match.id
              //   ? "Update New Client"
              //   : "Add New Client"
            }
          />
        </TitleWrapper>
      </div>
      <Grid.Column
        mobile={16}
        tablet={16}
        computer={16}
        // className="topBottomMarginNone"
      >
        <div>
          <Segment raised className="chart-container-height">
            <div className="stepContainer">
              <Stepper
                direction="horizontal"
                currentStepNumber={currentStep - 1}
                leftValue={bottomLeft}
                steps={stepsArray}
                stepColor="#F18A00"
              />

              <div className="buttons-container">
                <button onClick={() => handleClick()}>Previous</button>
                <button onClick={() => handleClick("next")}>Next</button>
              </div>
            </div>
          </Segment>
        </div>
      </Grid.Column>
      {renderPageFunction()}
    </Grid>
  );
}

export default AddEnquiryHOC(AddEnquiry);
