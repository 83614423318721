import produce from "immer";
import {
  SET_APPLY_LEAVE_LOADING,
  SET_ERROR,
  SET_LEAVE_COUNTS,
  SET_LEAVE_LIST,
} from "./constants";
export const INITIAL_STATE = {
  error: "",
  leaveList: [],
  leaveCounts: [],
  applyingLeave: false,
};
const my_account_leave_reducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_ERROR:
        draft.error = action.error;
        break;
      case SET_LEAVE_COUNTS:
        draft.leaveCounts = [...action.counts] || [];
        break;
      case SET_LEAVE_LIST:
        draft.leaveList = [...action.list] || [];
        break;
      case SET_APPLY_LEAVE_LOADING:
        draft.applyingLeave = action.loading;
        break;
      
      default:
        return draft;
    }
  });
};

export default my_account_leave_reducer;
