import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { TimelineLite, Power2, gsap } from "gsap";
import OtpInput from "react-otp-input";
import axios from "../../utils/axios";
gsap.registerPlugin([TimelineLite]);
export default function Security(props) {
    const [security, setSecurity] = useState("");
    const [emailSuccess, setEmailSuccess] = useState("");
    const [securityError, setSecurityError] = useState('');
const Message = (
    <p style={{ marginRight: '-10px',  marginLeft: '-12px' }}>
      You will be asked any random character from the
      <br />
      above set keyword for authentication purposes.
    </p>
  );

    useEffect(() => {
        let recovery = new TimelineLite({ paused: true, smoothChildTiming: true, reversed: true, yoyo: true });
        recovery
            .fromTo(
                "#code_verification",
                { opacity: 0, ease: Power2.easeInOut, y: -50 },
                { opacity: 1, ease: Power2.easeInOut, y: 0 }
            )
            .play();
        return () => { };
    }, []);

    const getCodeBoxElement = (index) => {
        return document.getElementById("codeBox" + index);
    };
    const onKeyUpEvent = (index, event) => {
        const eventCode = event.which || event.keyCode;
        if (getCodeBoxElement(index).value.length === 1) {
            if (index !== 6) {
                getCodeBoxElement(index + 1).focus();
            } else {
                getCodeBoxElement(index).blur();
            }
        }
        if (eventCode === 8 && index !== 1) {
            getCodeBoxElement(index - 1).focus();
        }
    };
    const validate = () => {
        const regex = /^(?=[^a-zA-Z\n]*[a-zA-Z])(?=[^a-zA-Z\n]*[a-zA-Z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/;
        if (!regex.test(security)) {
            setSecurityError("Please enter a valid code")
        }
        else if (security.length < 8) {
            setSecurityError("Your password must be have 8 characters")
        }
        else if (security === '') {
            setSecurityError('Please enter Security Code')
        }
        else {
            onSuccess(security)
            // props.onNext()
            // props.keyword(security)
        }

    }
    const loginTypeValue = () => {
        if (props.loginType === "DOT_TEAM") {
            return { role: 4 };
        }
        return {};
    };
    const onSuccess = (security) => {
        let value = { email: props.email, securitycode: security, ...loginTypeValue() };
        axios
            .put("/forgetPassword/resetKeyword", value, {
                headers: {},
            })
            .then((response) => {
                console.log("response",response);
                if (response.status === 200) {
                    props.onNext()
                }
                else {
                    setSecurityError("Invalid Keyword")
                }
            })
            .catch((er) => {
                if(er?.response?.data?.err === "Security keyword must contain at least one lowercase letter."){
                     setSecurityError("Please enter a valid code")
                }
                   else {
                    setSecurityError("Internal Server Error")
                   }
            })
            .finally(() => {
            });
    }
    const onOtpChange = (e) => {
        setSecurity(e);
        setSecurityError('')
    };
    const slug = security.substring(security.indexOf(7));
    return (
        <div className="client-login recovery" id="code_verification">
            <p className="login-input-header-keyword">Security Keyword</p>
            <div className="input-form-control keywordMainBody" name='formOtp' style={{ width: "auto" }}>
                <div>
                    <p className={"setKeywordMessage"}>As an additional measure to keep you account safe, please setup a memorable word.  This must be minimum 8 characters and to include 1 letter, 1 number and 1 special character. You will be asked to enter any characters from it when you sign in again.</p>
                </div>
                <label className="input-form-control-label" htmlFor="otp">
                    Code
                </label>
                <OtpInput
                    value={security}
                    onChange={onOtpChange}
                    numInputs={8}
                    shouldAutoFocus
                    inputStyle={"inputStyle circle-3rem"}
                    id={"otp"}
                />
                <div className='errorLabel'>
                    {securityError}
                </div>

                <div className='successLabel text-center'>
                    {emailSuccess}
                    {security.length === 8 ? "Your security code : " + slug
                        : ""}
                    {security.length === 8 ? Message : ""}
                </div>
            </div>

            <button
                className="login-type-submit"
                style={{
                    height: window.innerHeight / 12,
                    width: window.innerHeight / 12,
                }}
                onClick={
                    validate
                }
            >
                <Icon name={"arrow right"} size="large" />
            </button>
        </div>
    );
}
