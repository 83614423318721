import { toast } from "react-toastify";

export const getOffset = (pageNumber, flag) => {

    return flag ? "" : (pageNumber * 10) - 10;
};

export const getLimit = (pageNumber, flag) => {

    return flag ? "" : pageNumber * 10;

};

export const restrictFileFormats = (files) => {
    const restrictedFormats = ['php','js','html','css']; 
    const invalidFile = files.find((file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        return restrictedFormats.includes(fileExtension);
      });
      if (invalidFile) {
        const fileExtension = invalidFile.name.split('.').pop().toLowerCase(); 
        toast.error(`${fileExtension} format is not allowed`, {
        toastId: "JobInvalidFileFormat",
        });
        return false;
      }
    return true;
  };
  