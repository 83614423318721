export const SET_ERROR = "@my_account/SET_ERROR";
export const RESET_ERROR = "@my_account/RESET_ERROR";
export const SET_ERROR_MESSAGE = "@my_account/SET_ERROR_MESSAGE";


export const SET_PROFILE = "@my_account/SET_PROFILE"
export const RESET_PROFILE ="@my_account/RESET_PROFILE"



export const EDIT_PROFILE ="@my_account/EDIT_PROFILE"