import { createSelector } from "reselect";
import { INITIAL_STATE } from "./reducers";


const selectArchiveDomain = (state) => state.archive || INITIAL_STATE;

const makeSelectArchive = () => createSelector(selectArchiveDomain, (archive) => archive);

export default makeSelectArchive;
export { selectArchiveDomain, makeSelectArchive };
