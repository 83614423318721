import React, { useState } from "react";

import { connect, useDispatch } from "react-redux";

import { createStructuredSelector } from "reselect";

import { Button, Form, Grid, Select } from "semantic-ui-react";

import ModalWrapper from "../../../components/Modals/ModalWrapper";

import { makeSelectDeviceDimension } from "../../../redux/redux/device/selectors";

import {
  resetAddPlaning,
  setAddPlaning,
} from "../../../redux/redux/masters/action";

import makeSelectMaster from "../../../redux/redux/masters/selectors";

import { closeModalAction } from "../../../redux/redux/modals/actions";

function AddPlaning(props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  return (
    <ModalWrapper
      size="mini"
      header={props.header || "Add Planing Activity"}
      esc
      scrolling
      className={"add"}
      closeModal={props.closeModal}
    >
      <Form.Group className="">
        <Form.Field
          control={Select}
          label={{
            children: "Job Type",

            htmlFor: "form-select-control-manager",
          }}
          placeholder="Job Type"
          searchInput={{ id: "form-select-control-manager" }}
          className="inlineLabel topMarginInput"
          search
          options={props.master.jobType}
          clearable
          // width={8}

          value={props.master.addPlaning.type}
          selectOnBlur={false}
          name="type"
          onChange={async (e, { value }) => {
            e.target.value = await value;

            e.target.name = await "type";

            // await onChange(e);

            dispatch(setAddPlaning(e));
          }}

          // error={

          //   formValueError.managerError

          //     ? formValueError.managerError

          //     : null

          // }
        />

        <Form.Input
          className=""
          label="Planing Activity"
          placeholder="Planing Activity"
          // width={16}

          name="planing"
          value={props.master.addPlaning.planing}
          onChange={(e) => dispatch(setAddPlaning(e))}
          style={{
            display: "flex",

            alignItems: "flex-start",

            justifyContent: "flex-start",

            flexDirection: "column",

            width: "100% !important",
          }}
        />
      </Form.Group>

      <Grid
        columns="2"
        centered
        verticalAlign="middle"
        style={{ marginTop: "20px" }}
      >
        <Grid.Column>
          <Button
            style={{ width: "100%" }}
            className="secondary"
            onClick={() => {
              setLoading(true);

              props.onSubmit(props.master.addPlaning);
            }}
            loading={loading}
            disabled={loading}
          >
            {props.type && props.type === "edit" ? "UPDATE" : "ADD"}
          </Button>
        </Grid.Column>

        <Grid.Column>
          <Button
            basic
            color="red"
            style={{ width: "100%" }}
            onClick={() => {
              dispatch(resetAddPlaning());

              dispatch(closeModalAction());
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        </Grid.Column>
      </Grid>
    </ModalWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  dimension: makeSelectDeviceDimension(),

  master: makeSelectMaster(),
});

export default connect(mapStateToProps, null)(AddPlaning);
