import React from 'react'
// import MoonLoader from "react-spinners/MoonLoader";
// import HashLoader from "react-spinners/HashLoader";
import ClipLoader from "react-spinners/ClipLoader";
export default function Spinners() {
    return (
        <div className="full-container">
             <ClipLoader color={'#193256'} loading={true}  size={65} />
        </div>
    )
}
