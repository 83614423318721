// function for random numbers
export const get_random = (list) => {
  return list[Math.floor(Math.random() * list.length)];
};

// function for random numbers with range
export function generateRandomInteger(min, max) {
  return Math.floor(min + Math.random() * (max + 1 - min))
}
// function to convert number to two digit decimal value
export function twoDecimal(num, value) {
  // var num = Number(num);
  // num = num.toFixed(value || 2);
  return parseFloat(num).toFixed(2);
}
export function twoDecimalNumber(num) {
  return num == 0 ? "" :parseFloat(num).toFixed(2);
}

export const thousandSeparator = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// function to convert number to two digit decimal value optimised
export function decimalFloat(numb) {
  numb = parseFloat(numb)
  var rounded = Math.round((numb + Number.EPSILON) * 100) / 100;
  return rounded;
}