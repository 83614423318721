import produce from "immer";
import { EDIT_PROFILE, RESET_PROFILE, SET_PROFILE } from "./constants";
export const INITIAL_STATE = {
  profile: null,
};
const myAccountReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_PROFILE:
        return (draft.profile = action.profile);
      case RESET_PROFILE:
        return (draft.profile = null);
      case EDIT_PROFILE:
        return (draft.profile = {
          ...draft.profile,
          [`${action.e.target.name}`]: action.e.target.value,
        });

      default:
        return draft;
    }
  });
};

export default myAccountReducer;
