import React, { useEffect, Suspense } from "react";
import { Helmet } from "react-helmet";
import cx from "classnames";
import { createStructuredSelector } from "reselect";
import { Redirect, Route, Switch } from "react-router-dom";
import makeSelectGlobal, {
  makeSelectAccessToken,
  makeSelectUserName,
} from "./redux/redux/global/selectors";
import {
  makeSelectDeviceDimension,
  makeSelectSideBar,
} from "./redux/redux/device/selectors";
import { connect } from "react-redux";
import { setDeviceHeightWidth } from "./redux/redux/device/action";
import { Container, Segment, Sidebar } from "semantic-ui-react";
import ModalManager from "./components/Modals/ModalManager";
import Login from "./containers/Login/Login";
import EnquiryList from "./containers/Marketing/EnquiryList/EnquiryList";
import { checkToken } from "./utils/axios";
import AddEvents from "./containers/Marketing/Events/Events/addEvents";
import AddEnquiry from "./containers/Marketing/EnquiryList/Enquiry/addEnquiryList";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import "./index.scss";
import Events from "./containers/Marketing/Events/Events";
import StaffTraining from "./containers/Staff/StaffTraining/staffTraining";
import AddTraining from "./containers/Staff/StaffTraining/AddTraining";
import Exit from "./containers/Staff/Exit/Exit";
import ExitCandidates from "./containers/Staff/Exit/ExitCandidateList";
import ExitForm from "./containers/Staff/Exit/ExitForm";
import { store } from "./redux/store";
import AccessCheck, { CommonAccessCheck } from "./utils/AccessCheck";
import dotSkills from "./containers/Staff/DotSkills/dotSkills";
import ViewDotSkills from "./containers/Staff/DotSkills/viewDotSkills";
import SkillsLog from "./containers/Staff/DotSkills/skillsLog";
import Archive from "./containers/Archiving/Archive";
import AddCandidate from "./containers/Candidates/AddNewCandidate";
import RecurrenceJob from "./containers/RecurrenceJob/RecurrenceJob";
import { accessActionType, accessModule } from "./utils/accessConstant";
import {
  HR_CANDIDATES,
  HR_RECRUITMENT,
  HR_TIMEKEEPER_ATTENDANCE,
  REPORTS,
} from "./utils/labelContant";
import UseDotkris from "./containers/UseDotkris/useDotkris";
const Reports = React.lazy(() => import("./containers/Reports/Reports"));
const FestivalTemplate = React.lazy(() => import("./containers/FestivalTemplate/FestivalTemplate"));
const TimeKeeper = React.lazy(() =>
  import("./containers/AttendanceLeave/TimeKeeeper")
);
const PerformanceReport = React.lazy(() =>
  import("./containers/Staff/PerformanceReport/PerformanceReport")
);
const Home = React.lazy(() => import("./containers/Home/Home"));
const AllNotification = React.lazy(() =>
  import("./containers/Home/AllNotifications")
);
const Jobs = React.lazy(() => import("./containers/MainTable/Job"));
const CreateQuotation = React.lazy(() =>
  import("./components/Quotation/CreateQuotation")
);
const Error = React.lazy(() => import("./components/Error/Error"));
const Error403 = React.lazy(() => import("./components/Error/Error403"));
const ComingSoon = React.lazy(() =>
  import("./components/ComingSoon/ComingSoon")
);
const Masters = React.lazy(() => import("./containers/Masters/Masters"));
const MyAccount = React.lazy(() => import("./containers/MyAccount/MyAccount"));
const Staff = React.lazy(() => import("./containers/Staff/Staff"));
const Clients = React.lazy(() => import("./containers/Clients/Clients"));
const AddNewClients = React.lazy(() =>
  import("./containers/Clients/AddNewClient")
);
const JoiningKit = React.lazy(() =>
  import("./containers/Candidates/JoiningKit")
);

const Access = React.lazy(() => import("./containers/Access/Access"));
// const Invoicing = React.lazy(() => import("./components/Invoicing/Invoicing"));
// const AddInvoicing = React.lazy(() =>
//   import("./components/Invoicing/AddInvoicing")
// );

const AddStaff = React.lazy(() =>
  import("./containers/Staff/Add Staff/AddStaff")
);
const LeaveCancellations = React.lazy(() =>
  import("./containers/Leaves/LeaveCancellations")
);
const TimeKeeeper = React.lazy(() =>
  import("./containers/AttendanceLeave/TimeKeeeper")
);
const Recruitment = React.lazy(() =>
  import("./containers/Recruitment/Recruitment")
);
const AdminAttendance = React.lazy(() =>
  import("./containers/AttendanceLeave/AdminAttendance")
);
const AdminLeave = React.lazy(() =>
  import("./containers/AttendanceLeave/AdminLeave")
);
const RecruitmentEditor = React.lazy(() =>
  import("./containers/Recruitment/RecruitmentEditor")
);
const Candidates = React.lazy(() =>
  import("./containers/Candidates/Candidates")
);
const OnboardingCandidates = React.lazy(() =>
  import("./containers/Candidates/OnboardingCandidates")
);
const OfferCandidate = React.lazy(() =>
  import("./containers/Candidates/OfferCandidate")
);
const CandidateProfile = React.lazy(() =>
  import("./containers/Candidates/CandidateProfile")
);
const CandidateSubmission = React.lazy(() =>
  import("./containers/Candidates/CandidateSubmission")
);
const Templates = React.lazy(() => import("./containers/Templates/Templates"));
const TemplateEditor = React.lazy(() =>
  import("./containers/Templates/TemplateEditor")
);
const JobDetails = React.lazy(() =>
  import("./containers/JobDetails/JobDetails")
);
const ManagementSow = React.lazy(() =>
  import("./components/sowForms/managementSow")
);
const CreateJob = React.lazy(() => import("./components/CreateJob/jobform"));
const Invoice = React.lazy(() => import("./containers/Invoice/Invoice"));
const EditJob = React.lazy(() => import("./components/UpdateJob/editJob"));
const ViewQuotation = React.lazy(() =>
  import("./components/Quotation/ViewQuotation")
);
const Authorization = React.lazy(() =>
  import("./components/Authorization/Authorization")
);
const NavBar = React.lazy(() => import("./components/NavBar/NavBar"));
const InvoiceDetails = React.lazy(() =>
  import("./containers/Invoice/InvoiceDetails")
);
const SideBars = React.lazy(() => import("./components/SideBar/SideBar"));

const App = ({ dimension, openSideBar, dispatch, token, ...props }) => {
  useEffect(() => {
    const deviceHight = () => dispatch(setDeviceHeightWidth());
    window.addEventListener("resize", deviceHight);
    return () => {
      window.removeEventListener("resize", deviceHight);
    };
  });
  return (
    <div className="App">
      <Helmet titleTemplate="%s - Dot Kris" defaultTitle="Dot Kris">
        <meta name="description" content="Dot Kris Web Application" />
      </Helmet>
      <Switch>
        <Route path="/submission/:candidateId" exact>
          <Suspense fallback={null}>
            <CandidateSubmission />
          </Suspense>
        </Route>
        <Route path="/joining-kit/:id">
          <Suspense fallback={null}>
            <JoiningKit />
          </Suspense>
        </Route>
        <Route
          exact
          path="/"
          render={() =>
            checkToken(token) ? <Redirect to="/dashboard" /> : <Login />
          }
        ></Route>
        <Route
          path="/(.+)"
          render={() =>
            checkToken(token) ? (
              <>
                <Container fluid className="main-container">
                  <Sidebar.Pushable as={Segment}>
                    <Suspense fallback={null}>
                      <SideBars
                        isMobile={dimension.isMobile}
                        openSideBar={openSideBar}
                      />
                    </Suspense>
                    <Sidebar.Pusher
                      className={cx("pusher-overflow", {
                        "pusher-max-width": !dimension.isMobile,
                      })}
                      dimmed={dimension.isMobile && openSideBar}
                    >
                      <Suspense fallback={null}>
                        <NavBar
                          username={props.username}
                          isMobile={dimension.isMobile}
                          global={props.global}
                        />
                      </Suspense>
                      <Container fluid style={{}} className="app-height">
                        <Switch>
                          {/* <Route path={AccessCheck("Job","View") ? "/dashboard" : "/"}> */}
                          <Route path="/dashboard">
                            <Suspense fallback={null}>
                              <Authorization Component={Home} />
                            </Suspense>
                          </Route>
                          <Route path="/allnotification">
                            <Suspense fallback={null}>
                              <Authorization Component={AllNotification} />
                            </Suspense>
                          </Route>
                          <Route path="/jobs">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.JOBS,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? Jobs
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/quotation/:jobId">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.QUOTATION,
                                    accessActionType.CREATE,
                                    store.getState().global?.access
                                  )
                                    ? CreateQuotation
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/viewquotation/:jobId">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.QUOTATION,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? ViewQuotation
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/view/:jobId">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.JOBS,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? EditJob
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/editjob/:jobId">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.JOBS,
                                    accessActionType.UPDATE,
                                    store.getState().global?.access
                                  )
                                    ? EditJob
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route
                            exact
                            path="/job/:type(create||update||copy||view)/:id?"
                          >
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.JOBS,
                                    accessActionType.CREATE,
                                    store.getState().global?.access
                                  )
                                  ||
                                  AccessCheck(
                                    accessModule.JOBS,
                                    accessActionType.UPDATE,
                                    store.getState().global?.access
                                  )
                                    ? CreateJob
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/job/update/managementSow/:sowid">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.JOBS,
                                    accessActionType.UPDATE,
                                    store.getState().global?.access
                                  )
                                    ? ManagementSow
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/jobdetails/:jobId">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.JOBS,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? JobDetails
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route exact path="/clients">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.CLIENT,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? Clients
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/addclients">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.CLIENT,
                                    accessActionType.CREATE,
                                    store.getState().global?.access
                                  )
                                    ? AddNewClients
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/updateClient">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.CLIENT,
                                    accessActionType.UPDATE,
                                    store.getState().global?.access
                                  )
                                    ? AddNewClients
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/timekeeper">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.HR_TIMEKEEPER,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? TimeKeeper
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/leavecancellations">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    "HR",
                                    accessActionType.CREATE,
                                    store.getState().global?.access
                                  )
                                    ? LeaveCancellations
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/staff">
                            <Suspense fallback={null}>
                              <Authorization Component={Staff} />
                            </Suspense>
                          </Route>
                          <Route path="/enquiryList">
                            <Suspense fallback={null}>
                              <Authorization Component={EnquiryList} />
                            </Suspense>
                          </Route>
                          <Route path="/addEnquiry/:type(create||update)/:id?">
                            <Suspense fallback={null}>
                              <Authorization Component={AddEnquiry} />
                            </Suspense>
                          </Route>
                          <Route path="/events">
                            <Suspense fallback={null}>
                              <Authorization Component={Events} />
                            </Suspense>
                          </Route>
                          <Route path="/addEvents/:type(create||update)/:id?">
                            <Suspense fallback={null}>
                              <Authorization Component={AddEvents} />
                            </Suspense>
                          </Route>
                          <Route path="/addStaff">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    "Staff",
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? AddStaff
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/updateStaff">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    "Staff",
                                    accessActionType.UPDATE,
                                    store.getState().global?.access
                                  )
                                    ? AddStaff
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/my-account">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.MY_ACCOUNT,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? MyAccount
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/master">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.MASTER,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? Masters
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/invoicing">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.INVOICING,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? Invoice
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/invoices/:id">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.INVOICING,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? InvoiceDetails
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route
                            path="/settings"
                            render={() => (
                              <Suspense fallback={null}>
                                <ComingSoon />
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/report"
                            render={() => (
                              <Suspense fallback={null}>
                                <ComingSoon />
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/guide"
                            render={() => (
                              <Suspense fallback={null}>
                                <ComingSoon />
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/use-kris"
                            render={() => (
                              <Suspense fallback={null}>
                                <UseDotkris/>
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/billing"
                            render={() => (
                              <Suspense fallback={null}>
                                <ComingSoon />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/recruitment"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_RECRUITMENT,
                                      accessActionType.READ,
                                      store.getState().global?.access
                                    )
                                      ? Recruitment
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/adminleave"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_TIMEKEEPER_LEAVES,
                                      accessActionType.READ,
                                      store.getState().global?.access
                                    )
                                      ? AdminLeave
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/adminattendance"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_TIMEKEEPER_ATTENDANCE,
                                      accessActionType.READ,
                                      store.getState().global?.access
                                    )
                                      ? AdminAttendance
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/recruitment/:type(create)/:id?"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_RECRUITMENT,
                                      accessActionType.CREATE,
                                      store.getState().global?.access
                                    )
                                      ? RecruitmentEditor
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/recruitment/:type(update)/:id?"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_RECRUITMENT,
                                      accessActionType.UPDATE,
                                      store.getState().global?.access
                                    )
                                      ? RecruitmentEditor
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/add-candidate"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_CANDIDATES,
                                      accessActionType.CREATE,
                                      store.getState().global?.access
                                    )
                                      ? AddCandidate
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/candidates/:recruitmentId?"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_CANDIDATES,
                                      accessActionType.READ,
                                      store.getState().global?.access
                                    )
                                      ? Candidates
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/onboarding-candidates"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_ONBOARDING,
                                      accessActionType.READ,
                                      store.getState().global?.access
                                    )
                                      ? OnboardingCandidates
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/candidate/:candidateId/:recruitmentId"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_CANDIDATES,
                                      accessActionType.UPDATE,
                                      store.getState().global?.access
                                    )
                                      ? CandidateProfile
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/candidate/send-offer/:candidateId/:recruitmentId?"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_CANDIDATES,
                                      accessActionType.CREATE,
                                      store.getState().global?.access
                                    )
                                      ? OfferCandidate
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/templates"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_TEMPLATES,
                                      accessActionType.READ,
                                      store.getState().global?.access
                                    )
                                      ? Templates
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/templates/:type(create)/:id?"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_TEMPLATES,
                                      accessActionType.CREATE,
                                      store.getState().global?.access
                                    )
                                      ? TemplateEditor
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route
                            exact
                            path="/templates/:type(update)/:id?"
                            render={() => (
                              <Suspense fallback={null}>
                                <Authorization
                                  Component={
                                    AccessCheck(
                                      accessModule.HR_TEMPLATES,
                                      accessActionType.UPDATE,
                                      store.getState().global?.access
                                    )
                                      ? TemplateEditor
                                      : Error403
                                  }
                                />
                              </Suspense>
                            )}
                          />
                          <Route path="/access">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.ACCESS,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? Access
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/reports">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  Reports
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/festivalTemplate">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  FestivalTemplate
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/joining-kit-view/:id">
                            <Suspense fallback={null}>
                              <Authorization Component={JoiningKit} />
                            </Suspense>
                          </Route>
                          <Route path="/training-list">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  StaffTraining || Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/view-training">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  StaffTraining || Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/performance-report">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.STAFF_PERFORMANCE,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? PerformanceReport
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/addTraining/:type(create||update||view)/:id?">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.STAFF_TRAINING_CREATE_TRAINING,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? AddTraining
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/exit">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.STAFF_EXIT,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? Exit
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/dot-skills/:type(create||view)/">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={dotSkills || Error403}
                              />
                            </Suspense>
                          </Route>
                          <Route path="/dot-skills-view">
                            <Suspense fallback={null}>
                              <Authorization Component={ViewDotSkills} />
                            </Suspense>
                          </Route>
                          <Route path="/logs-dot-skill">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.STAFF_TRAINING_SKILL_LOGS,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? SkillsLog
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/exitList">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.STAFF_EXIT,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? ExitCandidates
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/exitForm/:type(create||update)/:id?">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.STAFF_EXIT,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? ExitForm
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/exit-form">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  Exit
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/archive">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.ARCHIVE_JOBS,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? Archive
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route path="/recurrence">
                            <Suspense fallback={null}>
                              <Authorization
                                Component={
                                  AccessCheck(
                                    accessModule.RECURRENCE_JOBS,
                                    accessActionType.READ,
                                    store.getState().global?.access
                                  )
                                    ? RecurrenceJob
                                    : Error403
                                }
                              />
                            </Suspense>
                          </Route>
                          <Route
                            path="**"
                            render={() => (
                              <Suspense fallback={null}>
                                <Error errorTitle="404 Page Not Found" />
                              </Suspense>
                            )}
                          />
                        </Switch>
                      </Container>
                    </Sidebar.Pusher>
                  </Sidebar.Pushable>
                </Container>
              </>
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route path="**" render={() => <Error errorTitle="404" />} />
      </Switch>
      <ModalManager />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  username: makeSelectUserName(),
  dimension: makeSelectDeviceDimension(),
  openSideBar: makeSelectSideBar(),
  token: makeSelectAccessToken(),
  global: makeSelectGlobal(),
});
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);