// import { SET_DEVICE_DETAILS, SET_ERROR, SET_ERROR_MESSAGE, SET_SET_VISIBLE, SET_USER_NAME } from "./constants";

import {
  RESET_ERROR,
  SET_ACTIVE_TAB,
  SET_ERROR,
  SET_LOADING,
  SET_MASTER,
  SET_MASTER_COUNT,
  SET_PAGE_NO,
  MASTER_ERROR,
  MASTER_ERROR_RESET,
  SET_MASTER_LOADING,
  RESET_ADD_COMPANY,
  ADD_COMPANY,
  UPDATE_DATA,
  ADD_DATA,
  ADD_PRIORITY,
  RESET_ADD_PRIORITY,
  ADD_VAT,
  RESET_ADD_VAT,
  ADD_MASTER_COUNT,
  RESET_MASTER_COUNT,
  ADD_TEAM,
  RESET_ADD_TEAM,
  MASTER_COUNT_LOADING,
  ADD_LEAVE,
  RESET_ADD_LEAVE,
  ADD_PLANING,
  RESET_ADD_PLANING,
  ADD_CURRENCY,
  RESET_ADD_CURRENCY,
  ADD_JOB_TYPE_LIST,
  RESET_ADD_JOB_TYPE_LIST,
  UPDATE_PLANING,
  UPDATE_CURRENCY,
  ADD_COUNTRY,
  UPDATE_COUNTRY,
  RESET_ADD_COUNTRY,
  ADD_DEPARTMENT,
  RESET_ADD_DEPARTMENT,
  ADD_GRADE,
  RESET_ADD_GRADE,
  ADD_JOBTYPE,
  RESET_ADD_JOBTYPE,
  ADD_TAT,
  RESET_ADD_TAT,
  ADD_ATTENDANCE_TYPE,
  UPDATE_ATTENDANCE_TYPE,
  RESET_ADD_ATTENDANCE_TYPE,
} from "./constants";

// /**
//  * Dispatched when loading the repositories fails
//  *
//  * @param  {string} error The error
//  *
//  * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
//  */
// export function setError(error) {
//     return {
//       type: SET_ERROR,
//       error,
//     };
//   }
//   /**
//    * Dispatched when loading the repositories fails
//    *
//    * @param  {string} error The error
//    *
//    * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
//    */
//   export function setErrorMessage(error) {
//     return {
//       type: SET_ERROR_MESSAGE,
//       error,
//     };
//   }
//   /**
//    * Dispatched when loading the repositories fails
//    *
//    * @param  {string} username The error
//    *
//    * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
//    */
//   export function setUserName(username) {
//     return {
//       type: SET_USER_NAME,
//       username,
//     };
//   }

//   // Device Width and Height
//   export const getWindowDimensions = () => {
//     const { innerWidth: width, innerHeight: height } = window;
//     const isMobile = width < 786;
//     return {
//       width,
//       height,
//       isMobile,
//     };
//   };
//   export const setDeviceHeightWidth = () => ({
//     type: SET_DEVICE_DETAILS,
//     payload: getWindowDimensions(),
//   });

//   // export const setDeviceDetails = deviceDetails => ({
//   //   type: 'SET_DEVICE_DETAILS',
//   //   payload: deviceDetails,
//   // });
//   // Close Device Width and Height
//   export const setSideBarVisible = () => ({
//     type: SET_SET_VISIBLE,
//   });

/**
 * Dispatched when loading the repositories fails
 *
 * @param  {Array} master The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export const setMaster = (master) => ({
  type: SET_MASTER,
  master,
});
/**
 * Dispatched when loading the repositories fails
 *
 * @param  {number} pageNumber The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export const setPageNo = (pageNumber,dataLength) => ({
  type: SET_PAGE_NO,
  pageNumber,
  dataLength,
});

/**
 * Dispatched when loading the repositories fails
 *
 * @param  {string} error The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export const setMasterError = (error) => ({
  type: SET_ERROR,
  error,
});

/**
 * Dispatched when loading the repositories fails
 *
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */

export const resetError = () => ({
  type: RESET_ERROR,
});

/**
 *
 *
 * @param  {boolean} loading
 * @return {object}
 */
export const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});
/**
 *
 *
 * @param  {number} count
 * @return {object}
 */
export const setTotalCount = (count) => ({
  type: SET_MASTER_COUNT,
  count,
});

/**
 *
 *
 * @param  {number} active_tab
 * @return {object}
 */
export const setActiveTab = (active_tab) => ({
  type: SET_ACTIVE_TAB,
  active_tab,
});

export const setMasterErrors = (error) => ({
  type: MASTER_ERROR,
  error,
});
export const resetMasterErrors = () => ({
  type: MASTER_ERROR_RESET,
});
export const setMasterLoading = (loading = false) => ({
  type: SET_MASTER_LOADING,
  loading,
});
export const setAddCompany = (name) => ({
  type: ADD_COMPANY,
  name,
});
export const resetAddCompany = () => ({
  type: RESET_ADD_COMPANY,
});
export const setAddLeave = (name) => ({
  type: ADD_LEAVE,
  name,
});
export const resetAddLeave = () => ({
  type: RESET_ADD_LEAVE,
});
export const setAddPriority = (name, value) => ({
  type: ADD_PRIORITY,
  name,
  value,
});
export const resetAddPriority = () => ({
  type: RESET_ADD_PRIORITY,
});
export const setAddVat = (name, value) => ({
  type: ADD_VAT,
  name,
  value,
});
export const resetAddVat = () => ({
  type: RESET_ADD_VAT,
});
export const setAddDepartment = (name, value) => ({
  type: ADD_DEPARTMENT,
  name,
  value,
});
export const resetAddDepartment = () => ({
  type: RESET_ADD_DEPARTMENT,
});
export const setAddGrade = (name, value) => ({
  type: ADD_GRADE,
  name,
  value,
});
export const resetAddGrade = () => ({
  type: RESET_ADD_GRADE,
});
export const setAddJobtype = (name, value) => ({
  type: ADD_JOBTYPE,
  name,
  value,
});
export const resetAddJobtype = () => ({
  type: RESET_ADD_JOBTYPE,
});
export const setAddTat = (name, value) => ({
  type: ADD_TAT,
  name,
  value,
});
export const resetAddTat = () => ({
  type: RESET_ADD_TAT,
});
export const setAddTeam = (name) => ({
  type: ADD_TEAM,
  name,
});
export const resetAddTeam = () => ({
  type: RESET_ADD_TEAM,
});
export const addData = (data) => ({
  type: ADD_DATA,
  data,
});
export const updateData = (data) => {
  return {
    type: UPDATE_DATA,
    data,
  };
};
export const addMasterCount = (counts) => {
  return {
    type: ADD_MASTER_COUNT,
    counts,
  };
};
export const resetMasterCount = () => {
  return {
    type: RESET_MASTER_COUNT,
  };
};
export const masterCountLoading = (loading) => {
  return {
    type: MASTER_COUNT_LOADING,
    loading,
  };
};


export const setAddPlaning = (e) => ({
  type: ADD_PLANING,
  e,
});
export const updatePlaning = (e) => ({
  type: UPDATE_PLANING,
  e,
});
export const resetAddPlaning = () => ({
  type: RESET_ADD_PLANING,
});
export const setAddCurrency = (e) => ({
  type: ADD_CURRENCY,
  e,
});
export const updateCurrency = (e) => ({
  type: UPDATE_CURRENCY,
  e,
});
export const resetAddCurrency = () => ({
  type: RESET_ADD_CURRENCY,
});
export const setAddCountry = (e) => ({
  type: ADD_COUNTRY,
  e,
});
export const updateCountry = (e) => ({
  type: UPDATE_COUNTRY,
  e,
});
export const resetAddCountry = () => ({
  type: RESET_ADD_COUNTRY,
});
export const setAddAttendancetype = (e) => ({
  type: ADD_ATTENDANCE_TYPE,
  e,
});
export const updateAttendancetype = (e) => ({
  type: UPDATE_ATTENDANCE_TYPE,
  e,
});
export const resetAddAttendancetype = () => ({
  type: RESET_ADD_ATTENDANCE_TYPE,
});
export const setJobTypeList = (list = []) => ({
  type: ADD_JOB_TYPE_LIST,
  list,
});
export const resetJobTypeList = () => ({
  type: RESET_ADD_JOB_TYPE_LIST,
});