import produce from "immer";
import { SET_SELECTED_ID, RESET_SELECTED_ID, SET_ROLES } from "./constants";
export const INITIAL_STATE = {
  // openDocumentSideBar :false,
  selectedId: "",
  roles: [],
};
const accessReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_SELECTED_ID:
        draft.selectedId = { ...action.selectedId };
        break;
      case RESET_SELECTED_ID:
        draft.selectedId = "";
        break;
      case SET_ROLES:
        draft.roles = [ ...action.roles ];
        break;
      default:
        return draft;
    }
  });
};

export default accessReducer;
