export const JOB_FETCH = "@job/JOB_FETCH";
export const JOB_SUCCESS = "@job/JOB_SUCCESS";
export const JOB_ERROR = "@job/JOB_ERROR";




export const JOB_GET = "@job/JOB_GET";
export const JOB_EXPORT = "@job/JOB_EXPORT";
export const JOB_GET_ERROR = "@job/JOB_GET_ERROR";
export const JOB_GET_LIST_LOADING = "@job/JOB_GET_LIST_LOADING";


export const JOB_GET_PAGE_NO= "@job/JOB_GET_PAGE_NO";
export const JOB_COUNT= "@job/JOB_COUNT";
export const JOB_SEARCH= "@job/JOB_SEARCH";
export const JOB_SEARCH_RESET= "@job/JOB_SEARCH_RESET";
export const ADD_MANAGER= "@job/ADD_MANAGER";
export const ADD_CLIENT= "@job/ADD_CLIENT";
export const ADD_STATUS= "@job/ADD_STATUS";
export const ADD_JOB_TYPE= "@job/ADD_JOB_TYPE";