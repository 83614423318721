import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import ContainerTitle from "../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../components/ContainerTitle/TitleWrapper";
import { Button, Dropdown, Menu } from "semantic-ui-react";
import EventHoc from "./EventsHoc";

import CustomTable from "../../../components/CustomTable/CustomTable";
import { loopObject } from "../../../utils/objectLoop";
import { render } from "@testing-library/react";
import AccessCheck from "../../../utils/AccessCheck";

// const clientCols = [
//   { id: "id", title: "Client ID", classStyle: {}, fixed: true },
//   { id: "name", title: "Client Name", classStyle: {} },
//   { id: "project_name", title: "Project", classStyle: {} },
//   { id: "company_name", title: "Company Name", classStyle: {} },
//   { id: "phone", title: "Mobile Number", classStyle: {} },
//   { id: "business_phone", title: "Business Phone Number", classStyle: {} },
//   { id: "email", title: "Email Address", classStyle: {} },
//   { id: "createdAt", title: "Created At", classStyle: {} },
//   { id: "manager", title: "Manager Name", classStyle: {} },
// ];

function Event({
  event,
  setEventPageNumber,
  getData,
  editRoute,
  deleteEvent,
  onSelect,
  location,

  ...props
}) {
    // event component NOT in use
  const getEventList = useCallback(
    (dataObj) => {
      getData({ ...dataObj });
    },
    [getData]
  );
  useEffect(() => {
    getEventList(loopObject(event.eventSearch));
    return () => {};
  }, [event.pageNumber, event.eventSearch, getEventList]);

  const renderEventTitleWraper = () => {
    if (props.history.location.pathname.includes("/dashboard")) {
      return <></>;
    } else {
      return (
        <TitleWrapper>
          <ContainerTitle title={"Events"} />
          {props.global.userDetails &&
            props.global.userDetails.roleName &&
            (props.global.userDetails.roleName === "Admin" ||
              props.global.userDetails.roleName === "Client") && AccessCheck("Marketing","Create")&&(
              <Button
                className="dotButton"
                floated="right"
                content="Create New Event"
                color="green"
                onClick={() => props.history.push("/addEvents/create")}
              />
            )}
        </TitleWrapper>
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>Events</title>
        <meta name="description" content="Description of events" />
      </Helmet>
        <div
          className={
            props.history.location.pathname.includes("dashboard")
              ? ""
              : "container-height-width"
          }
        >
          {renderEventTitleWraper()}
          <div className="customTableWraper">
            <CustomTable
              cols={[
                {
                  id: "id",
                  title: "      ID",
                  classStyle: {},
                  fixed: true,
                  search: true,
                  onSelect: onSelect,
                  text: event.eventSearch.eventId || "",
                  type: "eventId",
                },
                {
                  id: "event_name",
                  title: "Event Name",
                  classStyle: {},
                  search: true,
                  onSelect: onSelect,
                  options: [],
                  text: event.eventSearch.name || "",
                  type: "name",
                },
                {
                  id: "address",
                  title: "Event Address",
                  classStyle: {},
                  search: true,
                  onSelect: onSelect,
                  // options: [...props.addjob.companies],
                  text: event.eventSearch.address || "",
                  type: "address",
                },
                {
                  id: "contact_person",
                  title: "Contact Person",
                  classStyle: {},

                  search: true,
                  onSelect: onSelect,
                  // options: [...event.jobType],
                  text: event.eventSearch.contact || "",
                  type: "contact",
                },
                {
                  id: "event_date",
                  title: "Event Date",
                  classStyle: {},
                  search: false,
                  onSelect: onSelect,
                  // options: [...event.manager],
                  text: event.eventSearch.jobManager || "",
                  type: "jobManager",
                },
                {
                  id: "createdAt",
                  title: "Created On",
                  classStyle: {},
                  search: false,
                  onSelect: onSelect,
                  // options: [],
                  // text: job.jobSearch.email || "",
                  type: "email",
                },
              ]}
              data={event.eventData}
              count={event.count}
              setPagination={(pageNumber) => setEventPageNumber(pageNumber)}
              activePage={event.pageNumber}
              error={event.eventError}
              loading={event.eventListLoading}
              renderMenuItem={(row) => {
                return (
                  <Menu fluid vertical tabular className="user-menu">
                   
                    {AccessCheck("Marketing", "Update") && (
                      <Menu.Item name="edit" onClick={() => editRoute(row.id)} />
                    )}
                    {AccessCheck("Marketing", "Delete") && (
                      <Menu.Item
                        name="delete"
                        onClick={() => {
                          deleteEvent(row.id);
                        }}
                      />
                    )}
                    {/* <Menu.Item name="details" onClick={() => detailsRoute(row.id)} />
                <Menu.Item name="copy" onClick={() => copyRoute(row.id)} /> */}
                  </Menu>
                );
              }}
            />
          </div>
        </div>
    </>
  );
}

// const mapStateToProps = createStructuredSelector({
//   client: makeSelectClient(),
// });

// function mapDispatchToProps(dispatch) {
//   return {
//     dispatch,
//   };
// }

// const withConnect = connect(mapStateToProps, mapDispatchToProps);

// export default compose(withConnect, memo)(Clients);

export default EventHoc(Event);
