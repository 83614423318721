import produce from "immer";
import {
  RESET_ERROR,
  SET_ACTIVE_TAB,
  SET_ERROR,
  SET_LOADING,
  SET_MASTER,
  SET_MASTER_COUNT,
  SET_PAGE_NO,
  MASTER_ERROR,
  MASTER_ERROR_RESET,
  SET_MASTER_LOADING,
  ADD_COMPANY,
  RESET_ADD_COMPANY,
  ADD_DATA,
  UPDATE_DATA,
  RESET_ADD_PRIORITY,
  ADD_PRIORITY,
  RESET_ADD_VAT,
  ADD_VAT,
  ADD_TEAM,
  RESET_ADD_TEAM,
  ADD_MASTER_COUNT,
  RESET_MASTER_COUNT,
  MASTER_COUNT_LOADING,
  ADD_LEAVE,
  RESET_ADD_LEAVE,
  ADD_PLANING,
  RESET_ADD_PLANING,
  RESET_ADD_JOB_TYPE_LIST,
  ADD_JOB_TYPE_LIST,
  UPDATE_PLANING,
  ADD_CURRENCY,
  UPDATE_CURRENCY,
  RESET_ADD_CURRENCY,
  ADD_COUNTRY,
  UPDATE_COUNTRY,
  RESET_ADD_COUNTRY,
  ADD_DEPARTMENT,
  RESET_ADD_DEPARTMENT,
  ADD_GRADE,
  RESET_ADD_GRADE,
  ADD_JOBTYPE,
  RESET_ADD_JOBTYPE,
  ADD_TAT,
  RESET_ADD_TAT,
  ADD_ATTENDANCE_TYPE,
  UPDATE_ATTENDANCE_TYPE,
  RESET_ADD_ATTENDANCE_TYPE,
} from "./constants";
import { round } from "lodash";
export const INITIAL_STATE = {
  master: [],
  error: "",
  pageNumber: 1,
  loading: false,
  count: 0,
  activeTab: 1,
  master_error: "",
  master_loading: false,
  addCompany: "",

  addTeam: "",
  addPriority: {
    priority: "",
  },
  addVat: {
    vat: "",
  },
  addDepartment: {
    department: "",
  },
  addGrade: {
    grate: "",
  },
  addJobtype: {
    jobtype: "",
  },
  addTat: {
    tat: "",
  },
  addLeave: "",
  masterCount: {
    roleCount: 0,
    companyCount: 0,
    priorityCount: 0,
    vatCount: 0,
    departmentCount: 0,
    gradeCount: 0,
    jobtypeCount: 0,
    tatCount: 0,
    leaveCount: 0,
    leaveTypeCount: 0,
    currencyCount: 0,
    countryCount: 0,
    attendancetypeCount: 0,
    payComponentCount: 0,
    statusCount: 0,
    teamCount: 0,
  },
  masterCountLoading: false,
  jobType: [],
  addPlaning: {
    planing: "",
    type: "",
  },
  addCurrency: {
    Currency_code: "",
    Currency_name: "",
    bank_name: "",
    bank_account_name: "",
    sortcode: "",
    account_number: "",
    iban: ""
  },
  addCountry: {
    Country_code: "",
    Country_name: "",
  },
  addAttendancetype: {
    Attendancetype: "",
    Attendancecode: "",
  },
};
const masterReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_LOADING:
        draft.loading = action.loading;
        break;
      case SET_ERROR:
        draft.error = action.error;
        break;
      case RESET_ERROR:
        draft.error = "";
        break;
      case SET_MASTER:
        draft.master = action.master;
        break;
      case SET_MASTER_COUNT:
        draft.count = action.count;
        break;
      case SET_ACTIVE_TAB:
        draft.activeTab = action.active_tab;
        break;
      case SET_PAGE_NO:
        let actualPageCount = round(action?.dataLength % 10);
        draft.pageNumber = actualPageCount && actualPageCount > 0 ? action?.pageNumber : action?.pageNumber > 1 ? action?.pageNumber - 1 : action?.pageNumber;
        break;
      case MASTER_ERROR:
        draft.master_error = action.error;
        break;
      case MASTER_ERROR_RESET:
        draft.master_error = "";
        break;
      case SET_MASTER_LOADING:
        draft.master_loading = action.loading;
        break;
      case ADD_COMPANY:
        draft.addCompany = action.name;
        break;
      case RESET_ADD_COMPANY:
        draft.addCompany = "";
        break;
      case ADD_LEAVE:
        draft.addLeave = action.name;
        break;
      case RESET_ADD_LEAVE:
        draft.addLeave = "";
        break;
      case ADD_PRIORITY:
        draft.addPriority = {
          ...draft.addPriority,
          [action.name]: action.value,
        };
        break;
      case RESET_ADD_PRIORITY:
        draft.addPriority = {
          priority: "",
        };
        break;
      case ADD_VAT:
        draft.addVat = {
          ...draft.addVat,
          [action.name]: action.value,
        };
        break;
      case RESET_ADD_VAT:
        draft.addVat = {
          vat: "",
        };
        break;
      case ADD_DEPARTMENT:
        draft.addDepartment = {
          ...draft.addDepartment,
          [action.name]: action.value,
        };
        break;
      case RESET_ADD_DEPARTMENT:
        draft.addDepartment = {
          department: "",
        };
        break;
      case ADD_GRADE:
        draft.addGrade = {
          ...draft.addGrade,
          [action.name]: action.value,
        };
        break;
      case RESET_ADD_GRADE:
        draft.addGrade = {
          grade: "",
        };
        break;
      case ADD_JOBTYPE:
        draft.addJobtype = {
          ...draft.addJobtype,
          [action.name]: action.value,
        };
        break;
      case RESET_ADD_JOBTYPE:
        draft.addJobtype = {
          jobtype: "",
        };
        break;
      case ADD_TAT:
        draft.addTat = {
          ...draft.addTat,
          [action.name]: action.value,
        };
        break;
      case RESET_ADD_TAT:
        draft.addTat = {
          tat: "",
        };
        break;
      case ADD_TEAM:
        draft.addTeam = action.name;
        break;
      case RESET_ADD_TEAM:
        draft.addTeam = "";
        break;
      case ADD_MASTER_COUNT:
        draft.masterCount = {
          ...draft.masterCount,
          ...action.counts,
        };
        break;
      case RESET_MASTER_COUNT:
        draft.masterCount = {
          roleCount: 0,
          companyCount: 0,
          priorityCount: 0,
          vatCount: 0,
          departmentCount: 0,
          gradeCount: 0,
          jobtypeCount: 0,
          tatCount: 0,
          leaveCount: 0,
          leaveTypeCount: 0,
          currencyCount: 0,
          countryCount: 0,
          attendancetypeCount: 0,
          statusCount: 0,
          teamCount: 0,
        };
        break;
      case MASTER_COUNT_LOADING:
        draft.masterCountLoading = action.loading;
        break;
      case ADD_PLANING:
        draft.addPlaning = { ...draft.addPlaning, [action.e.target.name]: action.e.target.value }
        break;
      case UPDATE_PLANING:
        draft.addPlaning = { ...draft.addPlaning, ...action.e }
        break;
      case RESET_ADD_PLANING:
        draft.addPlaning = {
          planing: "",
          type: "",
        };
        break;
      case ADD_CURRENCY:
        draft.addCurrency = { ...draft.addCurrency, [action.e.target.name]: action.e.target.value }
        break;
      case UPDATE_CURRENCY:

        draft.addCurrency = { ...draft.addCurrency, ...action.e }
        break;
      case RESET_ADD_CURRENCY:
        draft.addCurrency = {
          Currency_code: "",
          Currency_name: "",
          bank_name: "",
          bank_account_name: "",
          sortcode: "",
          account_number: "",
          iban: ""
        };
        break;
      case ADD_COUNTRY:
        draft.addCountry = { ...draft.addCountry, [action.e.target.name]: action.e.target.value }
        break;
      case UPDATE_COUNTRY:

        draft.addCountry = { ...draft.addCountry, ...action.e }
        break;
      case RESET_ADD_COUNTRY:
        draft.addCountry = {
          Country_code: "",
          Country_name: "",
        };
        break;
      case ADD_ATTENDANCE_TYPE:
        draft.addAttendancetype = { ...draft.addAttendancetype, [action.e.target.name]: action.e.target.value }
        break;
      case UPDATE_ATTENDANCE_TYPE:

        draft.addAttendancetype = { ...draft.addAttendancetype, ...action.e }
        break;
      case RESET_ADD_ATTENDANCE_TYPE:
        draft.addAttendancetype = {
          Attendancetype: "",
          Attendancecode: "",
        };
        break;
      case ADD_JOB_TYPE_LIST:
        draft.jobType = action.list;
        break;
      case RESET_ADD_JOB_TYPE_LIST:
        draft.jobType = [];
        break;
      default:
        return draft;
    }
  });
};

export default masterReducer;
