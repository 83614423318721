
export const ADD_COMPANIES = "@addClient/ADD_COMPANIES";
export const RESET_COMPANIES = "@addClient/RESET_COMPANIES";
export const ADD_CURRENCY = "@addClient/ADD_CURRENCY";
export const RESET_CURRENCY = "@addClient/RESET_CURRENCY";
export const ADD_VAT = "@addClient/ADD_VAT";
export const RESET_VAT= "@addClient/RESET_VAT";
export const ADD_ROLES = "@addClient/ADD_ROLES";
export const RESET_ROLES = "@addClient/RESET_ROLES";
export const ADD_ASSIGNS = "@addClient/ADD_ASSIGNS";
export const RESET_ASSIGNS = "@addClient/RESET_ASSIGNS";
export const ADD_TYPE = "@addClient/ADD_TYPE";
export const RESET_TYPE = "@addClient/RESET_TYPE";
export const ADD_FULL_PROFILE = "@addClient/ADD_FULL_PROFILE";
export const RESET_FULL_PROFILE = "@addClient/RESET_FULL_PROFILE";
export const ADD_FULL_CONTACT = "@addClient/ADD_FULL_CONTACT";
export const RESET_FULL_CONTACT = "@addClient/RESET_FULL_CONTACT";