import React from "react";
import StyleButton from "./EditorStyleButton";
// import rcEt from "../../styles/components/_editor.scss";
import "../../styles/components/_editor.scss";

const InlineStyleControls = ({ onToggle, editorState }) => {
  let INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
    { label: "font", style: "CODE" },
  ];
  let currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className={"RichEditor-controls"}>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default InlineStyleControls;
