import produce from "immer";
import {
  CLIENT_COUNT,
  CLIENT_ERROR,
  CLIENT_GET,
  CLIENT_GET_LIST_LOADING,
  CLIENT_GET_PAGE_NO,
  CLIENT_SEARCH,
  CLIENT_SEARCH_RESET,
} from "./constants";
export const INITIAL_STATE = {
  clientData: [],
  clientError: "",
  pageNumber: 1,
  clientListLoading: false,
  count: 0,
  clientSearch: {},
};
const clientsReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case CLIENT_GET:
        draft.clientData = action.data;
        break;
      case CLIENT_ERROR:
        draft.error = action.error;
        break;
      case CLIENT_GET_LIST_LOADING:
        draft.clientListLoading = action.loading;
        break;
      case CLIENT_GET_PAGE_NO:
        draft.pageNumber = action.pageNumber;
        break;
      case CLIENT_COUNT:
        draft.count = action.count;
        break;
      case CLIENT_SEARCH:
        const data = { ...draft.clientSearch, [action.name]: action.value };
        draft.clientSearch = data;
        break;
      case CLIENT_SEARCH_RESET:
        draft.clientSearch = {};
        break;
      default:
        return draft;
    }
  });
};

export default clientsReducer;
