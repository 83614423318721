import { createSelector } from 'reselect';
import { INITIAL_STATE } from './reducers';


const selectAddEnquiryDomain = state => state.addEnquiry || INITIAL_STATE;


const makeSelectAddEnquiry = () =>
  createSelector(
    selectAddEnquiryDomain,
    addEnquiry => addEnquiry,
  );

export default makeSelectAddEnquiry;
export { selectAddEnquiryDomain };
