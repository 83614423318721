import * as moment from "moment";
import { ANNUALLY, BI_MONTHLY, FORTNIGHTLY, FOUR_WEEKLY, MONTHLY, QUARTERLY, WEEKLY, ZERO } from "./labelContant";
// functions to format date accordingly
export const getLocalTime = (date) => {
  const localDate = moment(date).format("L, h:mm A");
  if (localDate === "Invalid date" || !date) return "N/A";
  return localDate;
};

export const getLocalTimeCus = (date) => {
  const localDate = moment(date).format("DD/MM/YYYY, hh:mm:ss");
  if (localDate === "Invalid date" || !date) return "N/A";
  return localDate;
};
export const getLocalHoursSwipeIn = (date) => {
  const localHours = moment(date).format("h:mm A");
  if (localHours === "Invalid date" || !date) return "N/A";
  return localHours;
};
export const getDifference = (endDate, startDate) => {
  let end = moment(new Date(startDate).valueOf());
  let updatedAt = moment(new Date(endDate).valueOf());
  let diff = end.diff(updatedAt, "hour", false);
  if (diff > 8760) {
    diff = end.diff(updatedAt, "years", false);
    return diff > 1 ? diff + " years ago" : diff + " year ago";
  } else if (diff > 730) {
    diff = end.diff(updatedAt, "months", false);
    return diff > 1 ? diff + " months ago" : diff + " month ago";
  } else if (diff > 24) {
    diff = end.diff(updatedAt, "days", false);
    return diff > 1 ? diff + " days ago" : diff + " day ago";
  } else if (diff <= 0) {
    diff = end.diff(updatedAt, "minutes", false);
    return diff > 1 ? diff + " minutes ago" : diff + " minute ago";
  } else return diff > 1 ? diff + " hours ago" : diff + " hour ago";
};

export const getDateMonth = (date) => {
  const localDate = moment(date).format("DD MMM");
  if (localDate === "Invalid date" || !date) return "N/A";
  return localDate;
};

export const getDifferenceDayLeaves = (endDate, startDate) => {
  const start = moment(startDate).format();
  const endD = moment(endDate).format();
  let end = moment(endD);
  let updatedAt = moment(start);
  let diff = end.diff(updatedAt, "hour", false);
  let days = (diff / 24).toFixed(1); //1.7
  let splitDays = days.split("."); //[1,7]
  if (parseInt(splitDays[1]) > 6) {
    let day = parseInt(splitDays[0]) + 1; //1+1
    if (isNaN(day)) return `0 days`;
    return `${day} days`;
  } else {
    let day = parseInt(splitDays[0]) + 0.5; //1+0.5
    if (isNaN(day)) return `0 days`;
    return `${day} days`;
  }
};

export const getLocalDate = (date) => {
  const localDate = moment(date).format("DD MMM YYYY");
  if (localDate === "Invalid date" || !date) return "N/A";
  return localDate;
};

export const getCusLocalDate = (date) => {
  const localDate = moment(date).format("DD-MM-YYYY");
  if (localDate === "Invalid date" || !date) return "N/A";
  return localDate;
};
export const getCusLocalDateMM = (date) => {
  let fechaLatinFormat = date; //octuber 5, 2016 - octubre 5 del 2016
  let datePieces = fechaLatinFormat.split("-");
  let preFinalDate = [datePieces[1], datePieces[0], datePieces[2]];
  let final = preFinalDate.join("-");
  return final;
};
export const getCusLocalDateYear = (date) => {
  const localDate = moment(date).format("DD-MM-YYYY");
  if (localDate === "Invalid date" || !date) return "N/A";
  return localDate;
};

export const getClosingDate = (date, period) => {
  const localDate = moment(date, "DD-MM-YYYY")
    .add(period, "days")
    .format("DD-MM-YYYY");
  if (localDate === "Invalid date" || !date) return "N/A";
  return localDate;
};

export const getDaysBetweenDateAndPeriod = (date, period) => {
  const current_date = moment();
  const end_date = moment(date, "DD-MM-YYYY").add(period, "days");
  const daysLeft = end_date.diff(current_date, "days") + 1;
  return daysLeft;
};

export const getCusMeetingDate = (date) => {
  var value = date;
  var newdate = value.split("-").reverse().join("-");
  return newdate;
};

export const getCusAttendanceDate = (date) => {
  const localDate = moment(date).format("YYYY-MM-DD");
  if (localDate === "Invalid date" || !date) return "N/A";
  return localDate;
};

export const getCusTimesheetDate = (date) => {
  var date2 = date;
  date2 = date.split("-").reverse().join("-");
  return date2;
};

export const getLocalHours = (date) => {
  const localDate = moment(date).format("LT");
  if (localDate === "Invalid date" || !date) return "N/A";
  return localDate;
};

export const utcTime = (utc) => {
  let utcTime = moment.utc(utc).local().format("LT");
  if (utcTime === "Invalid date" || !utc) return null;
  return utcTime;
};

export const utcTimeR = (utc) => {
  let utcTime = moment.utc(utc).local().format("LT");
  if (utcTime === "Invalid date" || !utc) return "N/A";
  return utcTime;
};

export const convertUTCTimeToLocalTime = (utcTime) => {
  let time = moment(utcTime, "HH:mm:ss").utc(true).local().format("LT");
  if (time === "Invalid date" || !utcTime) return "N/A";
  return time;
};

export const diffYearMonths = (startDate, endDate) => {
  if(startDate  && endDate){
    let months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    let dur1 = Math.floor((months <= 0 ? 0 : months) / 12)
    let dur2 = ((months <= 0 ? 0 : months) / 12) - dur1
    let dur3 = Math.floor(dur2 * 12)
    return dur1 + "." + dur3 + " years";
  }else{
    return null;
  }
}

export function formatDate(date) {
  return moment(date).format('DD-MM-YYYY')
}
export function reverse_date(date) {
  return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
}

export function getFormatTimeKeeper(date) {
  return moment(date,"YYYY-MM-DD hh:mm:ss").format('DD-MM-YYYY')
}

export function formatTimeKeeper(date) {
  return moment(date,"DD-MM-YYYY").format('DD-MM-YYYY')
}

export function MonthYearFormat() {
  return moment().format('D MMM YYYY')
}
export function dateFormat() {
  return moment().format('DD-MM-YYYY')
}
export function daysInMonth() {
  return moment().daysInMonth()
}
export function current_month(month, month_day,value) {
  return moment().add(month, month_day).format(`${value}-MM-YYYY`)
}


export const dateOfMonth = (type,month) => {
  let dates = [];
  let CurrentMonthDay;
  if (type === MONTHLY) {
    CurrentMonthDay = moment().add(1, 'M').daysInMonth(); 
  }
  else if (type === BI_MONTHLY) {
    CurrentMonthDay = moment().add(2, 'M').daysInMonth();
  }
  else if (type === QUARTERLY) {
    CurrentMonthDay =moment().add(3, 'M').daysInMonth();
  }
  else if (type === FORTNIGHTLY) {
    CurrentMonthDay =moment().add(15, 'd').daysInMonth()
  }
  else if (type === WEEKLY) {
    CurrentMonthDay =moment().add(7, 'd').daysInMonth()
  }
  else if (type === ANNUALLY) {
    CurrentMonthDay =moment().add(1, 'y').daysInMonth()
  }
  else if (type === FOUR_WEEKLY) {
    CurrentMonthDay =moment().add(28, 'd').daysInMonth()
  }
  for (var i = 1; i <= CurrentMonthDay; i++) { 
    if (i < 10) {
      dates.push({ text: ZERO+i, value: ZERO+ i });
    }
    else {
      dates.push({ text: i.toString(), value: i.toString() });
    }
  }
  return dates;
}
export function dateOfMonths () {
  let dates=[]
  for (var i = 1; i <= 31; i++) { 
    if (i < 10) {
      dates.push({ text: ZERO+i, value: ZERO+ i });
    }
    else {
      dates.push({ text: i.toString(), value: i.toString() });
    }
  }
  return dates;
}
export function getValueByKey(object, row) {
  return object[row];
}

export const dateFn = (start_date, end_date) => {
  let startDate = reverse_date(end_date);
  let endDate = reverse_date(start_date);
  let day1 = moment(startDate);
  let day2 = moment(endDate);
  let result = [moment({...day2})];
    while (day1.date() != day2.date()) {
              day2.add(1, 'day');
      result.push(moment({...day2 }));
  }
  result = result.map(x => x.format("YYYY-MM-DD"));
  const objValue =result.map((value) => ({
    [value]: 1
  }));
  return objValue
}

export const LeavesValues = (
  start_mode,
  end_mode,
  DateValue,
  startDate,
  endDate
) => {
  if (start_mode == 0.5 && end_mode == 0.5) {
    if (
      Object.keys(DateValue[0])[0] == startDate &&
      Object.keys(DateValue[DateValue.length - 1])[0] == endDate
    ) {
      DateValue[0][startDate] = 0.5;
      DateValue[DateValue.length - 1][endDate] = 0.5;
    }
  } else if (start_mode == 0.5) {
    if (Object.keys(DateValue[0]) == startDate) {
      DateValue[0][startDate] = 0.5;
    }
  } else if (end_mode == 0.5) {
    if (Object.keys(DateValue[DateValue.length - 1])[0] == endDate) {
      DateValue[DateValue.length - 1][endDate] = 0.5;
    }
  }
  return DateValue;
};
