// import { SET_DEVICE_DETAILS, SET_ERROR, SET_ERROR_MESSAGE, SET_SET_VISIBLE, SET_USER_NAME } from "./constants";

import { EVENT_COUNT, EVENT_GET, EVENT_GET_ERROR, EVENT_GET_LIST_LOADING, EVENT_GET_PAGE_NO, EVENT_SEARCH, EVENT_SEARCH_RESET } from "./constants";

// /**
//  * Dispatched when loading the repositories fails
//  *
//  * @param  {string} error The error
//  *
//  * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
//  */
// export function setError(error) {
//     return {
//       type: SET_ERROR,
//       error,
//     };
//   }
//   /**
//    * Dispatched when loading the repositories fails
//    *
//    * @param  {string} error The error
//    *
//    * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
//    */
//   export function setErrorMessage(error) {
//     return {
//       type: SET_ERROR_MESSAGE,
//       error,
//     };
//   }
//   /**
//    * Dispatched when loading the repositories fails
//    *
//    * @param  {string} username The error
//    *
//    * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
//    */
//   export function setUserName(username) {
//     return {
//       type: SET_USER_NAME,
//       username,
//     };
//   }

//   // Device Width and Height
//   export const getWindowDimensions = () => {
//     const { innerWidth: width, innerHeight: height } = window;
//     const isMobile = width < 786;
//     return {
//       width,
//       height,
//       isMobile,
//     };
//   };
//   export const setDeviceHeightWidth = () => ({
//     type: SET_DEVICE_DETAILS,
//     payload: getWindowDimensions(),
//   });

//   // export const setDeviceDetails = deviceDetails => ({
//   //   type: 'SET_DEVICE_DETAILS',
//   //   payload: deviceDetails,
//   // });
//   // Close Device Width and Height
//   export const setSideBarVisible = () => ({
//     type: SET_SET_VISIBLE,
//   });

export const fetchEventData = (query) => {
  return {
    type: "EVENT_FETCH",
    query,
  };
};
export const addEventData = (data) => {
  return {
    type: EVENT_GET,
    data,
  };
};
export const errorEventFetch = (error) => {
  return {
    type: EVENT_GET_ERROR,
    error,
  };
};

export const setEventPageNumber = (pageNumber) => {
  return {
    type: EVENT_GET_PAGE_NO,
    pageNumber,
  };
};

export const setLoading = (loading) => {
  return {
    type: EVENT_GET_LIST_LOADING,
    loading,
  };
};


export const setEventCount = (count) => {
  return {
    type: EVENT_COUNT,
     count,
  };
};


export const searchEvent = (name, value) => {
  return {
    type: EVENT_SEARCH,
    name,value
  };
};
export const searchEventReset = () => {
  return {
    type: EVENT_SEARCH_RESET,
   
  };
};


