import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { TimelineLite, Power2, gsap } from "gsap";
import { passwordValidator } from "../../utils/validator";
import axios from "../../utils/axios";
gsap.registerPlugin([TimelineLite]);
export default function ResetPassword(props) {
  const [passwordError, setPasswordError] = useState('');
  const [password, setPassword] = useState('');
  useEffect(() => {
    let recovery = new TimelineLite({ paused: true, smoothChildTiming: true, reversed: true, yoyo: true });
    recovery
      .fromTo(
        "#reset_password",
        { opacity: 0, ease: Power2.easeInOut, y: -50 },
        { opacity: 1, ease: Power2.easeInOut, y: 0 }
      )
      .play();
    return () => { };
  }, []);

  const loginTypeValue = () => {
    if (props.loginType === "DOT_TEAM") {
      return { role: 4 };
    }
    return {};
  };

  const validate = () => {
    var newPassword = document.getElementById("newPassword").value;
    var confirmPassword = document.getElementById("confirmPassword").value;

    if (newPassword == '' && confirmPassword == '') {
      setPasswordError('Please enter password')
    }
    else if (passwordValidator(newPassword, "Password") && passwordValidator(confirmPassword, "Password")) {
      setPasswordError('Password should have minimum 8 characters with atleast 1 alphabet in Caps 1 special character 1 number')
    }
    else if (newPassword != confirmPassword) {
      setPasswordError("Passwords do not match")
    }
    else if (newPassword === confirmPassword) {
      // setPassword(confirmPassword)
      onSuccess(confirmPassword)
    }
    // else null
  }

  const onSuccess = (confirmPassword) => {
    let value = { email: props.email, password: confirmPassword, ...loginTypeValue() };
    axios
      .put("/forgetPassword/resetPassword", value, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          props.onNext()
        }
        else {
          setPasswordError("Password invalid")
        }
      })
      .catch((er) => {
        setPasswordError("Internal Server Error")
      })
      .finally(() => {
      });
  }

  return (
    <div className="client-login recovery" id="reset_password">
      <p className="login-input-header" style={{ lineHeight: "10px" }}>
        Reset Password
      </p>
      <p className="forgot-password-description">Enter 8 digit password to secure your account.</p>
      <div className="input-form-control reset-password-controller">
        <label className="input-form-control-label" htmlFor="email">
          Enter New Password
        </label>
        <input
          placeholder=""
          className="input-form-control-input"
          type="password"
          autoComplete="new-password"
          aria-autocomplete="none"
          id="newPassword"
          onChange={() => setPasswordError('')}
        />
      </div>
      <div className="input-form-control  reset-password-controller">
        <label className="input-form-control-label" htmlFor="email">
          Confirm New Password
        </label>
        <input
          placeholder=""
          className="input-form-control-input"
          type="password"
          autoComplete="new-password"
          aria-autocomplete="none"
          id="confirmPassword"
          onChange={() => setPasswordError('')}
        />
        <div className='errorLabel'>
          {passwordError}
        </div>
      </div>

      <button
        className="login-type-submit"
        style={{
          height: window.innerHeight / 12,
          width: window.innerHeight / 12,
        }}
        onClick={
          validate
        }
      >
        <Icon name={"arrow right"} size="large" />
      </button>
    </div>
  );
}
