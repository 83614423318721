
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Form,
  Menu,
  Select
} from "semantic-ui-react";
import ContainerTitle from "../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../components/ContainerTitle/TitleWrapper";
import CustomTable from "../../../components/CustomTable/CustomTable";
import axios from "../../../utils/axios";
import moment from "moment";
import AddDotSkills from "./skillsModal";
import { toast } from "react-toastify";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { store } from "../../../redux/store";
import AccessCheck from "../../../utils/AccessCheck";
import { accessActionType, accessModule } from "../../../utils/accessConstant";

const DotSkills = (props) => {
  const { match, history } = props;
  const [skillsData, setSkillsData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [editData, setEditData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [viewStatus, setViewStatus] = useState("view");
  const [modalAction, setModalAction] = useState("add");
  const [pageNumber, setPageNumber] = useState(1)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState({
    sr_no: "",
    date_training: "",
    training_topic: "",
    duration: "",
    details: "",
  });
  // Create new skill 
  const handleSubmit = (value, mode) => {
    let temp = value
    temp.link = temp.link + ":::" + temp.link_type
    delete temp.link_type
    if (mode === "add") {
      axios
        .post("/staff/createSkills", temp)
        .then((res) => {
          if (res.status === 200) {
            fetchData();
            toast.success("Skill Added Successfully", {
              toastId: "clientAddedSuccessfully",
            });
          }
        })
        .catch((e) => {
          let response = e.response;
          toast.error(
            response?.data?.message ||
            "Something went wrong, Please try again later",
            {
              toastId: "clientAddedError",
            }
          );
        })
        .finally(() => {
        });
    } else if (mode === "edit") {
      axios
        .put("/staff/updateSkills", temp)
        .then((res) => {
          if (res.status === 200) {
            fetchData();
            toast.success("Skill Updated Successfully", {
              toastId: "clientUpdateSuccessfully",
            });
          }
        })
        .catch((e) => {
          let response = e.response;
          toast.error(
            response?.data?.message ||
            "Something went wrong, Please try again later",
            {
              toastId: "clientAddedError",
            }
          );
        })
        .finally(() => {
        });
    }
  };
  //for Delete existing skill
  const handleDelete = (id) => {
    axios
      .delete("/staff/deleteSkills", { data: { id: id } })
      .then((res) => {
        if (res.status === 200)
          fetchData();
        toast.success("Skill Deleted Successfully", {
          toastId: "clientdeleteSuccessfully",
        });
      })
      .catch((e) => { })
      .finally(() => {
      });
  }
  // create skill logs
  const viewSkills = (value) => {
    let userData = store.getState().global;
    let categorySplit = value.category.split('-')
    let temp = {
      skill_id: value?.id,
      user_id : userData?.userDetails?.id,
      employee_name: userData?.userDetails?.employee_name,
      category: categorySplit[0],
      training_topic: value?.training_topic
    }
    axios
      .post("/staff/createSkillLogs", temp)
      .then((res) => {
      })
      .catch((e) => {
        // let response = e.response;
        // toast.error(
        //   response?.data?.message ||
        //   "Something went wrong, Please try again later",
        //   {
        //     toastId: "clientAddedError",
        //   }
        // );
      })
      .finally(() => {
        props.history.push({
          pathname: "/dot-skills-view",
          viewData: value
        });
      });
  }
  // fetch staff skill list 
  const fetchData = () => {
    setLoading(true);
    axios
      .get(`/staff/fetchSkillList?limit=10&offset=${pageNumber * 10 - 10}`)
      .then((result) => {
        let data = result.data.result[0].response_fetchSkills;
        data = data.map((value) => {
          let tempData = value.link.split(':::');
          let tempData2 = value.category.split('-');
          return ({
            ...value,
            link: tempData[0],
            categoryData: tempData2[0],
            link_type: tempData[1],
            view: (<p className="link-text w60" onClick={() => viewSkills(value)}>
              View
            </p>)
          })
        })
        setSkillsData([...data])
        setCount(data[0].Total_count)
      })
      .catch((error) => {
        setSkillsData([])
        setCount(0)
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const getSkillsCategory = () => {
    axios
      .get("/master/fetchSkillCategory?limit=&offset=")
      .then((res) => {
        let data = [...res.data.result.rows];
        data = data.map((item) => {
          item = {
            key: `${item.id}_${item.category}`,
            value: item.category + "-" + item.id,
            text: item.category,
          };
          return item;
        });
        setCategoryOptions([...data]);
      })
      .catch((e) => { })
      .finally(() => { });
  };
  useEffect(() => {
    getSkillsCategory()
    if (match?.params?.type === "create") {
      setViewStatus("create");
    }
    fetchData();
  }, [pageNumber]);

  return (
    <>
      <Helmet>
        <title>Dot Skills</title>
        <meta name="dot-skills" content="Dot Skills" />
      </Helmet>
      <div className="container-height-width">
        <div className="container-height-width">
          <TitleWrapper>
            <ContainerTitle title={"Skills"} />
            {(viewStatus === "create" &&
              AccessCheck(accessModule.STAFF_TRAINING_DOT_SKILL, accessActionType.CREATE, store.getState().global?.access)
              && props?.location?.pathname == "/dot-skills/create") ? (
              <div>
                <Button
                  className="dotButton"
                  floated="right"
                  content="Create New Skills"
                  color="green"
                  onClick={() => {
                    setModalAction("add")
                    setModalOpen(true)
                  }}
                />
              </div>
            ) : ""}
          </TitleWrapper>
        </div>
        <div className="tableWrapper">
          <div className="customTableWraper">
            {modalOpen ? <AddDotSkills categoryOptions={categoryOptions} editData={editData} type={modalAction} handleSubmit={handleSubmit} handleClose={() => setModalOpen(false)} /> : ""}
            {viewStatus === "create" &&
              AccessCheck(accessModule.STAFF_TRAINING_DOT_SKILL, accessActionType.READ, store.getState().global?.access)
              && props?.location?.pathname == "/dot-skills/create" ?
              (<CustomTable
                cols={[
                  {
                    id: "categoryData",
                    title: "Category",
                  },
                  {
                    id: "training_topic",
                    title: "Training Topic",
                  },
                  {
                    id: "link",
                    title: "Link",
                  },
                ]}
                data={skillsData}
                count={count}
                setPagination={(n) => setPageNumber(n)}
                activePage={pageNumber}
                error={(e) => { }}
                loading={loading}
                renderMenuItem={(row) => {
                  return (
                    <Menu fluid vertical tabular className="user-menu">
                      {/* <Menu.Item
                        name="edit"
                        onClick={() => {
                          setEditData({
                            id: row.id,
                            category: row.category,
                            training_topic: row.training_topic,
                            link: row.link,
                            link_type: row.link_type
                          })
                          setModalAction("edit")
                          setModalOpen(true)
                        }
                        }
                      >
                        Edit
                      </Menu.Item> */}
                      {AccessCheck(accessModule.STAFF_TRAINING_DOT_SKILL, accessActionType.DELETE, store.getState().global?.access) &&
                        (<Menu.Item
                        name="delete"
                        onClick={() => {
                          handleDelete(row.id);
                        }
                        }
                      >
                        Delete
                      </Menu.Item>)}
                    </Menu>);
                }}
              />
              ) :""}
              {props?.location?.pathname==="/dot-skills/view" ?(
                <CustomTable
                  cols={[
                    {
                      id: "categoryData",
                      title: "Category",
                    },
                    {
                      id: "training_topic",
                      title: "Training Topic",
                    },
                    {
                      id: "view",
                      title: "View",
                    },
                  ]}
                  data={skillsData}
                  count={count}
                  setPagination={(n) => setPageNumber(n)}
                  activePage={pageNumber}
                  loading={loading}
                />
              ):""}
          </div>
        </div>
      </div>
    </>
  );
};
export default compose(withRouter)(DotSkills);
