import React, { useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, Form, Grid, Select } from "semantic-ui-react";
import ModalWrapper from "../../../components/Modals/ModalWrapper";
import { makeSelectDeviceDimension } from "../../../redux/redux/device/selectors";
import {
  resetAddCurrency,
  setAddCurrency,
} from "../../../redux/redux/masters/action";
import makeSelectMaster from "../../../redux/redux/masters/selectors";
import { closeModalAction } from "../../../redux/redux/modals/actions";
import { ACCOUNT_NUMBER, ACCOUNT_NUMBER_LABEL, BANK_ACCOUNT_NAME, BANK_ACCOUNT_NAME_LABEL, BANK_NAME, BANK_NAME_LABEL, CURRENCY_CODE, CURRENCY_CODE_LABEL, CURRENCY_NAME, CURRENCY_NAME_LABEL, IBAN, IBAN_LABEL, SORT_CODE, SORT_CODE_LABEL } from "../../../utils/labelContant";
function AddCurrency(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return (
    <ModalWrapper
      size="tiny"
      header={props.header || "Add Currency Activity"}
      esc
      scrolling
      className={"add"}
      closeModal={props.closeModal}
    >
      <Form.Group >
        <Grid columns={2}>
        <Grid.Column width={8}>
        <Form.Input
          className="masterCurrencyModal"
          label={CURRENCY_NAME}
          placeholder={CURRENCY_NAME}
          name={CURRENCY_NAME_LABEL}
          value={props?.master?.addCurrency?.Currency_name}
          onChange={(e) => dispatch(setAddCurrency(e))}
        />
        <Form.Input
          className="masterCurrencyModal"
          label={BANK_NAME}
          placeholder={BANK_NAME}
          name={BANK_NAME_LABEL}
          value={props?.master?.addCurrency?.bank_name}
          onChange={(e) => dispatch(setAddCurrency(e))}
        />
            
          <Form.Input
          className="masterCurrencyModal"
          label={ACCOUNT_NUMBER}
          placeholder={ACCOUNT_NUMBER}
          name={ACCOUNT_NUMBER_LABEL}
          value={props?.master?.addCurrency?.account_number}
          onChange={(e) => dispatch(setAddCurrency(e))}
            />
          <Form.Input
          className="masterCurrencyModal IBANwidth"
          label={IBAN}
          placeholder={IBAN}
          name={IBAN_LABEL}
          value={props?.master?.addCurrency?.iban}
          onChange={(e) => dispatch(setAddCurrency(e))}
            />
          </Grid.Column>
          <Grid.Column>
          <Form.Input
          className="masterCurrencyModal"
          label={CURRENCY_CODE}
          placeholder={CURRENCY_CODE}
          // width={16}
          name={CURRENCY_CODE_LABEL}
          value={props?.master?.addCurrency?.Currency_code}
          onChange={(e) => dispatch(setAddCurrency(e))}
            />
             <Form.Input
          className="masterCurrencyModal"
          label={BANK_ACCOUNT_NAME}
          placeholder={BANK_ACCOUNT_NAME}
          // width={16}
          name={BANK_ACCOUNT_NAME_LABEL}
          value={props?.master?.addCurrency?.bank_account_name}
          onChange={(e) => dispatch(setAddCurrency(e))}
            />
        
        <Form.Input
          className="masterCurrencyModal sortCode"
          label={SORT_CODE}
          placeholder={SORT_CODE}
          // width={16}
          name={SORT_CODE_LABEL}
          value={props?.master?.addCurrency?.sortcode}
          onChange={(e) => dispatch(setAddCurrency(e))}
        />
       
        
            </Grid.Column>
         </Grid>
      </Form.Group>

      <Grid
        columns="2"
        centered
        verticalAlign="middle"
        style={{ marginTop: "20px" }}
      >
        <Grid.Column>
          <Button
            style={{ width: "100%" }}
            className="secondary"
            onClick={() => {
              setLoading(true);
              props.onSubmit(props.master.addCurrency);
            }}
            loading={loading}
            disabled={loading}
          >
            {props.type && props.type === "edit" ? "UPDATE" : "ADD"}
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="red"
            style={{ width: "100%" }}
            onClick={() => {
              dispatch(resetAddCurrency());
              dispatch(closeModalAction());
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        </Grid.Column>
      </Grid>
    </ModalWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  dimension: makeSelectDeviceDimension(),
  master: makeSelectMaster(),
});

export default connect(mapStateToProps, null)(AddCurrency);