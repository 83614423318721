import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Form, Grid, Input, Segment, Select, TextArea } from "semantic-ui-react";
import ContainerTitle from "../../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../../components/ContainerTitle/TitleWrapper";
import moment from "moment";
import { getCusLocalDateYear, getLocalTime } from "../../../../utils/localTime";

import axios from "../../../../utils/axios";
import {
  addressValidator,
  isDate,
  isZipCode,
  stringType,
} from "../../../../utils/validator";

import { toast } from "react-toastify";
import { useEffect } from "react";
import { DateInput } from "semantic-ui-calendar-react";
function AddEvents({ ...props }) {
  const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    date: "",
    contactPerson: "",
    address: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    eventDescription: "",
  });
  const [formValueError, setFormValueError] = useState({
    nameError: "",
    dateError: "",
    contactPersonError: "",
    addressError: "",
    cityError: "",
    countryError: "",
    stateError: "",
    zipcodeError: "",
    eventDescriptionError: "",
  });

  const getClientById = () => {
    axios
      .get(`/marketing/event/fetchEventById?id=${props.match.id}`)
      .then(async (res) => {
        let data = await { ...res.data.result };
        let updateData = await {
          name: data.event_name || "",
          date: getCusLocalDateYear(data.event_date || ""),
          contactPerson: data.contact_person || "",
          address: data.address || "",
          city: data.city || "",
          country: data.country || "",
          state: data.state || "",
          zipcode: data.zipcode || "",
          eventDescription: data.event_description || "",
        };

        setFormValue((prevState) => updateData);
      })
      .catch((res) => {
        setFormValue({
          name: "",
          date: "",
          contactPerson: "",
          address: "",
          city: "",
          country: "",
          state: "",
          zipcode: "",
          eventDescription: "",
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (props.match && props.match.type && props.match.id) {
      getClientById();
    }
    return () => {};
  }, [props.match.id, props.match.type, props.match]);
  const onValidator = (e) => {
    switch (e.target.name) {
      case "name":
        return stringType(e.target.value, "Name");
      case "date":
        return isDate(e.target.value, "Date");
      case "contactPerson":
        return stringType(e.target.value, "Contact Person");
      case "address":
        return addressValidator(e.target.value, "Address");
      case "city":
        return addressValidator(e.target.value, "City");
      case "country":
        return addressValidator(e.target.value, "Country");
      case "state":
        return addressValidator(e.target.value, "State");
      case "zipcode":
        return isZipCode(e.target.value, "Zip Code");
      case "eventDescription":
        return addressValidator(e.target.value, "Description");
      default:
        return null;
    }
  };

  const onChange = (e) => {
    // let name = e.target.name;
    // let value = e.target.value;

    let newValues = { ...formValue, [e.target.name]: e.target.value };
    let errorData = {
      ...formValueError,
      [`${e.target.name}Error`]: onValidator(e),
    };
    setFormValue({ ...newValues });
    setFormValueError({ ...errorData });
  };

  const validate = () => {
    let isError = false;
    let data = { ...formValue };
    Object.values({ ...formValue }).forEach((value) => {
      if (!value) {
        isError = true; //for future validation do not remove
      }
    });

    return isError;
  };
  const onSubmitValidate = async () => {
    let data = { ...formValue };
    // this.state.dataError.map(async (data) => {
    await Object.keys(data).forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };

      await setFormValueError((prevState) => {
        return { ...prevState, [`${key}Error`]: onValidator(e) };
      });
    });
  };

  const onSubmit = async () => {
    let isError = await validate();
    if (!isError) {
      if (props.match.type && props.match.id) {
        updateEvent();
      } else {
        newEvent();
      }
    } else {
        toast.error("Please enter all mandatory fields", {
          toastId: "JobInvalidData",
        });
      onSubmitValidate();
    }
  };

  const newEvent = async () => {
      setLoadingOnSubmit(true);
      axios
        .post("/marketing/event/addEvent", formValue)
        .then((res) => {
          if (res.status === 200)
            toast.success("Event Added Successfully", {
              toastId: "EventAddedSuccessfully",
            });
          props.history.replace("/events");
        })
        .catch((e) => {
          let response = e.response;
          toast.error(
            response.data.message ||
              "Something went wrong, Please try again later",
            {
              toastId: "eventAddedError",
            }
          );
        })
        .finally(() => {
          setLoadingOnSubmit(false);
        });
  };
  const updateEvent = async () => {
      setLoadingOnSubmit(true);
      axios
        .put(`/marketing/event/updateEventById?id=${props.match.id}`, formValue)
        .then((res) => {
          if (res.status === 200)
            toast.success(`Event Updated Successfully with Id: ${props.match.id}`, {
              toastId: "EventUpdatedSuccessfully",
            });
          props.history.replace("/events");
        })
        .catch((e) => {
          let response = e.response;
          toast.error(
            response.data.message ||
              "Something went wrong, Please try again later",
            {
              toastId: "eventAddedError",
            }
          );
        })
        .finally(() => {
          setLoadingOnSubmit(false);
        });
  };

  return (
    <Grid className="sideMargin">
      <Helmet>
        <title>
          Add New Event
          {/* {props.match && props.match.type && props.match.id
            ? "Update New Client"
            : "Add New Client"} */}
        </title>
        <meta name="description" content="Description of Clients" />
      </Helmet>

      <div className={"container-height-width"}>
        <TitleWrapper>
          <ContainerTitle
            title={
              "Add New Event"
              // props.match && props.match.type && props.match.id
              //   ? "Update New Client"
              //   : "Add New Client"
            }
          />
        </TitleWrapper>
      </div>
      <Grid.Column
        mobile={16}
        tablet={16}
        computer={16}
        className="topBottomMarginNone"
      >
        <div>
          <Segment raised className="chart-container-height">
            <div className="formContainer">
              <Form className="fontQuicksand">
                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field
                    className="inlineLabel topMarginInput margin-side mt-2"
                    label="EVENT NAME"
                    placeholder="Event Name"
                    width={8}
                    name="name"
                    value={formValue.name}
                    onChange={onChange}
                    control={Input}
                    error={
                      formValueError.nameError ? formValueError.nameError : null
                    }
                  />

                  <DateInput
                    label="EVENT DATE"
                    popupPosition="bottom"
                    className={"dateInput topMarginInput inlineLabel margin-side"}
                    icon={"calendar outline pointer large"}
                    name="date"
                    closeOnMouseLeave
                    placeholder="Event Date"
                    value={formValue.date}
                    iconPosition="right"
                    width={8}
                    closable
                    dateFormat={"DD-MM-YYYY"}
                    hideMobileKeyboard
                    animation={"drop"}
                    clearable
                    onChange={(event, { name, value }) => {
                      event.target.value = value;
                      event.target.name = "date";
                      onChange(event);
                    }}
                    error={
                      formValueError.dateError ? formValueError.dateError : null
                    }
                    minDate={moment()}
                  />
                </Form.Group>
                <Form.Group className="formGroup marginBottomNone" widths={16}>
                  <Form.Field
                    className="inlineLabel topMarginInput margin-side"
                    label="EVENT CONTACT PERSON"
                    placeholder="Event Contact Person"
                    width={8}
                    name="contactPerson"
                    value={formValue.contactPerson}
                    onChange={onChange}
                    control={Input}
                    error={
                      formValueError.contactPersonError
                        ? formValueError.contactPersonError
                        : null
                    }
                  />
                  <Form.Field
                    className="inlineLabel topMarginInput  margin-side"
                    label="EVENT ADDRESS"
                    placeholder="Event Address"
                    width={8}
                    name="address"
                    value={formValue.address}
                    onChange={onChange}
                    control={Input}
                    error={
                      formValueError.addressError
                        ? formValueError.addressError
                        : null
                    }
                  />
                </Form.Group>
                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field
                    className="inlineLabel topMarginInput margin-side"
                    label="CITY"
                    placeholder="City"
                    width={8}
                    name="city"
                    value={formValue.city}
                    onChange={onChange}
                    control={Input}
                    error={
                      formValueError.cityError ? formValueError.cityError : null
                    }
                  />
                  <Form.Field
                    className="inlineLabel topMarginInput margin-side"
                    label="COUNTRY"
                    placeholder="Country"
                    width={8}
                    name="country"
                    value={formValue.country}
                    onChange={onChange}
                    control={Input}
                    error={
                      formValueError.countryError
                        ? formValueError.countryError
                        : null
                    }
                  />
                </Form.Group>
                <Form.Group widths={16} className="formGroup marginBottomNone">
                  <Form.Field
                    className="inlineLabel topMarginInput margin-side"
                    label="STATE"
                    placeholder="State"
                    width={8}
                    name="state"
                    value={formValue.state}
                    onChange={onChange}
                    control={Input}
                    error={
                      formValueError.stateError
                        ? formValueError.stateError
                        : null
                    }
                  />
                  <Form.Field
                    className="inlineLabel topMarginInput margin-side"
                    label="ZIP CODE"
                    placeholder="Zip Code"
                    width={8}
                    name="zipcode"
                    value={formValue.zipcode}
                    onChange={onChange}
                    control={Input}
                    error={
                      formValueError.zipcodeError
                        ? formValueError.zipcodeError
                        : null
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    className="inlineLabel topMarginInput margin-side"
                    label="EVENT DESCRIPTION"
                    placeholder="Event Description"
                    width={16}
                    rows={2}
                    name="eventDescription"
                    value={formValue.eventDescription}
                    onChange={onChange}
                    control={TextArea}
                    error={
                      formValueError.eventDescriptionError
                        ? formValueError.eventDescriptionError
                        : null
                    }
                  />
                </Form.Group>

                <div className="buttonDiv">
                  <Button
                    type="submit"
                    className="dotButton margin-side mt-2 mb-2"
                    onClick={onSubmit}
                    loading={loadingOnSubmit}
                    disabled={loadingOnSubmit}
                  >
                    {props.match && props.match.type && props.match.id
                      ? "Update"
                      : "Submit"}
                  </Button>
                </div>
              </Form>
            </div>
          </Segment>
        </div>
      </Grid.Column>
    </Grid>
  );
}

export default AddEvents;
