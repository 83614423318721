import Snowfall from "react-snowfall";

import reindeer from "./images/reindeer.png"
import walkingSanta from "./images/walkingSanta.gif"
import bell from "./images/bell.gif"
import bell2 from "./images/bell.gif"
import snowMan from "./images/snowMan.gif"
import trees from "./images/trees.png"

import ganpatiLeftBanner from "./images/ganpatiLeftBanner.png"



import design from "./images/design.png"
import modak from "./images/modak.gif"
import tempSmall from "./images/tempSmall.png"
import tempBig from "./images/tempBig.png"
import people1 from "./images/people.png"
import people2 from "./images/people.png"
import people3 from "./images/people.png"

import jumber from "./images/jumber.png"
import jumber2056 from "./images/jumber2056.png";
import leftLantern from "./images/leftLantern.gif"
import leftLantern2056 from './images/leftLantern2056.gif';
import design_diwali from "./images/design.png"
import design_diwali_2056 from "./images/designdiwali2056.png";
import line from "./images/line.svg"
import line_horizontal from "./images/line_horizontal.png"


import line_verticle from "./images/line_verticle.svg"
import lampBottom from "./images/lampBottom.gif"
import lampBottom2056 from './images/lampBottom_2056.gif';



import leftSplash from "./images/leftSplash.gif"
import bottomHappyHoli from "./images/bottomHappyHoli.gif"
import rightBaloons from "./images/rightBaloons.gif"



import leftJumer from "./images/leftJumer.png"
import rightMoon from "./images/rightMoon.gif"
import leftMasjit from "./images/leftMasjit.png"
import leftName from "./images/leftName.png"
import leftStar from "./images/leftStar.gif"
import rightMasjit from "./images/rightMasjit.png"


import leftTop from "./images/leftTop.gif"
import rightEgg from "./images/rightEgg.gif"
import rightBottomEaster from "./images/rightBottomEaster.png"
import leftBottom from "./images/leftBottom.png"
import grass from "./images/grass.png"


import Navratri_new from "./images/navratri_12_59.gif"
import Navratri1024 from './images/Navaratri_1024.gif';
import Navratri2056 from './images/Navaratri_2056.gif';
import rightDandiya from "./images/rightDandiya.png"
import rightDandiya1024 from './images/rightDandiya1024.png';
import rightDandiya2056 from './images/rightdandiya_2056.png';
import designRightNavratri from "./images/designRightNavratri.png"
import designRightNavratri1024 from './images/Nav_man_1024.png';
import designRightNavratri2056 from './images/Nav_man_2056.png';
import leftPeople from "./images/leftPeople.png"
import leftPeople1024 from "./images/Playing Danndiya_1024.png";
import leftPeople2056 from "./images/Playing Danndiya_2056.png";
import rightPeople from "./images/rightPeople.png"

import Friday from "../../styles/images/Group 2085662804.png"
import topleft from "../../styles/images/goodFridayTopLogo.png"
import GoodFriday from "../../styles/images/goodFridayLogo.png"
import GoodFridayBottom from "../../styles/images/goodFridayBottomOrange.png"
import GoodFridayBottomwhite from "../../styles/images/Clip_path_group1.png"





import { useEffect, useState } from "react";

const Theme=({theme})=>{
    console.log(theme)

    // const templateOptions = ["Christmas", "Diwali", "Holi", "Navratri", 
    // "Ganesh Chaturthi", "Ramzan", "Easter", "Good Friday"];

    const snowflake1 = document.createElement('img')
    snowflake1.src = './images/2.jpg'
    const snowflake2 = document.createElement('img')
    snowflake2.src = '/assets/3.jpg'

    const images=[snowflake1,snowflake2]

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 3000); // 3-second delay
        return () => clearTimeout(timer); // Cleanup timeout
    }, []);

    return(
        <>

            {theme==="Christmas"?
                <div className="login-new-ui chrismas-theme">
                    <img src={reindeer} alt="hi I am santa component" className="fade-in" />
                    <img
                        src={walkingSanta}
                        alt="hi I am santa component"
                        className={`delayed-appear ${isVisible ? 'visible' : ''}`}
                        style={{
                            right: "18%",
                            top: "17%",
                            width: "209px",
                            height: "212px",
                            zIndex:10,
                        }}
                    />
                    <img
                        src={bell}
                        alt="hi I am santa component"
                        className={`delayed-appear ${isVisible ? 'visible' : ''}`}
                        style={{
                            right: "5%",
                            top: "5%",
                            width: "130px",
                            height: "131px",
                            zIndex:10,
                        }}
                    />
                    <img
                        src={bell2}
                        alt="hi I am santa component"
                        className={`delayed-appear ${isVisible ? 'visible' : ''}`}
                        style={{
                            transform: "scaleX(-1)",
                            right: "9%",
                            top: "15%",
                            width: "130px",
                            height: "131px",
                            zIndex:10,
                        }}
                    />
                    <img
                        src={snowMan}
                        alt="hi I am santa component"
                        className="fade-in"
                        style={{
                            transform: "scaleX(-1)",
                            left: "1%",
                            bottom: "1%",
                            width: "274px",
                            height: "256px",
                            zIndex:10,
                        }}
                    />
                    <img
                        src={trees}
                        style={{ bottom: 0, right: 5 }}
                        alt="hi I am santa component"
                        className="fade-in"
                    />
                    <Snowfall snowflakeCount={200} />
                    </div>:""}

{theme==="Diwali"?
<div className="login-new-ui diwali">
    {/* <img src={jumber} alt="diwali image" style={{
    position:"absolute",
    top:"0",
    left:"55px",
    height:"496.64px",
    width:"219.75px", 
    }} */}

    <img
        src={window.innerWidth >= 2056 ? jumber2056 : jumber}
        alt="diwali image"
        style={{
            position: "absolute",
            top: "0",
            left: "55px",
            width: window.innerWidth >= 2056 ? "600px" : "219.75px",
            height: window.innerWidth >= 2056 ? "600px" : "496.64px",
        }}
    />

    <img
        src={window.innerWidth >= 2056 ? leftLantern2056 : leftLantern}
        alt="diwali image"
        style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: window.innerWidth >= 2056 ? "400px" : "258px",
            height: window.innerWidth >= 2056 ? "400px" : "258px",
        }}
    />


<img
    src={window.innerWidth >= 2056 ? design_diwali_2056 : design_diwali}
    alt="diwali image"
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position: "absolute",
        top: window.innerWidth >= 1500 ? "1%" : "0px",
        right:
            window.innerWidth >= 2056 ? "8%" :
            window.innerWidth >= 1850 ? "7%" :
            window.innerWidth >= 1620 ? "5%" :
            window.innerWidth >= 1500 ? "3%" :
            window.innerWidth >= 1024 ? "5%" : "-4%",
        zIndex: "10",
        height:
            window.innerWidth >= 2056 ? "450px" :
            window.innerWidth >= 1500 ? "350px" : "300px",
    }}
/>


    <img src={line_horizontal} alt="diwali image" style={{
        position:"absolute",
        bottom:"4%",
        opacity:"90%",
        left:"135px",

    }}
    />
    <img src={line_horizontal} alt="diwali image" style={{
        position:"absolute",
        bottom:"4.8%",
        opacity:"90%",
        left:"135px",

    }}
    />
    <img src={line_verticle} alt="diwali image" style={{
        position:"absolute",
        bottom:"9%",
        left:"40.24px",

    }}
    />
    <img
        src={window.innerWidth >= 2056 ? lampBottom2056 : lampBottom}
        alt="diwali image"
        style={{
            position: "absolute",
            bottom: "-102px",
            left: "-102px",
            width: window.innerWidth >= 2056 ? "600px" : "418px",
            height: window.innerWidth >= 2056 ? "600px" : "418px",
        }}
    />

</div>:""}

{theme==="Holi"?
<div className="login-new-ui holi">

<img
    src={leftSplash}
    alt="holi image"
    style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: window.innerWidth >= 1920 ? "500px" : "270px",
        height: window.innerWidth >= 1920 ? "470px" : "220px",
    }}
/>

<img
    src={leftSplash}
    alt="holi image"
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position: "absolute",
        top: "40px",
        right: window.innerWidth >= 2056 ? "1%" : "0",
        width:
            window.innerWidth >= 2056
                ? "400px"
                : window.innerWidth >= 1920
                ? "350px"
                : "270px",
        height:
            window.innerWidth >= 2056
                ? "400px"
                : window.innerWidth >= 1920
                ? "270px"
                : "220px",
        zIndex:10,
    }}
/>

<img
    src={rightBaloons}
    alt="holi image"
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position: "absolute",
        top: window.innerWidth >= 1920 ? "15%" : "123px",
        right: window.innerWidth >= 1920 ? "15%" : "10%",
        width: window.innerWidth >= 2056 ? "300px" : "227.79px",
        zIndex: 10
    }}
/>


<img
    src={bottomHappyHoli}
    alt="holi image"
    style={{
        position: "absolute",
        bottom: "0",
        left: window.innerWidth >= 2056 ? "1%" : "13px",
        width: window.innerWidth >= 2056 ? "400px" : "227.79px",
    }}
/>





</div>:""}

{theme==="Navratri"?
<div className="login-new-ui navratri">
<img
    src={
        window.innerWidth > 2056 
            ? Navratri_new 
            : window.innerWidth > 1024 
                ? Navratri_new 
                : Navratri_new
    }
    alt="holi image"
    style={{
        position: "absolute",
        width: window.innerWidth > 1440 ? "500px" : "396px",
        height: window.innerWidth > 1440 ? "500px" : "396px",
        top: window.innerWidth > 1440 ? "-3%" : "-5%",
        left: window.innerWidth > 1440 ? "-2px" : "-1px",
    }}
/>

<img
    src={
        window.innerWidth >= 2056 
            ? rightDandiya2056 
            : window.innerWidth > 1024 
                ? rightDandiya1024
                : rightDandiya
    }
    alt="holi image"
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position: "absolute",
        width: 
            window.innerWidth >= 2056 ? "550px" :
            window.innerWidth >= 1850 ? "450px" :
            window.innerWidth >= 1620 ? "450px" :
            window.innerWidth >= 1440 ? "400px" :
            "333.93px",
        height: 
            window.innerWidth >= 2056 ? "600px" :
            window.innerWidth >= 1850 ? "450px" :
            window.innerWidth >= 1620 ? "500px" :
            window.innerWidth >= 1440 ? "450px" :
            "375px",
        top: "20%",
        right: 
            window.innerWidth >= 2056 ? "10%" :
            window.innerWidth >= 1850 ? "15%" :
            window.innerWidth >= 1620 ? "5%" :
            window.innerWidth >= 1440 ? "5%" :
            window.innerWidth >= 1024 ? "10%" :
            "20px",
        zIndex: "20",
    }}
/>



<img
    src={designRightNavratri}
    alt="holi image"
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position: "absolute",
        zIndex: "10",
        height: 
            window.innerWidth >= 2056 ? "450px" :
            window.innerWidth >= 1850 ? "400px" :
            window.innerWidth >= 1620 ? "350px" : "300px",
        top: window.innerWidth >= 1500 ? "1%" : "0px",
        right: 
            window.innerWidth >= 2056 ? "9%" :
            window.innerWidth >= 1850 ? "5%" :
            window.innerWidth >= 1620 ? "6%" :
            window.innerWidth >= 1500 ? "5%" :
            window.innerWidth >= 1024 ? "3%" : "-4%",
    }}
/>


<img
    src={
        window.innerWidth >= 2056 
            ? rightPeople 
            : window.innerWidth > 1024 
                ? rightPeople 
                : rightPeople
    }
    alt="holi image"
    style={{
        position: "absolute",
        height: window.innerWidth > 1440 ? "150px" : "110.9px",
        width: window.innerWidth > 1440 ? "600px" : "435.95px",
        bottom: "-20px",
        right: "0",
    }}
/>

    <img
        src={
            window.innerWidth >= 2056 
                ? leftPeople2056 
                : window.innerWidth > 1024 
                    ? leftPeople1024 
                    : leftPeople
        }
        alt="holi image"
        style={{
            position: "absolute",
            height: window.innerWidth > 1440 ? "150px" : "110.9px",
            width: window.innerWidth > 1440 ? "800px" : "690.43px",
            bottom: window.innerWidth > 1440 ? "-30px" : "-20px",
            right: window.innerWidth > 1440 ? "35%" : "31%",
        }}
    />

    <img
        src={tempBig}
        alt="ganapati image"
        style={{
            width: window.innerWidth > 1440 ? "250px" : "144.98px",
            height: window.innerWidth > 1440 ? "800px" : "433.21px",
            bottom: window.innerWidth > 1440 ? "0%" : "-5%",
            left: "0px",
        }}
    />

<img
    src={tempSmall}
    alt="ganapati image"
    style={{
        width: window.innerWidth > 1440 ? "250px" : "134.45px",
        height: window.innerWidth > 1440 ? "500px" : "297.68px",
        bottom: "0%",
        left: "10%",
    }}
/>



</div>:""}

{theme==="Ganesh Chaturthi"?
<div className="login-new-ui ganesh-chaturthi" >

<img
    src={ganpatiLeftBanner}
    alt="ganapati image"
    style={{
        position: "absolute",
        top: "0",
        left: "0",
        height: window.innerWidth > 2560 ? "800px" : window.innerWidth >= 1920 ? "600px" : "354px",
        width: window.innerWidth > 2560 ? "800px" : window.innerWidth >= 1920 ? "600px" : "354px",
    }}
/>



<img
    src={design}
    alt="ganapati image"
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position: "absolute",
        top: "0",
        right:
            window.innerWidth >= 2056 ? "8%" :
            window.innerWidth >= 1920 ? "4%" :
            window.innerWidth >= 1620 ? "7%" :
            window.innerWidth >= 1480 ? "6%" :
            window.innerWidth >= 1024 ? "4%" : "6%", // Default for smaller screens
        height:
            window.innerWidth >= 2056 ? "450px" :
            window.innerWidth >= 1920 ? "450px" :
            window.innerWidth >= 1620 ? "350px" :
            window.innerWidth >= 1500 ? "300px" :
            window.innerWidth >= 1024 ? "300px" : "300px", // Default for smaller screens
        zIndex: "10",
    }}
/>




        <img src={modak} alt="ganapati image" 
        className={`delayed-appear ${isVisible ? 'visible' : ''}`}
        style={{
            width:"185px",
            height:"185px",
            top:"15%",
            right:"15%",
            zIndex: "10"
        }}/>

<img
    src={tempBig}
    alt="ganapati image"
    style={{
        width: window.innerWidth >= 1920 ? "300px" : "185px",
        height: window.innerWidth >= 1920 ? "800px" : "554px",
        bottom: "0",
        right: "0",
    }}
/>


<img
    src={tempSmall}
    alt="ganapati image"
    style={{
        width: window.innerWidth >= 1920 ? "300px" : "172px",
        height: window.innerWidth >= 1920 ? "500px" : "380px",
        bottom: "0%",
        right: "11%",
    }}
/>





<img
    src={people1}
    alt="ganapati image"
    style={{
        width: window.innerWidth >= 1920 ? "500px" : "350px",
        height: window.innerWidth >= 1920 ? "400px" : "268px",
        bottom: "0%",
        left: "0",
    }}
/>

        <img src={people2} alt="ganapati image" style={{
            width: window.innerWidth >= 1920 ? "500px" : "350px",
            height: window.innerWidth >= 1920 ? "400px" : "268px",
            bottom:"-7%",
            left:window.innerWidth >= 1920 ? "17%" : "326px",
        }}
        
    />
    <img src={people3} alt="ganapati image" style={{
        width: window.innerWidth >= 1920 ? "500px" : "350px",
        height: window.innerWidth >= 1920 ? "400px" : "268px",
        bottom:"-14%",
        left:window.innerWidth >= 1920 ? "35%" : "672px",
    }}
    
    
    />

</div>:""}

{theme==="Ramzan"?
<div className="login-new-ui ramzan">

<img
    src={leftJumer}
    alt="holi image"
    style={{
        position: "absolute",
        width: window.innerWidth > 1440 ? "1000px" : "700px",
        height: window.innerWidth > 1440 ? "190px" : "140px",
        top: "",
        left: "-11px",
    }}
/>

<img
    src={rightMoon} 
    alt="holi image"
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position: "absolute",
        width: window.innerWidth >= 2056 ? "200px" : "150px",
        height: window.innerWidth >= 2056 ? "200px" : "150px",
        right: 
            window.innerWidth >= 2056 ? "12%" :
            window.innerWidth >= 1850 ? "10%" :
            window.innerWidth >= 1620 ? "8%" :
            window.innerWidth >= 1440 ? "8%" :
            window.innerWidth >= 1024 ? "60px" :
            "0%",
        zIndex: "10",
    }}
/>

<img
    src={leftMasjit}
    alt="holi image"
    style={{
        position: "absolute",
        width: 
            window.innerWidth >= 2056 ? "800px" :
            window.innerWidth >= 1850 ? "700px" :
            window.innerWidth >= 1620 ? "600px" :
            window.innerWidth >= 1440 ? "500px" :
            "455px",
        height: 
            window.innerWidth >= 2056 ? "600px" :
            window.innerWidth >= 1850 ? "500px" :
            window.innerWidth >= 1620 ? "450px" :
            window.innerWidth >= 1440 ? "350px" :
            "374px",
        bottom: window.innerWidth >= 1440 ? "10%" : "20px",
        left: "8px",
    }}
/>


<img
    src={leftName}
    alt="holi image"
    style={{
        position: "absolute",
        width: window.innerWidth > 1440 ? "300px" : "151.2px",
        height: window.innerWidth > 1440 ? "100px" : "51px",
        bottom: window.innerWidth > 1440 ? "8%" : "20px",
        left: window.innerWidth > 1440 ? "18%" : "276px",
    }}
/>


<img
    src={rightMasjit}
    alt="holi image"
    style={{
        position: "absolute",
        width: window.innerWidth > 1440 ? "900px" : "480px",
        height: window.innerWidth > 1440 ? "800px" : "412.96px",
        bottom: "0px",
        right: "0px",
    }}
/>


<img
    src={leftStar}
    alt="holi image"
    style={{
        position: "absolute",
        width: window.innerWidth > 1440 ? "100px" : "50px",
        height: window.innerWidth > 1440 ? "100px" : "50px",
        bottom: window.innerWidth > 1440 ? "20%" : "134px",
        left: window.innerWidth > 1440 ? "4%" : "50px",
        opacity: "0.5",
    }}
/>

<img
    src={leftStar}
    alt="holi image"
    style={{
        position: "absolute",
        width: window.innerWidth > 1440 ? "90px" : "40px",
        height: window.innerWidth > 1440 ? "90px" : "40px",
        bottom: window.innerWidth > 1440 ? "40%" : "210px",
        left: window.innerWidth > 1440 ? "3%" : "40px",
        opacity: "0.5",
    }}
/>

<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"20px",
    height:"20px",
    bottom:"210px",
    left:"90px",
    opacity:"50%"
}}
/>

<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"50px",
    height:"50px",
    bottom:"350px",
    left:"174.15px",
    transform: "rotate(90deg)",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"40px",
    height:"40px",
    bottom:"370px",
    left:"250.33px",
    transform: "rotate(90deg)",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"50px",
    height:"50px",
    bottom:"289px",
    left:"350.85px",
    transform: "rotate(144.79deg)",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"40px",
    height:"40px",
    bottom:"230px",
    left:"415.15px",
    transform: "rotate(144.79deg)",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"20px",
    height:"20px",
    bottom:"230px",
    left:"395.84px",
    transform: "rotate(144.79deg)",
    opacity:"50%"
}}
/>

</div>:""}

{theme==="Easter"?
<div className="login-new-ui easter">
<img
    src={leftTop}
    alt="holi image"
    style={{
        position: "absolute",
        width: window.innerWidth >= 1920 ? "600px" : "300px",
        height: window.innerWidth >= 1920 ? "600px" : "300px",
        top: window.innerWidth >= 1920 ? "-5%" : "-30px",
        left: "0",
        transform: "rotate(-27.22deg)",
    }}
/>

<img
    src={rightEgg}
    alt="holi image"
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position: "absolute",
        width: window.innerWidth >= 1920 ? "250px" : "120px",
        height: window.innerWidth >= 1920 ? "250px" : "120px",
        top: "40.97px",
        left: window.innerWidth >= 1920 ? "45%" : "671px",
        transform: "rotate(-18.95deg)",
        zIndex: 10,
    }}
/>


<img
    src={rightBottomEaster}
    alt="holi image"
    style={{
        position: "absolute",
        width: window.innerWidth >= 1920 ? "700px" : "377px",
        height: window.innerWidth >= 1920 ? "700px" : "377px",
        bottom: "20px",
        right: "0px",
        zIndex: "20",
    }}
/>

<img
    src={leftBottom}
    alt="holi image"
    style={{
        position: "absolute",
        width: window.innerWidth >= 1920 ? "600px" : "389.44px",
        height: window.innerWidth >= 1920 ? "300px" : "186.86px",
        bottom: "0px",
        left: "0px",
    }}
/>

<img src={grass} alt="holi image" style={{
    position:"absolute",
    width:"80%",
    opacity:"50%",
    bottom:"0",
    right:"0",          
}}
/>

</div>:""}
            {theme === "Good Friday" && (
                <div className="login-new-ui">
                    <img
                        src={topleft}
                        alt="Top Left Decoration"
                        className="top-left"
                    />
                    <img
                        src={topleft}
                        alt="Top Left Decoration Duplicate"
                        className="top-left-duplicate"
                    />
                    <img
                        src={GoodFriday}
                        alt="Good Friday Symbol"
                        className="good-friday-symbol"
                    />
                    <img
                        src={Friday}
                        alt="Friday Symbol"
                        className="friday-symbol"
                    />
                    <img
                        src={GoodFridayBottom}
                        alt="Good Friday Background"
                        className="good-friday-background"
                    />
                    <img
                        src={GoodFridayBottom}
                        alt="Good Friday Background Overlay"
                        className="good-friday-overlay"
                    />
                    <img
                        src={GoodFridayBottomwhite}
                        alt="White Good Friday Background"
                        className="good-friday-white-background"
                    />
                </div>
            )}


        </>
    )

}
export default Theme;