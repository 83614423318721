import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import { DateInput } from "semantic-ui-calendar-react";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Segment,
  Select,
} from "semantic-ui-react";
import ContainerTitle from "../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../components/ContainerTitle/TitleWrapper";
import {
  decimalValidator,
  isDate,
  stringType,
} from "../../../utils/validator";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { BUCKET_FOLDER } from "../../../utils/labelContant";
import { uploadPresigned } from "../../../utils/presignedUpload";

const AddTraining = (props) => {
  const history1 = useHistory();
  const [trainee, setTrainee] = useState();
  const [trainingId, setTrainingId] = useState("");
  const [form, setForm] = useState();
  const [formValue, setFormValue] = useState({
    date: "",
    duration: "",
    dotTrainer: null,
    externalTrainer: "",
    training_topic: "",
    document: null,
  });
  const [formValueError, setFormValueError] = useState({
    dateError: "",
    durationError: "",
    externalTrainerError: "",
    training_topicError: "",
  });
  const [staffData, setStaffData] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [file, setFile] = useState([]);
  const { match, history, location } = props;
  const [dotTrainer, setDotTrainer] = useState([]);
  const [isDisable, setDisable] = useState(false);

  // validation for formValue
  const onValidator = (e) => {
    switch (e.target.name) {
      case "date":
        return isDate(e.target.value, "Date");
      case "duration":
        return decimalValidator(e.target.value, "Duration");
      case "externalTrainer":
        return stringType(e.target.value, "External Trainer");
      case "training_topic":
        return stringType(e.target.value, "Training Topic");
      default:
        return null;
    }
  };
  //if formvalue is empty then show error
  const validateForm = () => {
    let isError = false;
    Object.values({ ...formValue }).forEach((value) => {
      if (value === "") {
        isError = true;
      }
    });
    return isError;
  };

  // check form value and set error
  const onSubmitForm = async () => {
    let data = { ...formValue };
    await Object.keys(data).forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };
      await setFormValueError((prevState) => {
        return { ...prevState, [`${key}Error`]: onValidator(e) };
      });
    });
  };

  // set onChange value of formValue
  const onChange = (e) => {
    let newValues = { ...formValue, [e.target.name]: e.target.value };
    let errorData = {
      ...formValueError,
      [`${e.target.name}Error`]: onValidator(e),
    };
    setFormValue({ ...newValues });
    setFormValueError({ ...errorData });
  };

  // onclick of submit form submitted or show error
  const onSubmit = async () => {
    let isError = await validateForm();
    if (!isError) {
      setForm({ ...formValue });
      getData();

    } else {
      toast.error("Please enter all mandatory fields", {
        toastId: "JobInvalidData",
      });
      onSubmitForm();
    }
  };

  // fetch all staff and manager
  const fetchStaff = () => {
    let result = [];
    let resultManager = [];
    let result1 = [];
    axios
      .get("/staff/fetchAllDotStaff")
      .then((res) => {
        result = [...res.data.result.rows];
        let filterDoc1 = result.filter((e) => e.role !== 3);
        let filterDocManager = result.filter((e) => e.role !== 1);
        result = filterDoc1.map((item, index) => {
          return {
            label: item?.employee_name,
            key: item?.employee_name,
            value: item?.id + "-" + item?.employee_name,
          };
        });
        result1 = filterDoc1.map((item, index) => {
          return {
            text: item?.employee_name,
            key: item?.employee_name,
            value: item?.id + "-" + item?.employee_name,
          };
        });
        resultManager = filterDocManager.map((item, index) => {
          return {
            text: item?.employee_name,
            key: item?.employee_name + item?.id,
            value: item?.id,
          };
        });
        setStaffData([...result]);
        setManagerData([...resultManager]);
        setDotTrainer([...result1]);
      })
      .catch((err) => { })
      .finally(() => { });
  };

  const checkMultiSelect = async (value) => {
    setTrainee([...value]);
  };

  // API call for Add new training 
  const getData = () => {
    let temp1;
    let temp4 = formValue.dotTrainer.split("-");
    let list = trainee.map((item) => {
      temp1 = item.value.split("-");
      let newVal = {
        id: parseInt(temp1[0]),
        name: temp1[1],
      };
      return newVal;
    });
    let valueData = {
      date: moment(formValue.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      training_topic: formValue.training_topic,
      duration: formValue.duration,
      externalTrainer: formValue.externalTrainer,
      dotTrainer: {
        id: parseInt(temp4[0]),
        name: temp4[1],
      },
      listOfTrainee: [...list],
      document: formValue.document ? formValue.document : "",
    };
    axios
      .post(`/staff/training/saveTrainings`, valueData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Submit Successfully", {
            toastId: "clientAddedSuccessfully",
          });
          props.history.replace("/training-list")
        }
      })
      .catch((err) => { })
      .finally(() => { });
  };

  const getDataUpdate = (id, type) => {
    axios
      .get(`/staff/training/fetchAllTrainingsByTrId?tr_id=${id}`)
      .then((res) => {
        let arrayData = res?.data?.result?.rows
        setTrainingId(id)
        let trainee = arrayData?.map((value) => {
          let abc = {
            label: value?.trainee_name,
            key: value?.trainee_name,
            value: value?.trainee_user_id +
              "-" +
              value?.trainee_name
          };
          return abc;
        })
        let temp = {
          date: moment(arrayData[0].date_of_training, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          ),
          duration: arrayData[0]?.duration,
          dotTrainer:
            (arrayData[0]?.trainer_user_id +
              "-" +

              arrayData[0]?.dot_trainer_name),
            
          externalTrainer: arrayData[0]?.external_trainer,
          training_topic: arrayData[0]?.training_topic,
          document: arrayData[0]?.training_documents || null,
        };
        setTrainee([...trainee]);

        setFormValue({
          ...temp,
        });
      })
      .catch((err) => { })
      .finally(() => { });
  }
  // API call for update training
  const setData = (value) => {
    let temp1;
    let temp4 = formValue.dotTrainer.split("-");
    let list = trainee.map((item) => {
      temp1 = item.value.split("-");
      let newVal = {
        id: parseInt(temp1[0]),
        name: temp1[2],
      };
      return newVal;
    });
    let valueData = {
      date: moment(formValue.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      training_topic: formValue.training_topic,
      duration: formValue.duration,
      externalTrainer: formValue.externalTrainer,
      dotTrainer: {
        id: parseInt(temp4[0]),
        name: temp4[2],
      },
      listOfTrainee: [...list],
      document: formValue.document || "",
    };
    axios
      .post(`/staff/training/saveTrainings`, {
        tr_id: trainingId,
        ...valueData,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Update Successfully", {
            toastId: "clientAddedSuccessfully",
          });
          props.history.replace("/training-list")

        }
      })
      .catch((err) => { })
      .finally(() => { });
  };

  //add document to S3 bucket
  const handleUploadToS3 = async (files, cb) => {
    setDisable(true);
    try {
          await uploadPresigned(files[0], BUCKET_FOLDER.STAFF_TRAINING)
            .then((uploadFile) => {
              const preDefineUrl = uploadFile[0]?.config?.url;
              const location = preDefineUrl?.substring(0, preDefineUrl?.indexOf("?"));

              let resp = {
                location: location,
              }

              return cb(resp);
            })

          setDisable(false);
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "SomethingWrong" });
    }
  };

  let doc_name = formValue?.document;
  if (doc_name) {
    doc_name = doc_name.split("/");
  }

  useEffect(() => {
    fetchStaff();
    if (match.type === "update" && location.updateData) {
      getDataUpdate(location.updateData.tr_id, "update")
    }
    if (match.type === "view" && props.location.viewData) {
      getDataUpdate(location.viewData.tr_id, "view")
    }
  }, [file]);
  return (
    <>
      <div className="container-height-width">
        <TitleWrapper>
          <div className="container-height-width">
            <ContainerTitle title={"Training"} />
          </div>
        </TitleWrapper>
      </div>
      <div className="container-height-width">
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Segment style={{ borderRadius: "10px" }}>
            <div className="attendanceTopInputsContainer">
              <Form>
                <Form.Group className="formGroup" width={16}>
                  <DateInput
                    popupPosition="bottom center"
                    label="DATE"
                    className={"inlineLabel topMarginInput"}
                    name="date"
                    closeOnMouseLeave
                    placeholder="DATE"
                    clearable={true}
                    search="true"
                    value={formValue.date}
                    iconPosition="right"
                    width={8}
                    dateFormat={"DD-MM-YYYY"}
                    hideMobileKeyboard
                    animation={"drop"}
                    onChange={(event, { value }) => {
                      event.target.value = value;
                      event.target.name = "date";
                      onChange(event);
                    }}
                    error={
                      formValueError.dateError ? formValueError.dateError : null
                    }
                    readOnly={history.location.pathname.includes(
                      "/addTraining/view"
                    )}
                  />
                  <Form.Field
                    control={Input}
                    label="DURATION"
                    clearable
                    upward={false}
                    className="topMarginInput inlineLabel"
                    placeholder="Duration"
                    selectOnBlur={false}
                    search
                    width={8}
                    name="duration"
                    value={formValue.duration}
                    onChange={(e) => onChange(e)}
                    error={
                      formValueError.durationError
                        ? formValueError.durationError
                        : null
                    }
                    readOnly={history.location.pathname.includes(
                      "/addTraining/view"
                    )}
                  />
                </Form.Group>
                <Form.Group className="formGroup" width={16}>
                  <Form.Field
                    control={Input}
                    label="TRAINING TOPIC"
                    clearable
                    upward={false}
                    className="topMarginInput inlineLabel"
                    placeholder="TRAINING TOPIC"
                    selectOnBlur={false}
                    search
                    width={8}
                    name="training_topic"
                    value={formValue.training_topic}
                    onChange={(e) => onChange(e)}
                    error={
                      formValueError.training_topicError
                        ? formValueError.training_topicError
                        : null
                    }
                    readOnly={history.location.pathname.includes(
                      "/addTraining/view"
                    )}
                  />

                  <Form.Field
                    control={Select}
                    label="DOT TRAINER"
                    clearable
                    className="topMarginInput inlineLabel dropIndex"
                    options={dotTrainer}
                    placeholder="DOT TRAINER"
                    selectOnBlur={false}
                    search
                    width={8}
                    name="dotTrainer"
                    value={formValue.dotTrainer}
                    onChange={(e, { value }) => {
                      e.target.name = "dotTrainer";
                      e.target.value = value;
                      onChange(e);
                    }}
                    disabled={history.location.pathname.includes(
                      "/addTraining/view"
                    )}
                  />
                </Form.Group>
                <Form.Group width={16} className="formGroup">
                  <Form.Field
                    control={Input}
                    label="EXTERNAL TRAINER"
                    clearable
                    upward={false}
                    className="topMarginInput inlineLabel"
                    placeholder="EXTERNAL TRAINER"
                    selectOnBlur={false}
                    search
                    width={8}
                    name="externalTrainer"
                    value={formValue.externalTrainer}
                    onChange={(e) => onChange(e)}
                    error={
                      formValueError.externalTrainerError
                        ? formValueError.externalTrainerError
                        : null
                    }
                    readOnly={history.location.pathname.includes(
                      "/addTraining/view"
                    )}
                  />
                  <Form.Field
                    width={8}
                    className="MarginRed10 multiCheckBox multiCheckBoxBlack dropIndexMulti"
                  >
                    <ReactMultiselectCheckboxes
                      clearable
                      options={staffData}
                      placeholderButtonLabel="List of Trainee"
                      label="LIST OF TRAINEE"
                      className={"inlineLabel dropIndexSpl"}
                      value={trainee}
                      placeholder="LIST OF TRAINEE"
                      name="listOfTrainee"
                      onChange={(value) => {
                        checkMultiSelect(value);
                      }}
                      disabled={
                        history.location.pathname.includes(
                          "/addTraining/view"
                        )
                          ? true
                          : false
                      }
                    />
                  </Form.Field>
                </Form.Group>

                {/* </div> */}

                <Form.Group className="w-100">
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleUploadToS3(acceptedFiles, (data) => {
                        setFormValue((prevState) => ({
                          ...prevState,
                          document: data?.location || "",
                        }));
                      })
                    }
                    disabled={history.location.pathname.includes(
                      "/addTraining/view"
                    )}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="w-100">
                        <input {...getInputProps()} />
                        <Item className="dotted w-100">
                          <Header className="w-100" icon>
                            <Icon className="iconOrange" name="cloud upload" />

                            <p>Upload File</p>
                            <p className="mbBlue">Browse or Drag and Drop</p>
                          </Header>
                        </Item>
                      </div>
                    )}
                  </Dropzone>

                </Form.Group>
                {doc_name && doc_name[4] ? decodeURI(doc_name[4]) : null}
                {history1.location.pathname.includes("/addTraining/view") ? (
                  ""
                ) : (
                  <Form.Group className="mt-1">
                    <Button
                      style={{ marginLeft: "auto" }}
                      type="button"
                      disabled={isDisable}
                      onClick={() => {
                        if (
                          match?.type === "update" &&
                          location.updateData.id
                        ) {
                          setData();
                        } else {
                          onSubmit();
                        }
                      }}
                      size="large"
                      className="secondary mr-1 mb-2 btn-width"
                    >
                      {history1.location.pathname.includes(
                        "/addTraining/create"
                      )
                        ? "Confirm & Submit"
                        : "Update"}
                    </Button>
                  </Form.Group>
                )}
              </Form>
            </div>
          </Segment>
        </Grid.Column>
      </div>
    </>
  );
};
export default AddTraining;
