import { useEffect, useState } from "react";
import ContainerTitle from "../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../components/ContainerTitle/TitleWrapper";
import CustomTable from "../../../components/CustomTable/CustomTable";
import axios from "../../../utils/axios";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { getLocalTimeCus } from "../../../utils/localTime";

const SkillsLog = (props) => {
  const [skillsData, setSkillsData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState({
    category: "",
    topic: "",
    employee_name: "",
  });
  const onSelect = (value, type) => {
    setSearch({
        ...search,
        [type]: value
    })
  };
  // fetch skill log for staff
  const fetchData = () => {
    setLoading(true);
    axios
      .get("/staff/fetchSkillLogs", {
        params: {
          limit: 10,
          offset: (pageNumber * 10 - 10),
          category: search.category,
          topic: search.topic,
          employee_name: search.employee_name
        },
      })
      .then((result) => {
        let data = result.data.result[0].response_fetchSkillLogs;
        data = data.map((value) => {
          value.createdAt = getLocalTimeCus(value.createdAt)
          return value;
        })
        setSkillsData([...data])
        setCount(data[0].Total_Count)
      })
      .catch((error) => {
        setSkillsData([])
        setCount(0)
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchData();
  }, [pageNumber, search]);

  return (
    <>
      <div className="container-height-width">
        <div className="tableWrapper">
          <div className="">
            <TitleWrapper>
              <ContainerTitle title={"Skill Logs"} />
            </TitleWrapper>
          </div>
          <div className="customTableWraper">
            <CustomTable
              cols={[
                {
                  id: "category",
                  title: "Category",
                  search: true,
                  onSelect: onSelect,
                  type: "category"
                },
                {
                  id: "training_topic",
                  title: "Training Topic",
                  search: true,
                  onSelect: onSelect,
                  type: "topic"
                },
                {
                  id: "employee_name",
                  title: "Staff Name",
                  search: true,
                  onSelect: onSelect,
                  type: "employee_name"
                },
                {
                  id: "createdAt",
                  title: "Created At",
                }
              ]}
              data={skillsData}
              count={count}
              setPagination={(n) => setPageNumber(n)}
              activePage={pageNumber}
              error={(e) => { }}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default compose(withRouter)(SkillsLog);
