export const SET_ERROR = "@master/SET_ERROR";
export const RESET_ERROR = "@master/RESET_ERROR";
export const SET_ERROR_MESSAGE = "@master/SET_ERROR_MESSAGE";
export const SET_MASTER_COUNT = "@master/SET_MASTER_COUNT";
export const SET_PAGE_NO = "@master/SET_PAGE_NO";
export const RESET_PAGE_NO = "@master/RESET_PAGE_NO";
export const SET_LOADING = "@master/SET_LOADING";
export const SET_ACTIVE_TAB = "@master/SET_ACTIVE_TAB";
export const SET_MASTER = "@master/SET_MASTER";
export const SET_MASTER_LOADING = "@master/SET_MASTER_LOADING";
export const MASTER_ERROR = "@master/MASTER_ERROR";
export const MASTER_ERROR_RESET = "@master/MASTER_ERROR_RESET";

export const ADD_COMPANY = "@master/ADD_COMPANY";
export const RESET_ADD_COMPANY = "@master/RESET_ADD_COMPANY";
export const ADD_PLANING = "@master/ADD_PLANING";
export const UPDATE_PLANING = "@master/UPDATE_PLANING";
export const ADD_CURRENCY = "@master/ADD_CURRENCY";
export const UPDATE_CURRENCY = "@master/UPDATE_CURRENCY";
export const ADD_COUNTRY = "@master/ADD_COUNTRY";
export const UPDATE_COUNTRY = "@master/UPDATE_COUNTRY";
export const RESET_ADD_COUNTRY  = "@master/RESET_ADD_COUNTRY";
export const ADD_ATTENDANCE_TYPE = "@master/ADD_ATTENDANCE_TYPE";
export const UPDATE_ATTENDANCE_TYPE = "@master/UPDATE_ATTENDANCE_TYPE";
export const RESET_ADD_ATTENDANCE_TYPE  = "@master/RESET_ADD_ATTENDANCE_TYPE";
export const ADD_LEAVE = "@master/ADD_LEAVE";
export const RESET_ADD_LEAVE  = "@master/RESET_ADD_LEAVE";
export const RESET_ADD_PLANING  = "@master/RESET_ADD_PLANING";
export const RESET_ADD_CURRENCY  = "@master/RESET_ADD_CURRENCY";
export const ADD_PRIORITY = "@master/ADD_PRIORITY";
export const RESET_ADD_PRIORITY = "@master/RESET_ADD_PRIORITY";
export const ADD_VAT = "@master/ADD_VAT";
export const RESET_ADD_VAT = "@master/RESET_ADD_VAT";
export const ADD_DEPARTMENT = "@master/ADD_DEPARTMENT";
export const RESET_ADD_DEPARTMENT = "@master/RESET_ADD_DEPARTMENT";
export const ADD_GRADE = "@master/ADD_GRADE";
export const RESET_ADD_GRADE = "@master/RESET_ADD_GRADE";
export const ADD_JOBTYPE = "@master/ADD_JOBTYPE";
export const RESET_ADD_JOBTYPE = "@master/RESET_ADD_JOBTYPE";
export const ADD_TAT = "@master/ADD_TAT";
export const RESET_ADD_TAT = "@master/RESET_ADD_TAT";
export const ADD_TEAM = "@master/ADD_TEAM";
export const RESET_ADD_TEAM = "@master/RESET_ADD_TEAM";
export const ADD_MASTER_COUNT = "@master/ADD_MASTER_COUNT";
export const RESET_MASTER_COUNT = "@master/RESET_MASTER_COUNT";

export const MASTER_COUNT_LOADING = "@master/MASTER_COUNT_LOADING";

export const ADD_DATA = "@master/ADD_DATA";
export const UPDATE_DATA = "@master/UPDATE_DATA";

export const ADD_JOB_TYPE_LIST = "@master/ADD_JOB_TYPE_LIST";
export const RESET_ADD_JOB_TYPE_LIST = "@master/RESET_ADD_JOB_TYPE_LIST";
