import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import ContainerTitle from "../../../components/ContainerTitle/ContainerTitle";
import TitleWrapper from "../../../components/ContainerTitle/TitleWrapper";
import { Button, Dropdown, Menu } from "semantic-ui-react";
import EnquiryListHoc from "./EnquiryListHoc";

import CustomTable from "../../../components/CustomTable/CustomTable";
import { loopObject } from "../../../utils/objectLoop";
import { render } from "@testing-library/react";
import AccessCheck from "../../../utils/AccessCheck";


function Enquiry({
  enquiry,
  setEnquiryPageNumber,
  getData,
  editRoute,
  deleteEnquiry,
  onSelect,
  location,

  ...props
}) {
  // enquiry component NOT in use
  const getEnquiryList = useCallback(
    (dataObj) => {
      getData({ ...dataObj });
    },
    [getData]
  );
  useEffect(() => {
    getEnquiryList(loopObject(enquiry.enquirySearch));
    return () => {};
  }, [enquiry.pageNumber, enquiry.enquirySearch, getEnquiryList]);

  const renderEnquiryTitleWraper = () => {
    if (props.history.location.pathname.includes("/dashboard")) {
      return <></>;
    } else {
      return (
        <TitleWrapper>
          <ContainerTitle title={"Enquiry List"} />
          {props.global.userDetails &&
            props.global.userDetails.roleName &&
            (props.global.userDetails.roleName === "Admin" ||
              props.global.userDetails.roleName === "Client") && AccessCheck("Marketing","Create") &&(
              <Button
                className="dotButton"
                floated="right"
                content="Add New Enquiry"
                color="green"
                onClick={() => props.history.push("/addEnquiry/create")}
              />
            )}
        </TitleWrapper>
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>Enquiry List</title>
        <meta name="description" content="Description of enquiry" />
      </Helmet>
      
        <div className="customTableWraper">
          <div
            className={
              props.history.location.pathname.includes("dashboard")
                ? ""
                : "container-height-width"
            }
          >
            {renderEnquiryTitleWraper()}
        
            <CustomTable
              cols={[
                {
                  id: "id",
                  title: "      ID",
                  classStyle: {},
                  fixed: true,
                  search: true,
                  onSelect: onSelect,
                  text: enquiry.enquirySearch.enquiryId || "",
                  type: "enquiryId",
                },
                {
                  id: "name",
                  title: "Enquiry Name",
                  classStyle: {},
                  search: true,
                  onSelect: onSelect,
                  options: [],
                  text: enquiry.enquirySearch.name || "",
                  type: "name",
                },
                {
                  id: "category",
                  title: "Category",
                  classStyle: {},
                  search: true,
                  onSelect: onSelect,
                  options: [...enquiry.categorisation],
                  text: enquiry.enquirySearch.category || "",
                  type: "category",
                },
                {
                  id: "status",
                  title: "Status",
                  classStyle: {},

                  search: true,
                  onSelect: onSelect,
                  options: [...enquiry.event],
                  text: enquiry.enquirySearch.status || "",
                  type: "status",
                },
                {
                  id: "source",
                  title: "Enquiry Source",
                  classStyle: {},
                  search: false,
                  onSelect: onSelect,
                  options: [...enquiry.source],
                  text: enquiry.enquirySearch.source || "",
                  type: "source",
                },
                {
                  id: "engagement",
                  title: "Engagement",
                  classStyle: {},
                  search: false,
                  onSelect: onSelect,
                  options: [...enquiry.engagement],
                  text: enquiry.enquirySearch.engagement || "",
                  type: "engagement",
                },
                {
                  id: "mobile",
                  title: "Mobile Number",
                  classStyle: {},
                  search: false,
                  onSelect: onSelect,
                  // options: [],
                  // text: job.jobSearch.email || "",
                  type: "email",
                },
                {
                  id: "email",
                  title: "Email Address",
                  classStyle: {},
                  search: false,
                  onSelect: onSelect,
                  // options: [],
                  // text: job.jobSearch.email || "",
                  type: "email",
                },
                {
                  id: "address",
                  title: "Address",
                  classStyle: {},
                  search: false,
                  onSelect: onSelect,
                  // options: [],
                  // text: job.jobSearch.email || "",
                  type: "email",
                },
                {
                  id: "createdAt",
                  title: "Created On",
                  classStyle: {},
                  search: false,
                  onSelect: onSelect,
                  // options: [],
                  // text: job.jobSearch.email || "",
                  type: "email",
                },
              ]}
              data={enquiry.enquiryData}
              count={enquiry.count}
              setPagination={(pageNumber) => setEnquiryPageNumber(pageNumber)}
              activePage={enquiry.pageNumber}
              error={enquiry.enquiryError}
              loading={enquiry.enquiryListLoading}
              renderMenuItem={(row) => {
                return (
                  <Menu fluid vertical tabular className="user-menu">
                    {AccessCheck("Marketing", "Update") && (
                      <Menu.Item name="edit" onClick={() => editRoute(row.id)} />
                    )}
                    {AccessCheck("Marketing","Delete") &&(
                      <Menu.Item
                        name="delete"
                        onClick={() => {
                          deleteEnquiry(row.id);
                        }}
                      />
                    )}
                    {/* <Menu.Item name="details" onClick={() => detailsRoute(row.id)} />
                <Menu.Item name="copy" onClick={() => copyRoute(row.id)} /> */}
                  </Menu>
                );
              }}
            />
          </div>
        </div>
      
    </>
  );
}

export default EnquiryListHoc(Enquiry);
