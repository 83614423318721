export const EVENT_FETCH = "@event/EVENT_FETCH";
export const EVENT_SUCCESS = "@event/EVENT_SUCCESS";
export const EVENT_ERROR = "@event/EVENT_ERROR";




export const EVENT_GET = "@event/EVENT_GET";
export const EVENT_GET_ERROR = "@event/EVENT_GET_ERROR";
export const EVENT_GET_LIST_LOADING = "@event/EVENT_GET_LIST_LOADING";


export const EVENT_GET_PAGE_NO= "@event/EVENT_GET_PAGE_NO";
export const EVENT_COUNT= "@event/EVENT_COUNT";
export const EVENT_SEARCH= "@event/EVENT_SEARCH";
export const EVENT_SEARCH_RESET= "@event/EVENT_SEARCH_RESET";