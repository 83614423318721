export const SET_ERROR = "@global/SET_ERROR";
export const SET_ERROR_MESSAGE = "@global/SET_ERROR_MESSAGE";
export const SET_USER_NAME = "@global/SET_USER_NAME";
export const SET_ACCESS_TOKEN = "@global/SET_ACCESS_TOKEN";
export const SET_REFRESH = "@global/SET_REFRESH";
export const SET_ACCESS = "@global/SET_ACCESS";




export const SET_USER_DETAILS = "@global/SET_USER_DETAILS";
export const SET_USER_DETAILS_ERROR = "@global/SET_USER_DETAILS_ERROR";

export const LOGOUT = "@global/LOGOUT";


