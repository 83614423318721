import produce from "immer";
import { SET_DOCUMENTS, SET_DOCUMENT_SIDEBAR } from "./constants";
export const INITIAL_STATE = {
  openDocumentSideBar :false,
  documents:[]
};
const jobdetailsReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_DOCUMENT_SIDEBAR:
        draft.openDocumentSideBar =  action.value;
        break;
      case SET_DOCUMENTS:
        draft.documents =  action.arr;
        break;
      default:
        return draft;
    }
  });
};

export default jobdetailsReducer;
