export const CLIENT_FETCH = "@clients/CLIENT_FETCH";
export const CLIENT_SUCCESS = "@clients/CLIENT_SUCCESS";
export const CLIENT_ERROR = "@clients/CLIENT_ERROR";




export const CLIENT_GET = "@clients/CLIENT_GET";
export const CLIENT_GET_ERROR = "@clients/CLIENT_GET_ERROR";
export const CLIENT_GET_LIST_LOADING = "@clients/CLIENT_GET_LIST_LOADING";


export const CLIENT_GET_PAGE_NO= "@clients/CLIENT_GET_PAGE_NO";
export const CLIENT_COUNT= "@clients/CLIENT_COUNT";
export const CLIENT_SEARCH= "@clients/CLIENT_SEARCH";
export const CLIENT_SEARCH_RESET= "@clients/CLIENT_SEARCH_RESET";