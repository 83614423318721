import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Select } from "semantic-ui-react";
import ModalWrapper from "../../../components/Modals/ModalWrapper";
import { closeModalAction } from "../../../redux/redux/modals/actions";
import { isSelectEmpty, stringType } from "../../../utils/validator";
import axios from "../../../utils/axios"


function AddDotSkills(props) {
  const [loading, setLoading] = useState(false);
  const [trainingOptions, setTrainingOptions] = useState([]);
  const [skillsData, setSkillsData] = useState({
    category: "",
    training_topic: "",
    link_type: "",
    link: ""
  });
  const [skillsDataError, setSkillsDataError] = useState({
    category: null,
    training_topic: null,
    link_type: null,
    link: null,
  });
  const ATYPE = [
    { text: "Deduction", key: "Deduction", value: "Deduction" },
    { text: "Earnings", key: "Earnings", value: "Earnings" },
  ];

  const VALUETYPE = [
    { text: "Number", key: "Number", value: "Number" },
    { text: "Percent", key: "Percent", value: "Percent" },
  ];
  const LTYPE = [
    { text: "DOCUMENT", key: "DOCUMENT", value: "DOCUMENT" },
    { text: "VIDEO/AUDIO", key: "VIDEO/AUDIO", value: "VIDEO/AUDIO" },
  ];

  const validate = () => {
    let isError = false;
    let data = { ...skillsData };
    Object.values(data).forEach((value) => {
      if (value === "") {
        isError = true;
      }
    });
    return isError;
  };

  const onValidator = (e) => {
    switch (e.target.name) {
      case "category":
        return isSelectEmpty(e.target.value, "Category");
      case "training_topic":
        return isSelectEmpty(e.target.value, "Training Topic");
      case "link":
        return stringType(e.target.value, "Link");
      case "link_type":
        return stringType(e.target.value, "Link Type");
      default:
        return null;
    }
  };
  const onChange = (e) => {
    setSkillsData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setSkillsDataError((prevState) => ({
      ...prevState,
      [e.target.name]: onValidator(e),
    }));
  };

  const onSubmitValidate = async () => {
    let data = { ...skillsData };
    // this.state.dataError.map(async (data) => {
    await Object.keys(data).forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };

      await setSkillsDataError((prevState) => {
        return { ...prevState, [`${key}`]: onValidator(e) };
      });
    });
  };

  const onSubmit = () => {
    if (!validate()) {
      if (props.type === "edit" && props.editData) {
        props.handleSubmit(skillsData, "edit");
        props.handleClose()
      } else {
        props.handleSubmit(skillsData, "add");
        props.handleClose()
      }
    } else {
      onSubmitValidate();
    }
  };
  // get training topic list 
  const getTraining = (id) => {
    setLoading(true);
    axios
      .get(`/dds/listTrainingTopic?skill_category_id=${id}`)
      .then((res) => {
        let data = [...res.data.result];
        data = data.map((item) => {
          item = {
            key: `${item.id}_${item.training}`,
            value: item.training,
            text: item.training,
          };
          return item;
        });
        setTrainingOptions([...data]);
      })
      .catch((e) => { })
      .finally(() => {
        setLoading(false);
       });
  };
  useEffect(async() => {
    // let temp = await getSkillsCategory()
    if (props.type === "edit" && props.editData) {
      setSkillsData({ ...props.editData });
    }
  }, [props]);

  return (
    <ModalWrapper
      size="tiny"
      header={props.type === "edit" ? "Update Skills" : "Add Skills"}
      esc
      className={"add"}
      closeModal={props.handleClose}
    >
      <Form.Group className="">
        <Grid columns={1}>
          <Grid.Column>
            <Select
              placeholder="Category"
              label="Category"
              options={props.categoryOptions}
              search
              clearable
              selection
              value={skillsData.category}
              onChange={(e, { value }) => {
                e.target.name = "category";
                e.target.value = value;
                onChange(e);
                let temp = value.split('-')
                getTraining(temp[1]);
              }}
              error={
                skillsDataError.category ? skillsDataError.category : null
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Select
              placeholder="Training Topic"
              label="Training Topic"
              options={trainingOptions}
              search
              clearable
              selection
              loading={loading}
              value={skillsData.training_topic}
              onChange={(e, { value }) => {
                e.target.name = "training_topic";
                e.target.value = value;
                onChange(e);
              }}
              error={skillsDataError.training_topic ? skillsDataError.training_topic : null}
            />
          </Grid.Column>
          <Grid.Column>
            <Select
              placeholder="LINK TYPE"
              label="LINK TYPE"
              options={LTYPE}
              search
              clearable
              selection
              value={skillsData.link_type}
              onChange={(e, { value }) => {
                e.target.name = "link_type";
                e.target.value = value;
                onChange(e);
              }}
              error={skillsDataError.link_type ? skillsDataError.link_type : null}
            />
          </Grid.Column>
        </Grid>
        <Grid columns={1}>
          {/* {2} */}
          <Grid.Column>
            <Form.Input
              label="Link"
              placeholder="Link"
              name="link"
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                width: "100% !important",
              }}
              onChange={onChange}
              value={skillsData.link}
              error={skillsDataError.link ? skillsDataError.link : null}
            />
          </Grid.Column>
        </Grid>
      </Form.Group>
      <Grid
        columns={2}
        verticalAlign="middle"
        style={{ marginTop: "20px" }}
      >
        {/* 4 */}
        <Grid.Column>
          <Button
            style={{ width: "100%" }}
            className="secondary"
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
          >
            {props.type && props.type === "edit" ? "UPDATE" : "ADD"}
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="red"
            style={{ width: "100%" }}
            disabled={loading}
            onClick={() => props.handleClose()}
          >
            Cancel
          </Button>
        </Grid.Column>
      </Grid>
    </ModalWrapper>
  );
}

export default AddDotSkills;
