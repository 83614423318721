import { createSelector } from 'reselect';
import { INITIAL_STATE } from './reducers';

/**
 * Direct selector to the home state domain
 */

const selectLoginDomain = state => state.login || INITIAL_STATE;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Home
 */

const makeSelectLogin = () =>
  createSelector(
    selectLoginDomain,
    login => login,
  );

export default makeSelectLogin;
export { selectLoginDomain };
