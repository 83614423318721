import React, { memo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Modal } from "semantic-ui-react";
import { compose } from "redux";
import { closeModalAction } from "../../redux/redux/modals/actions";

const DEFAULT_PROPS = {
  transition: {
    animation: "scale",
    duration: 300,
  },
};
const ModalWrapper = ({
  children,
  size,
  esc,
  dimmer,
  scrolling = false,
  header,
  closeIcon,
  dispatch,
  ...props
}) => {
  return (
    <>
      {/* <TransitionablePortal
      {...{ open: true }}
      {...{
        animation: 'scale',
        duration: 300,
      }}
    > */}
      <Modal
        closeOnEscape={esc}
        closeOnDimmerClick={dimmer}
        onClose={() =>
          props.closeModal ? props.closeModal() : dispatch(closeModalAction())
        }
        closeIcon={closeIcon}
        open
        dimmer={dimmer ? "blurring" : ""}
        size={size}
        className={props.className}
        id="modal_modal"
      >
        {header && <Modal.Header>{header}</Modal.Header>}
        <Modal.Content scrolling={scrolling ? true : false}>
          {children}
        </Modal.Content>
      </Modal>
      {/* </TransitionablePortal> */}
    </>
  );
};

// ModalWrapper.propTypes = {
//   props: PropTypes
// }

const mapStateToProps = createStructuredSelector({
  // modals: makeSelectModals(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ModalWrapper);
